@import '../var'

#react-confirm-alert
  .react-confirm-alert-overlay
    background: rgba(0, 0, 0, 0.5)
    z-index: 1004

    .react-confirm-alert-body
      border-radius: 0

      h1
        height: 33px
        font-family: $font-secondary
        font-size: 18px

        color: $secondary-color
