
@import 'var'

.title-head
  width: auto
  max-padding-left: 45px
  font-family: $font-primary
  color: $secondary-color
  font-size: $primary-size
  font-weight: $weight-bold

.detail-service
  font-family: $font-primary
  font-size: $default-size
  color: $table-color
  margin-bottom: 30px

.pic-large
  margin-bottom: 50px
  background-color: $light-blue
  padding: 22px 21px 18px 20px
  border-radius: 4px
  img
    text-align: left
    height: 40px
    margin-bottom: 20px

  button.btn-service
    text-decoration: none
    border: none
    font-size: $second-size
    line-height: 18px
    background-color: $light-blue
    font-family: $font-semiBold
    padding-left: 0
    color: $actual-color
    &:hover
      cursor: pointer
    svg
      margin-left: 15px

.service-nav
  font-family: $font-bold
  font-size: $second-size
  color: $secondary-color
  text-transform: uppercase

  &.disabled
    opacity: 0.5

    a
      cursor: not-allowed

  .add-service-link
    &:before
      content: '+'
      padding-right: 10px

.tel-number
  padding: 10px
  font-family: $font-secondary
  font-size: $primary-size

.display-filtre
  display: none
  @media only screen and (max-width: $mobile-breakpoint-i)
    display: block

.price-right
  float: right
  font-size: 11px
  font-family: $font-primary

  @media only screen and (max-width: $mobile-breakpoint-i)
    float: right
    margin-top: -34px

.arrow-width
  width: 8px
.bottom-service
  position: absolute
  bottom: 35px
  width: 75%
.soon
  width: 35px
  border-radius: 4px
  border: solid 1px $primary-color
  font-family: $font-primary
  font-size: $medium-size
  font-weight: bold
  color: $primary-color
  text-transform: none
  text-align: center
  float: right
  position: relative
  top: 42%
  right: 15%
  line-height: 12px
.hover-valide:hover
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1)
  border-bottom: 2px solid $success-color

.link-btn-service
  position: absolute
  bottom: 19px
  width: 90%
  text-align: center
  margin: 15px

  @media screen and (max-width: $mobile-breakpoint-i)
    position: relative
    margin: auto
    display: block
    text-align: center
    width: 80%
    margin-top: 50px

.slider-image
  width: 230px
  float: left !important
  img
    width: auto !important
    min-height: 180px

  @media only screen and (max-width: $mobile-breakpoint-i)
    width: 100%
    float: none !important

.pic-carousel
  width: 100%

.text-left
  text-align: left

.return-link
  position: absolute
  cursor: pointer

  @media only screen and (max-width: $mobile-breakpoint-i)
    margin-left: 18px

.no-bottom
  @media only screen and (max-width: $mobile-breakpoint-i)
    margin-bottom: 0px

.no-bottom-desktop
  margin-bottom: 0px !important

.SingleDatePicker_picker
  position: absolute
  height: 298px!important
  overflow-x: visible
  overflow-y: visible
  display: inline-block
  z-index: 999999

.dte-input
  margin-bottom: 0px

.full-padding
  .msg
    text-align: center
  .icon-info
    color: $table-color
    img
      margin-right: 0.5rem

.room.bureaux-cadre
  height: auto
  border: solid 1px #ececec
  .row
    min-height: 180px
  .title-cadre
    padding: 8px 0
  .icon-info
    color: $table-color
    img
      margin-top: -3px
      margin-right: 6px
  .equipments
    font-size: 0.9rem
    text-transform: initial
    font-family: $font-secondary
.carousel-room
  min-height: 180px
  .carousel-inner
    min-height: 180px
    .carousel-item
      min-height: 180px
      .shadow-inset
        position: absolute
        width: 100%
        height: 100%
        box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.5) inset
        top: 0
        left: 0
        cursor: pointer
      img
        object-fit: cover
        object-position: center
  .carousel-indicators
    li
      border-radius: 50%
      width: 7px
      height: 7px
      margin: 0 12px

.nav__menu_filtre
  padding: 0

  .SingleDatePickerInput, .DateInput, .DateInput_input
    background-color: transparent
  .DateInput_input.DateInput_input__focused
    background-color: white

  .row.full-height
    height: 100vh

  .form-container
    padding: 40px
    h4
      font-family: $font-secondary


.header-child-services
  @media screen and (max-width: $mobile-breakpoint-i)
    display: block
    width: 100%
    background: whitesmoke
    left: 0px
    padding: 80px 20px 14px 20px

  .category-breadcrumb
    font-family: $font-secondary

    @media screen and (max-width: $mobile-breakpoint-i)
      font-size: $second-size
      color: $secondary-color
      text-transform: uppercase

  .link-btn-service
    @media screen and (max-width: $mobile-breakpoint-i)
      width: 100%
      margin-top: 16px

.service-fullscreen
  z-index: 1003
  position: absolute
  top: 0
  background: white
  left: 0
  width: 100%
  min-height: 100%
  min-height: 100vh

  @media screen and (max-width: $mobile-breakpoint-i)
    padding: 50px

  .hotline-form
    @media screen and (min-width: $mobile-breakpoint-o)
      padding-top: 50px

  .service-inner-form
    max-width: 660px

    h4
      padding-left: 15px

  .section-button
    padding: 1.5rem
    width: 100%
    @media screen and (max-width: $mobile-breakpoint-i)
      padding: 7px
      width: 100%
      text-align: center
  .error-input
    position: absolute

  .close
    width: auto
    height: auto
  > .row
    width: 100%
    margin-left: 0

  .hint
    position: relative
    padding-bottom: 0.5rem
    margin-bottom: 30px
    @media screen and (min-width: $mobile-breakpoint-o)
      padding-left: 15px

.price-summary
  padding: 2rem 3rem
  color: $secondary-color
  background-color: #fafafa
  margin: 2rem auto
  text-align: left
  .dates
    font-size: 1.2rem
    margin-bottom: 1.5rem
  .detail
    color: #858889
    margin-bottom: 0.5rem
    &.total
      color: $secondary-color
      margin-top: 1.5rem
      .price
        color: $secondary-color
    .price
      float: right
      color: #858889

.terms
  margin-top: 1rem
  font-size: $medium-size
  color: $grey-text

.hotline-form
  .headset-div
    text-align: center
    margin-top: 66px
    margin-bottom: 42px
    padding: 1rem
    border: 1px solid $color-border
    min-height: 246px
    &.selected
      border-color: $primary-color

    &:not(.selected)
      opacity: 0.8

    @media screen and (max-width: $mobile-breakpoint-i)
      margin: 20px 10px

    .radio
      width: 18px
      height: 18px
      margin: auto

  .hotline-sumup
    background: whitesmoke
    padding: 30px
    border: none
    color: $secondary-color
    margin: 50px 0px 0px 0px
    .icon-info
      color: $grey-text
      .align-right
        color: $secondary-color
        margin-right: 0
    .title-popup
      margin-top: 0px

.hotline-form-submit
  button[type="submit"]
    margin-top: 10px

.form-booking
  .custom-select .options
    max-height: 263px
    overflow-y: auto
  .hours-select .custom-select .options
    max-height: 140px
  button
    margin-top: 25px

.add-service-text
  color: $primary-color
  font-size: $medium-size
  text-transform: uppercase
