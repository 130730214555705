
@import 'var'

@media only screen and (max-width: $mobile-breakpoint-i)
  .page-subscription
    .service
      display: block
      .left-box
        width: 100%
        float: none
      .right-box
        width: 100%
        float: none
        margin-top: 10px

