@import var
@import sidebar_courriers

.user-mails-section
  display: flex
  flex-flow: column nowrap
  @media only screen and (max-width: $mobile-breakpoint-i)
    padding: 0 20px

    h1
      margin-bottom: 20px
  .top-row
    height: 40px
    font-family: Open Sans
    font-style: normal
    font-weight: normal
    flex-flow: row nowrap
    @media (max-width: $xlarge-breakpoint-i)
      padding-top: 20px
      flex-flow: column nowrap

    .credits-container
      margin-right: 20px
      @media (max-width: $xlarge-breakpoint-i)
        margin-right: 0

.nav__menu,
.nav__menu_2
  box-shadow: $box-shadow

th.date_of_receipt_th
  min-width: 148px


.courriers-table
  tr
    height: 60px

  tr:hover:not(.selected)
    box-shadow: 7px 7px 25px 0 rgba(0, 0, 0, 0.1)
  tr:hover
    background: $white-color !important
  tr.before-selected
    box-shadow: 0 -20px 20px -20px rgba(0, 0, 0, 0.1) inset
  tr.last-selected
    box-shadow: 7px 7px 25px 0 rgba(0, 0, 0, 0.1)

  th
    width: auto
    height: 14px
    font-family: $font-secondary
    font-size: $third-size
    font-weight: $weight-primary
    color: $secondary-color

  th:hover
    box-shadow: none

  td
    font-family: $font-primary
    font-size: $third-size
    color: $table-color
    vertical-align: middle
    &.first-col
      color: $secondary-color
      font-size: $second-size
    &:last-child
      text-align: right
    &.download
      text-decoration: underline


    .tag-name
      font-size: $default-size
      font-family: $font-primary

  .w10
    max-width: 20px
    text-align: center

  .tag-name.to-define
    font-style: italic

  &.loading
    th, td
      position: relative
      background-color: $white-color
      color: $white-color !important
      *
        visibility: hidden
    th:before, td:before
      content: ""
      position: absolute
      color: #f0f0f0
      background-color: #f0f0f0
      border-radius: 7px
      top: 23px
      left: 10%
      right: 10%
      bottom: 23px
      z-index: 1
    tbody tr:nth-child(odd)
      th:before, td:before
        right: 50%

.head-table:hover
  box-shadow: none !important
  background: $white-color !important

.no-user-mails, .no-invoice
  text-align: center
  .msg
    text-align: center
    font-size: $sub-size
    color: $secondary-color
  img
    width: 100%
    max-width: 180px
    margin-bottom: 30px
    margin-top: 90px

.gh11
  color: $secondary-color !important

.new
  background-color: $primary_opacity_3

.line
  width: 7px
  height: 3px
  margin-bottom: 2px
  margin-right: 5px
  border: solid 1px

.line_warning
  border: solid 1px $table-color_2
  background: $table-color_2

.line_default
  border: solid 1px $actual-color
  background: $actual-color

.line_alert
  border: solid 1px $primary-color
  background: $primary-color

.line_blue
  border: solid 1px $blue-color
  background: $blue-color

.line_success
  border: solid 1px $success-color
  background: $success-color

.oval-red
  width: 4px
  height: 4px
  background-color: $primary-color
  border-radius: 16px
  position: relative
  top: 5px
  left: 10px

.oval-eye
  width: $third-size

.icon-arrow-left
  width: 15px
  height: 12px
  margin-right: 4px
  margin-top: -2px

.return-back
  font-family: $font-primary
  font-size: $third-size
  color: $primary-color

.mails-filters-container
  display: flex
  flex-flow: row nowrap
  @media (max-width: $xlarge-breakpoint-i)
    padding-top: 20px
  @media (max-width: $mobile-breakpoint-i)
    flex-flow: column nowrap

.user-mails-filters
  display: flex
  gap: 20px
  @media (max-width: $xlarge-breakpoint-i)
    margin-left: auto
  @media (max-width: $mobile-breakpoint-i)
    margin-left: 0
    gap: 0px
    flex-flow: column nowrap
  .date-range-picker
    box-shadow: 0 3px 6px -1px $shadow-menu
    position: absolute
    right: 15px
    top: 45px
    z-index: 1000
  .filters-list
    order: 1
    display: flex
    flex-flow: row nowrap
    gap: 20px
    @media (max-width: $mobile-breakpoint-i)
      gap: 0px
      order: 2
      flex-flow: column nowrap

    .filter
      background-color: $primary-color
      border-radius: 4px
      padding: 10px
      margin-right: 1rem
      color: $white-color
      font-size: 13px
      display: inline-block
      @media (max-width: $mobile-breakpoint-i)
        margin-right: 0
      .remove-filter
        display: inline
        margin-left: 1rem
        cursor: pointer
  .dropdown
    order: 2
    @media (max-width: $mobile-breakpoint-i)
      order: 1
    &.show .add-filter
      background-color: $primary-color
      color: $white-color
    .filtre-btn
      top: auto !important
      left: auto !important
      min-width: 200px

      @media screen and (max-width: $mobile-breakpoint-i)
        top: 0px !important

      a
        padding: 15px 20px
        text-decoration: none
        font-size: 13px
        &.subitem
          padding-left: 40px
    .dropdown-item:hover
      background: $hove-color


#dropdown-filtre:focus
  background: $primary-color !important
  color: $white-color !important

.mobile-hidden
  display: none
.mobile-hidden
  display: none

.checkbox
  margin-top: 10px

.carousel-user-mail-query
  margin-top: 10px

  .carousel-inner
    width: 70%
    margin-left: 15%
    min-height: 330px
    background-color: whitesmoke
    border-radius: 4px
    @media only screen and (max-width: $mobile-breakpoint-i)
      min-height: 200px

  .carousel-control-prev-icon
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='ff3465' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E")

  .carousel-control-next-icon
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='ff3465' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E")

  .carousel-item
    padding: 20px
    .tag-name
      float: right

.carousel
  .carousel-item.active ~ .carousel-item.active
    display: none

@media only screen and (max-width: $mobile-breakpoint-i)
  .btn.btn-custom-right
    width: 100%

  .nav__menu,
  .nav__menu_2
    padding-top: 84px
    padding-bottom: 60px
    width: 100%
    z-index: 1

  .user-mails-filters
    .add-filter
      float: none
      text-align: center

    .filters-list
      float: none

      .filter
        margin-top: 10px

    .date-range-picker
      right: 0

  .carousel-user-mail-query
    .carousel-control-prev, .carousel-control-next
      width: 6%
    .carousel-inner
      width: 86%
      margin-left: 7%
    .carousel-item
      .text-default.right-popup
        float: left
        clear: both

@media only screen and (min-width: $mobile-breakpoint-o) and (max-width: $xlarge-breakpoint-i)
  .user-mails-section
    .top-row
      height: auto

@media only screen and (min-width: 334px) and (max-width: $mobile-breakpoint-i)
  .user-mails-filters
    .DayPicker
      margin-right: 15px
  .user-mails-section
    .top-row
      height: auto
      flew-flow: column nowrap
      align-items: stretch

#no-user-mail-modal
  .title-popup
    .count
      color: $primary-color
      margin-left: 0.5rem
  .text
    margin: 30px 0
    p
      margin-bottom: 0.5rem
  .img-container
    width: 50%
    @media only screen and (max-width: $mobile-breakpoint-i)
      width: 100%
  .bitmap
    height: 200px
    margin: auto
  .default-link
    margin-top: 1rem
    font-size: $second-size
    svg
      margin-right: 0.5rem
