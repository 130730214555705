@import var

.CalendarMonth_table, .SingleDatePicker
  .DayPicker_weekHeader_li
    color: rgba(123, 126, 128, 0.7)

.SingleDatePicker
  .SingleDatePicker_picker
    top: 36px !important

.CalendarMonth_table
  margin-top: 10px
  td, th
    border: none !important
    &.CalendarDay__selected_span, &.CalendarDay__selected:hover, &.CalendarDay__hovered_span:hover, &.CalendarDay__hovered_span
      border: none
      border-bottom: 1px solid $color-hover !important
      color: $color-hover
      background: $white-color

    &.CalendarDay__selected, &.CalendarDay__default:hover
      background: $primary-color
      border: 1px solid $color-hover
      color: $white-color