@import 'var'

/* Sidebar */

.sidebar-settings
  h1.title-service.no-center
    padding-left: 30px

/* Modal with sidebar offset */

#regularize-risks-modal
  max-width: 740px
  // Center modal in the middle of the right panel instead of middle of window
  @media screen and (min-width: 901px)
    margin-left: calc(50% + #{$sidemenu-size} / 2)
    transform: translateX(-50%)
  .modal-header
    padding: 0 22px
    img
      margin-right: 16px
  .modal-body
    margin: 0
    margin-bottom: 20px
    padding: 0 22px
    .section-warning
      margin-top: 16px




/* Main Content */

.settings-block
  padding: 50px 45px 160px 35px

  @media screen and (max-width: $mobile-breakpoint-i)
    padding: 60px 16px 160px 16px

  .subtitle-invoices
    font-family: $font-secondary
    margin-bottom: 30px
    font-size: $large-size
    width: 100%
    color: $secondary-color

  .subtitle-sidebar
    font-family: $font-secondary
    margin-bottom: 30px
    font-size: $large-size
    width: 100%
    color: $secondary-color

    button.section-button
      float: right

    @media screen and (max-width: $mobile-breakpoint-i)
      font-size: $primary-size
      margin-bottom: 80px

      button.section-button
        margin-top: 40px


  .primary-link
    text-decoration: underline

  .no-link
    color: $grey-text

  .xtop-margin
    margin-top: 110px

    @media screen and (max-width: $mobile-breakpoint-i)
      margin-top: 40px

  &.notifications-section
    max-width: 900px

    .notification-element
      width: 100%

      .bloc-notification-large
        width: 80%
        display: inline-block
        margin-top: 30px
        margin-bottom: 30px

        @media screen and (max-width: $mobile-breakpoint-i)
          margin-top: 0px

      .bloc-notification-right
        display: inline-block
        text-align: right
        width: 20%
        vertical-align: middle

        @media screen and (max-width: $mobile-breakpoint-i)
          margin-left: -5%

        .checkbox
          padding: 0
          margin: 0

    .column-email
      float: right
      clear: both
      font-size: $third-size
      font-weight: $weight-primary
      color: $grey-text
      padding-top: 6px
      .column-title
        font-weight: bold
        margin-bottom: 5px
      .email
        a
          margin-left: 0.5rem
          text-transform: none
          font-size: $third-size

  &.invoices-section
    .regularize-header
      margin-bottom: 64px
      .alert-message
        justify-content: space-between
        display: flex
        flex-direction: row
        align-items: center
        padding: 16px
        gap: 16px

        background: #F2E6E6
        border-radius: 8px
        .alert-header
          display: flex
          gap: 16px
          img
            width: 18px
            height: 18px
          span
            font-size: 14px
            line-height: 150%
            color: $darker-error-color
            display: block

        span.alert-button
          display: flex
          flex-direction: row
          padding: 11px 12px
          gap: 10px
          background: $white-color
          border-radius: 6px
          font-weight: 600
          cursor: pointer
          align-items: center
          text-align: center

      .regularize-message
        justify-content: space-between
        margin-top: 10px
        display: flex
        flex-direction: row
        align-items: center
        padding: 16px
        gap: 16px
        border: 1px solid #D3DBE1
        border-radius: 8px
        .regularize-sum-title
          font-size: 12px
        .regularize-sum-price
          font-size: 14px
          display: block
          font-weight: 500
        .regularize-button
          gap: 10px
          padding: 11px 12px
          font-style: normal
          font-weight: 600
          font-size: 14px
          line-height: 150%
          display: flex
          align-items: center
          text-align: center
          color: #FFFFFF
          background: $darker-error-color
          border-radius: 6px
          cursor: pointer
      .time-message
        margin-top: 10px
        display: flex
        flex-direction: row
        gap: 16px
        font-size: 12px
        color: $grey-text
        img
          margin: 0
    table
      tbody
        td
          padding-top: 20px
          padding-bottom: 20px
          a
            font-size: $third-size
            text-decoration: underline
      @media screen and (max-width: $mobile-breakpoint-i)
        td
          padding: 10px 5px !important
      @media screen and (max-width: $mobile-breakpoint-i)
        th
          padding: 10px 5px !important

    &.loading
      table
        tbody
          td
            span,
            a
              background-color: #f0f0f0
              color: transparent !important
              border-radius: 7px

    @media screen and (max-width: $mobile-breakpoint-i)
      table
        tbody
          td
            span.id-setting:before
              content: 'n°'


    .download-invoice-link

      @media screen and (min-width: $mobile-breakpoint-o)
        margin-left: 15px

    .subtitle
      font-weight: bold

    .head
      font-weight: bold
      font-size: $default-size

      @media only screen and (max-width: mobile-breakpoint-i)
        display: none

    .invoice-container
      &.enabled:hover
        box-shadow: 7px 7px 25px 0 rgba(0, 0, 0, 0.1)

        .ligne-setting
          border-bottom: none

      .ligne-setting
        padding: 22px 20px 3px 0px
        width: 95%
        margin-left: 5%
        display: flex
        justify-content: space-between
        @media only screen and (max-width: mobile-breakpoint-i)
          flex-wrap: wrap
          .name-setting
            width: 100%

        @media screen and (max-width: $mobile-breakpoint-i)
          flex-wrap: wrap

          .invoice-actions
            width: 100%

      .id-setting, .name-setting
        font-size: $third-size
        color: $secondary-color
        text-transform: none
      .info-setting
        font-size: $third-size
        color: $table-color
        text-transform: none

        @media screen and (max-width: $mobile-breakpoint-i)
          text-align: right
          padding-right: 12px

      .invoice-actions
        p
          display: inline-block
        .price-setting
          font-size: $third-size
          color: $secondary-color
          text-transform: none
          margin-right: 15px
          font-weight: bold
          display: block

        .oval
          float: none
          margin-top: -4px
          margin-left: 4px
          margin-right: 4px
          width: 3px

      @media screen and (max-width: $mobile-breakpoint-i)
        td
          &.label
            min-width: 7rem
          &.info-setting
            min-width: 7.5rem
          &.invoice-actions
            min-width: 12rem

      .label
        span
          display: block
        > *
          margin-left: 0
      .invoice-actions > span.status
        display: inline-block
        border-radius: 10px
        background-color: transparentize($success-color, 0.9)
        color: $success-color
        font-size: $medium-size
        font-family: $font-secondary
        padding: 5px 15px
        padding-bottom: 4px

      &.unpaid
        .invoice-actions > span.status
          color: $red-color
          background-color: transparentize($red-color, 0.9)
        .label > a.regularize-invoice
          display: inline-block
          color: $red-color
          margin-left: 1rem
        .download-unpaid
          width: 2rem
          padding-left: 0
          padding-right: 0

      &.disabled
        cursor: default

        .download-invoice-link
          cursor: default
          opacity: 0.5

          &:hover
            text-decoration: underline

  &.payment-section
    h2
      button
        img,
        svg
          padding-right: 10px
    .payment-element
      padding: 20px
      font-size: $third-size
      display: flex
      justify-content: space-between

      .method-title,
      .action-links,
      .method-sumup
        display: inline-flex
        height: 60px
        width: 300px
        vertical-align: middle
        align-items: center
        p
          margin: 0px

      .method-title
        min-width: 90px

      .method-sumup
        min-width: 110px

      .action-links
        min-width: 206px

        p
          margin-left: auto
          font-size: 13px
          span
            font-size: 13px

      .method-sumup
        .carte-paiement
          width: 35px
          height: 18px
          margin-left: -60px
          margin-right: 26px
          color: $grey-text
        .card-status
          margin-top: 10px
          margin-left: 10px



        .info-sub
          font-size: $medium-size
      &.default
        background-color: transparentize($primary-color, 0.9)
        padding: 0 20px
        margin: 20px 0
    @media screen and (max-width: 1240px)
      .payment-element
        display: block
        text-align: center

        .method-title,
        .action-links,
        .method-sumup
          height: auto
          display: block
          width: 100%
          text-align: center

        .method-title
          padding-bottom: 14px

        .action-links
          padding-top: 14px

        .last4
          padding-top: 10px
          display: block

        .method-sumup
          .carte-paiement
            margin: auto

        .info-sub
          display: block

      .subtitle-sidebar
        text-align: center
        margin: 0

        button.section-button
          float: none
          display: block
          margin: 30px auto 20px auto


// TODO/ Delete it or adapt it...
#payment-source-create
  max-width: 600px


  .modal-body
    margin-top: -20px
    .modal-content
      padding: 0 15px

  ul.nav.nav-tabs
    li.nav-item
      width: 50%
      text-align: center
      text-transform: uppercase
      font-size: 12px
      &.active
        border-bottom: 1px solid $primary-color

      a.nav-link
        color: $grey-text
        &:visited
          color: $grey-text
        &:focus, &:hover, &:active, &.active
          color: $secondary-color

  .tab-content
    margin-bottom: -15px
    padding-top: 15px
    .tab-pane
      .cardSection,
      .sepaSection
        text-align: center
        display: flex
        flex-direction: column
        justify-content: space-between
        margin-bottom: 30px

        .sepa-error-message
          margin-right: 14%
          margin-left: 14%
          margin-top: 5%

          .sepa-error-message-text
            margin-top: 5%

        button
          margin: 0 15px
          @media screen and (max-width: $mobile-breakpoint-i)
            margin: 0 5px

      .sepaSection
        input.owner-account
          border: 1px solid $gray-border-semi-transparent
          border-radius: 4px
          width: 100%
          max-width: 460px
          margin-bottom: 20px
          padding: 8px 5px
          font-size: 14px !important

          &::placeholder
            color: $gray-border-semi-transparent

          &:focus
            border: 1px solid $gray-border-semi-transparent !important
            outline: 0 none !important
        .legals
          font-size: 9px
          margin-top: 1rem
          text-align: justify

        .select-country
          width: 100%
          max-width: 460px
          margin: 0 auto 20px auto
          margin-bottom: 20px
          font-size: 14px !important

          &::placeholder
            color: $gray-border-semi-transparent

          &:focus
            border: 1px solid $gray-border-semi-transparent !important
            outline: 0 none !important

.cardSection,
.sepaSection
  .cb-wrapper,
  .sepa-wrapper
    font-family: $font-primary
    border: 1px solid $gray-border-semi-transparent
    border-radius: 4px
    padding: 8px 6px 6px 6px
    margin: 0px auto 30px auto
    max-width: 460px
    width: 100%
    height: 37px

    .form-errors
      color: $error-color
      font-size: $error-validation
      margin-top: 11px
.cardSection
  .cb-wrapper
    margin-top: 3rem

#retry-payment-modal
  .modal-body
    padding-bottom: 2rem
    .choices
      display: flex
      justify-content: space-around
      .radio
        display: inline
        label
          width: auto
    .stripe
      .title
        width: auto
        min-height: auto
        font-size: $second-size
        margin: 2rem 0 0.5rem 0
      .hint
        color: $grey-text
        font-size: $medium-size
      .cardSection
        .cb-wrapper
          margin: 0
      .payment-source-selector
        .radio
          font-family: $font-primary
          border: 1px solid $gray-border-semi-transparent
          border-radius: 4px
          padding: 8px 6px 6px 6px
          margin-bottom: 7px
          max-width: 460px
          width: 100%
          height: 37px
          .info-sub
            display: inline
            float: right
      .submit
        margin-top: 15px
      .form-errors
        color: $error-color
        font-size: $error-validation
        margin-top: 11px

.confirm-password
  margin-top: -10px

.legals-section
  .choice
    h1
      font-size: $large-size
    p
      color: $grey-text
      font-size: $third-size
      font-family: $font-secondary
    .action
      margin-top: 3rem
      img
        max-width: 90px
        max-height: 90px
        margin: 10px 20px
      .btn:hover
        color: inherit

.terms
  font-family: $font-secondary
  color: $secondary-color
  font-size: $third-size
  h1
    text-align: center
    font-size: $small-title-size
  .slogan
    text-align: center
    color: $primary-color
    margin-top: -10px
    margin-bottom: 15px
    font-size: $third-size
  h2, h3, h4
    font-size: $primary-size
    margin-top: 30px
  p, a
    color: $grey-text
    line-height: 1.6
    margin-bottom: 15px
  ul
    list-style: circle
    li
      color: $grey-text
      font-size: $third-size

.termination-survey
  max-width: 600px
  margin: auto
  min-height: 100vh
  font-family: $font-secondary
  h1
    margin: 1rem 0
    text-align: center
    font-size: $small-title-size
  h2
    font-size: $large-size
  label
    font-size: $primary-size
    font-weight: normal
    font-family: $font-primary
    margin: 2rem 0
