@import var

.side-menu
  height: 100%
  width: $sidemenu-size
  position: fixed
  z-index: 1
  top: 0
  left: 0
  background-color: $side-menu-color
  overflow-x: hidden
  text-align: center

  @media only screen and (max-width: $mobile-breakpoint-i)
    display: none

  .side-menu-link
    width: 100%
    height: 35px
    font-family: $font-primary
    font-size: $default-size
    color: $white-color
    margin-bottom: 4px
    border-left: 2px solid transparent

    a, .disabled-nav-link
      text-decoration: none
      font-size: $default-size
      color: $gray-color
      display: flex
      text-align: left
      height: 100%
      padding-top: 8px

    a:hover
      color: $gray-color
      text-decoration: none
      height: 100%

    .disabled-nav-link
      opacity: 0.5
      cursor: default

    .side-menu-title
      font-size: 14px

    .side-menu-image
      opacity: 0.5
      vertical-align: top

      &.without-opacity-reduction
        opacity: 1

    .with-notification
      display: flex
      justify-content: space-between
      padding-right: 16px

    .rectangle-active
      .side-menu-image
        opacity: 1
    .icon-active
      .side-menu-image
        opacity: 1

    &.subscription-menu-link
      color: #1DBE6D
      a
        color: #1DBE6D

  .side-menu-link:hover
    border-left: 2px solid white

  .side-menu-logo
    margin-left: 20px
    margin-top: 22px
    width: 26px

  .side-menu-logo-affiliated
    margin-bottom: 10px

  .shape
    width: 18px
    height: 18px
    margin-left: 18px
    margin-right: 15px

  #first
    margin-top: 40px

  .rectangle-active
    background-color: $active-color
  .profile
    position: relative
    .notification-menu
      border-radius: 1.6px
      background-color: $notification-color
      font-family: $font-primary
      color: $white-color
      font-size: $x-small-size
      position: absolute
      top: 12px
      padding: 0.6px 2px 1px 2px

.navbar
  z-index: 999
  display: flex
  align-items: center

  .navbar-brand
    display: none
    font-size: inherit

    @media screen and (max-width: $mobile-breakpoint-i)
      width: 70px
      height: 70px
      display: flex
      margin-right: 0px

  .navbar-toggler
    border: none

.navbar-collapse
  background-color: white
  position: fixed
  width: 100%
  min-height: 100%
  left: 0
  right: 0
  top: 70px
  z-index: 999
  .side-menu-image
    margin-right: 16px

  .navbar-nav
    margin-top: 0px
    // IOS bug navbar-nav: need padding to scroll
    padding-bottom: 100px
    height: calc(100vh - 94px)
    overflow: auto
    .nav-item
      margin-bottom: 0
      background: #000F46

      @media only screen and  (max-width: $mobile-breakpoint-i)
        .nav-link
          padding: 22px
          font-family: $font-primary
          font-size: $primary-size
          color: white
          border: none
          text-decoration: none
          border-left: 2px solid transparent

          &.active,
          &:hover
            background: rgba(255, 255, 255, 0.2)
          &:hover
            border-left: 2px solid white
        &.subscription-menu-link
          color: #1DBE6D
          a
            color: #1DBE6D

      &.support
        background: white
        .nav-link
          color: black

.dropdownplus
  &.float-right
    float: right
