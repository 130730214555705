@import 'var'


.ant-modal-mask
  z-index: 1005 !important
.ant-modal-wrap
  z-index: 1006 !important

.modal-form
  .modal-content
    padding: 0
    .modal-header
      padding: 0 16px
      border-bottom: 1px solid $gray-color !important
      .modal-title
        padding-top: 12px
        font-size: 18px

        button
          opacity: 1
          margin: 0
          padding: 0
          color: $secondary-color
          position: relative
          display: inline-block
          top: auto
          right: auto
          width: auto
          height: auto
          font-size: 1.4rem

        .title-popup
          margin: 0
          float: none
          display: inline
          height: auto

    .modal-body
      margin-top: 0px

    .modal-footer
      padding-top: 0px


  &.user-mails-share
    .custom.contact-box
      border: none
      position: relative

      .radio
        position: absolute

        label
          display: none

      input
        width: 100%

      svg.fa-envelope
        position: absolute
        top: 16px
        left: 14px
        font-size: 14px
        color: $grey-text

  &.modal-usermail-query
    .modal-content
      padding: 0px

      button.close
        font-size: 1.6rem
        opacity: 1
        color: $secondary-color

  &.modal-map
    width: 730px
    max-width: initial

    .modal-content
      padding: 0
      .modal-body
        padding: 0

        > .row
          padding: 0

.ant-notification
  z-index: 10000

.communication-notifications
  position: fixed
  top: 69px
  left: 232px
  width: calc(100% - 232px)
  z-index: 3
  .notification
    font-size: $third-size
    background: #FFE4ED
    border-radius: 4px
    padding: 1rem
    margin-bottom: 1rem
    color: #282C2E
    a
      color: #282C2E
    .close.historique-close
      position: relative
      right: 0
      margin-top: 0
      margin-right: 0
      top: -3px
      > svg
        display: block
  @media only screen and (max-width: $mobile-breakpoint-i)
    left: 1rem
    right: 1rem
    top: 5rem
    width: auto
  &.demo
    .notification
      padding: 0.5rem 1rem
    .section-button
      margin-bottom: 0
      display: inline-block
      text-transform: uppercase
      padding: 10px
      margin-left: 2rem
      box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2)
      white-space: nowrap
    @media only screen and (max-width: 1700px)
      height: auto
      .section-button
        margin-left: 0
        margin-top: 0.5rem

.demo-continue-modal
  h3
    font-size: 16px
    padding-top: 20px
    font-family: AvenirLTPro-Roman

  .modal-footer
    justify-content: center

  button
    border-radius: 6px
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5)
    text-transform: uppercase
    min-width: 180px
    text-align: center


.demo-catch-lead-modal
  h1
    font-family: AvenirLTPro-Roman
    font-size: 21px
    font-weight: 900
    margin: 0
    display: inline-block
  .line
    width: 197px
    margin-top: 4px
    height: 2px
  p
    font-family: AvenirLTPro-Roman
    font-size: 15px
    font-weight: normal
    line-height: 1.6
    margin: 2rem 0
  .error-input
    text-align: left
  button
    text-transform: uppercase
  #avis-verifies
    text-align: center
    position: relative
    margin: 2rem 0
    .overlay
      width: 100%
      height: 100%
      z-index: 1
