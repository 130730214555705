@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Open+Sans:wght@600&display=swap");
@import "icons.css";
.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize !important;
}

.chevron::before {
  border-style: solid;
  border-width: 0.1rem 0.1rem 0 0;
  content: "";
  display: inline-block;
  height: 0.45em;
  left: 0.8rem;
  position: relative;
  top: 0.15em;
  transform: rotate(-45deg);
  margin-top: 8px;
  width: 0.45em;
  vertical-align: top;
}

.chevron.right:before {
  left: 0;
  transform: rotate(45deg);
}

.chevron.bottom:before {
  top: 0;
  transform: rotate(135deg);
}

.chevron.left:before {
  left: 0.25em;
  transform: rotate(-135deg);
}

.nopadding {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.fullscreen-container-loader {
  height: 60vh;
}

.common-loader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.partial-loader {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

@font-face {
  font-family: "AvenirLTStd-Roman";
  src: url("fonts/Avenir/37C428_0_0.eot");
  src: url("fonts/Avenir/37C428_0_0.eot?#iefix") format("embedded-opentype"), url("fonts/Avenir/37C428_0_0.woff2") format("woff2"), url("fonts/Avenir/37C428_0_0.woff") format("woff"), url("fonts/Avenir/37C428_0_0.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirLTPro-Roman";
  src: url("fonts/Avenir/37C428_1_0.eot");
  src: url("fonts/Avenir/37C428_1_0.eot?#iefix") format("embedded-opentype"), url("fonts/Avenir/37C428_1_0.woff2") format("woff2"), url("fonts/Avenir/37C428_1_0.woff") format("woff"), url("fonts/Avenir/37C428_1_0.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirLTStd-Heavy";
  src: url("fonts/Avenir/37C428_2_0.eot");
  src: url("fonts/Avenir/37C428_2_0.eot?#iefix") format("embedded-opentype"), url("fonts/Avenir/37C428_2_0.woff2") format("woff2"), url("fonts/Avenir/37C428_2_0.woff") format("woff"), url("fonts/Avenir/37C428_2_0.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirLTPro-Heavy";
  src: url("fonts/Avenir/37C428_3_0.eot");
  src: url("fonts/Avenir/37C428_3_0.eot?#iefix") format("embedded-opentype"), url("fonts/Avenir/37C428_3_0.woff2") format("woff2"), url("fonts/Avenir/37C428_3_0.woff") format("woff"), url("fonts/Avenir/37C428_3_0.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirLTPro-HeavyOblique";
  src: url("fonts/Avenir/37C428_4_0.eot");
  src: url("fonts/Avenir/37C428_4_0.eot?#iefix") format("embedded-opentype"), url("fonts/Avenir/37C428_4_0.woff2") format("woff2"), url("fonts/Avenir/37C428_4_0.woff") format("woff"), url("fonts/Avenir/37C428_4_0.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirLTStd-HeavyOblique";
  src: url("fonts/Avenir/37C428_5_0.eot");
  src: url("fonts/Avenir/37C428_5_0.eot?#iefix") format("embedded-opentype"), url("fonts/Avenir/37C428_5_0.woff2") format("woff2"), url("fonts/Avenir/37C428_5_0.woff") format("woff"), url("fonts/Avenir/37C428_5_0.ttf") format("truetype");
}
@font-face {
  font-family: "Avenir-semibold";
  src: url("fonts/Avenir/AvenirNext-DemiBold.woff2") format("woff2");
}
@font-face {
  font-family: "PublicoText-Bold";
  src: url("fonts/Avenir/PublicoText-Bold-Web.woff2") format("woff2");
}
@font-face {
  font-family: "OpenSans-semibold";
  src: url("fonts/Avenir/opensans-semibold.woff2") format("woff2");
}
/* font */
/* color */
/* text size */
/* utils */
body {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: "Open Sans", sans-serif;
  height: 100vh;
}
body #root {
  height: 100%;
}

.affiliatedLayout {
  padding: 30px 0px 30px 0px;
}

::-webkit-scrollbar {
  width: 5px;
  margin-right: 5px;
}

::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #7b7e80;
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
}

a.have-tooltip {
  position: relative;
  display: inline;
}
a.have-tooltip span {
  position: absolute;
  width: 100px;
  position: absolute;
  left: -35px;
  top: 20px;
  color: #fff;
  background: #1576E1;
  height: 30px;
  line-height: 30px;
  text-align: center;
  visibility: hidden;
}
a.have-tooltip span:after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -8px;
  width: 0;
  height: 0;
  border-bottom: 8px solid #1576E1;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}

a:hover.have-tooltip span {
  visibility: visible;
  z-index: 999;
}

.container {
  max-width: 100%;
  margin: 0;
}

@media only screen and (min-width: 1200px) {
  .row {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.icon-button {
  margin: 4px;
}

.logo.logo-mobile {
  width: 26px;
  height: 26px;
  margin: auto;
}

.valide-icon {
  width: 9.7px;
}

.pic-p {
  width: 177px !important;
  height: 38px;
  border-radius: 19px;
  background-color: #2dcfb3;
  overflow: hidden;
  font-family: AvenirLTPro-Heavy;
  font-size: 14px;
  color: #ffffff !important;
  text-align: center;
  line-height: 38px;
}

.house-icon {
  width: 40px;
  float: right;
  margin-right: 0px;
  margin-top: -4px;
}

.icon-valide {
  width: 18px;
  height: 18px;
  border-radius: 3.2px;
  background-color: #2dcfb3;
  padding: 4px;
  margin-right: 10px;
}

.arrow-button-left {
  width: 5px;
  margin-top: -3px;
  margin-left: 7px;
}

.outside-popup {
  margin-left: 5px !important;
}

.success-color input[type=checkbox]:checked + label:before {
  background-color: #2dcfb3;
  border-color: #2dcfb3;
  color: #fff;
}

.valide {
  background-color: #1576E1;
  border: 1px #1576E1;
}

.info-shape {
  margin: auto;
}
.info-shape .first-cercle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.info-shape .second-cercle {
  width: 73%;
  height: 73%;
  border-radius: 21px;
  position: absolute;
  top: 14%;
  left: 14%;
}
.info-shape .third-cercle {
  width: 64%;
  height: 64%;
  border-radius: 13.5px;
  position: absolute;
  top: 19%;
  left: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.large-shape {
  width: 57px;
  height: 57px;
}
.large-shape .inside-circle {
  width: 20px;
  height: 20px;
}
.large-shape.warning-shape .inside-circle, .large-shape.notification-shape .inside-circle {
  width: auto;
  color: #ffffff;
  font-size: 18px;
}

.small-shape {
  width: 34px;
  height: 34px;
}
.small-shape .inside-circle {
  max-width: 20px;
}
.small-shape .third-cercle span {
  padding: 0px !important;
  font-size: 10px;
  color: white;
}

.valide-shape .first-cercle {
  background-color: rgba(45, 207, 179, 0.1);
}
.valide-shape .second-cercle {
  background-color: rgba(45, 207, 179, 0.23);
}
.valide-shape .third-cercle {
  background-color: #2dcfb3;
}

.gray-shape .first-cercle {
  background-color: rgba(123, 126, 128, 0.1);
}
.gray-shape .second-cercle {
  background-color: rgba(123, 126, 128, 0.23);
}
.gray-shape .third-cercle {
  background-color: #7b7e80;
}

.warning-shape .first-cercle {
  background-color: rgba(255, 52, 101, 0.1);
}
.warning-shape .second-cercle {
  background-color: rgba(255, 52, 101, 0.23);
}
.warning-shape .third-cercle {
  background-color: #ff3465;
}

.notification-shape .first-cercle {
  background-color: rgba(45, 108, 213, 0.21);
}
.notification-shape .second-cercle {
  background-color: rgba(45, 108, 213, 0.42);
}
.notification-shape .third-cercle {
  background-color: #2d6cd5;
}

.inside-popup {
  margin-left: 0px !important;
}

.num-carte {
  float: left;
  margin-right: -5px;
  width: 96%;
}

.sidebar-row {
  width: 105%;
  margin-left: -10%;
}

.alert-warning {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #d2800d;
}
.alert-warning a {
  color: #b16700;
}

.primary-link {
  color: #1576E1;
  font-size: 13px;
}

.default-link {
  color: #1576E1;
  font-size: 11px;
  font-weight: normal;
  text-transform: uppercase;
  text-decoration: none;
}

.tab-link {
  font-family: AvenirLTPro-Heavy;
  font-size: 10px;
  color: #7b7e80;
  text-transform: uppercase;
  width: 136px;
  text-align: center;
}
.tab-link a {
  text-decoration: none;
}

.nav-tabs .nav-link.active {
  border: none;
  border-bottom: 1px solid #1576E1;
}

.nav-tabs .nav-link {
  border: none;
  text-decoration: none;
}

.inside-line {
  margin-top: 30px;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 30px;
}

.inside-text {
  margin-top: 21px;
  position: relative;
  margin-bottom: -8px;
  text-align: center;
  background: white;
  width: 232px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  color: #7b7e80;
}

.filtre-btn {
  top: 6px !important;
  left: -30px !important;
  width: auto;
}
.filtre-btn a {
  padding: 18px 21px 18px 21px;
  text-decoration: none;
}

.msg-confirm-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}
.msg-confirm-container #msg-confirm {
  display: block;
  position: relative;
}

@media only screen and (max-width: 900px) {
  .container {
    width: 100% !important;
    max-width: 100% !important;
    margin-left: 0px !important;
  }
}
.explain-container {
  margin: 10px 0;
  text-align: justify;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #7b7e80;
}
.explain-container p {
  margin: 0;
}

.page-title {
  margin: 0px;
}
.page-title h1.title {
  font-family: PublicoText-Bold;
  font-weight: 700;
  font-size: 22px;
}
@media screen and (min-width: 901px) {
  .page-title {
    display: none;
  }
}

/* font */
/* color */
/* text size */
/* utils */
.title {
  width: 208px;
  min-height: 57px;
  font-family: AvenirLTPro-Roman;
  font-size: 42px;
  color: #282c2e;
}
@media screen and (max-width: 900px) {
  .title {
    font-size: 35px;
  }
}

.title-sidebar {
  width: 208px;
  height: 57px;
  font-family: AvenirLTPro-Roman;
  font-size: 42px;
  color: #282c2e;
}

.section-title {
  height: 19px;
  font-family: AvenirLTPro-Heavy;
  font-size: 14px;
  color: #282c2e;
  text-transform: uppercase;
  margin-bottom: 28px;
}
@media only screen and (max-width: 1200px) {
  .section-title {
    margin-top: 46px;
  }
}
@media only screen and (max-width: 900px) {
  .section-title {
    text-align: left;
  }
}

.cadre-services .section-title {
  font-size: 14px;
}
.cadre-services .grid-row {
  padding: 0;
}

/* font */
/* color */
/* text size */
/* utils */
.section-button {
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;
  font-size: 13px !important;
  text-decoration: none;
  padding: 9px 24px 10px 24px;
  cursor: pointer;
  border: none;
  position: relative;
}
.section-button.btn-block svg {
  position: absolute;
  left: 40px;
  top: 12px;
}
.section-button:not(.btn-block) svg {
  margin-right: 14px;
}
.section-button.disabled {
  background-color: rgba(123, 126, 128, 0.3);
  color: white !important;
  border-color: transparent;
  cursor: default;
}

.btn:disabled {
  background: #d0d1d1;
  border: 1px solid #d0d1d1 !important;
}

.section-button.submit {
  background-color: #1576E1;
  color: #ffffff;
}
.section-button.submit:disabled {
  background-color: #dddddd;
  color: #ffffff;
  cursor: default;
}
.section-button.submit:hover:not(:disabled) {
  background-color: #0e519b;
}
.section-button.submit .submit-loader {
  display: inline-block;
  width: 20px;
  position: relative;
}

.primary-button {
  background-color: #1576E1 !important;
  color: #ffffff;
}

.primary-button:hover {
  text-decoration: none;
  color: #ffffff;
  background-color: #0e519b !important;
}

.red-button {
  background-color: #eb4968;
  color: #ffffff;
}

.red-button:hover {
  text-decoration: none;
  color: #ffffff;
}

.black-button {
  background-color: #191D20;
  color: #ffffff;
}

.black-button:hover {
  text-decoration: none;
}

.gray-button {
  background-color: #dddddd !important;
  color: #ffffff;
}

.gray-button:hover {
  text-decoration: none;
  color: white;
}

.white-button {
  border: solid 1px #dddddd !important;
  background: #ffffff;
  color: #282c2e;
}

.transparent-button {
  border: solid 1px #dddddd !important;
  background: transparent;
  color: #282c2e;
}

.button-pic {
  width: 15px;
  height: 15px !important;
  margin-bottom: 1px !important;
  margin-right: 10px;
}

.btn-align-right {
  float: right;
}

.auto-button {
  margin-left: auto;
  margin-right: auto;
}

input[type=file] {
  cursor: pointer;
}

.svg-white {
  fill: white;
}

.plus-utils-button {
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  padding: 9px 24px 10px 24px;
  position: relative;
  cursor: pointer;
}
.plus-utils-button svg {
  margin-right: 8px;
  font-size: 17px;
  vertical-align: middle;
}

/* font */
/* color */
/* text size */
/* utils */
table {
  margin-bottom: 1rem;
  background-color: transparent;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
}
table td, table th {
  border-top: none !important;
  border-bottom: 1px solid #dee2e6 !important;
}

.content-table {
  color: #7b7e80;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
}

.hover-tr {
  cursor: pointer;
}

.first-col {
  color: #282c2e;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}
@media only screen and (max-width: 900px) {
  .first-col {
    padding-left: 7% !important;
  }
}

/* font */
/* color */
/* text size */
/* utils */
.dropdown-item:hover, .dropdown-item:focus,
button:hover,
button:focus {
  outline: none;
}

.dropdown-element {
  height: 50px;
}

.dropdown-item {
  outline-color: transparent;
}
.dropdown-item svg {
  margin-top: -5px;
  margin-left: -10px;
  margin-right: 10px;
}

.dropdown-item:active {
  background: #f8f9fa;
}

.dropdown-divider {
  width: 80%;
  margin: 5% 10% 5% 10%;
}

/* font */
/* color */
/* text size */
/* utils */
.no-center {
  text-align: left !important;
}

.center {
  text-align: center;
}

.no-transform {
  text-transform: none;
}

.right-topmargin {
  float: right;
  margin-top: 5px;
}

#fullwidth {
  width: auto !important;
}

@media only screen and (max-width: 900px) {
  .width100-mobile {
    width: 100% !important;
  }
}

.width100 {
  width: 100%;
  text-align: center;
}

.section-top {
  margin-top: -15px;
  margin-bottom: 25px;
}

.col-special-3 {
  width: 40%;
}

.nomargin-left-right {
  margin-left: 0px;
  margin-right: 0px;
}

.margin-right {
  margin-right: 15px;
}

.text-align-left {
  text-align: left !important;
}

@media only screen and (max-width: 900px) {
  .hidden-mobile {
    display: none;
  }
}

.arrow-right {
  width: 7.6px;
  height: 9.4px;
  opacity: 0.6;
}

.right {
  float: right;
  width: auto;
  margin-right: 18%;
  color: #7b7e80;
}

.float-none {
  float: none !important;
}

.auto-margin {
  margin: auto !important;
}

.bottom10 {
  margin-bottom: 10px;
}

.spacer-right {
  margin-right: 15px !important;
}

.spacer {
  padding: 15px;
}

.under-space {
  margin-top: 12px;
}

@media only screen and (max-width: 900px) {
  .margin55 {
    margin-top: 55px;
  }
}

@media only screen and (max-width: 900px) {
  .margin22 {
    margin-top: 22px;
  }
}

.full-padding {
  margin-top: -30px;
  overflow-y: -webkit-paged-x;
}
@media only screen and (max-width: 900px) {
  .full-padding {
    margin-left: 0px !important;
    padding: 15px;
  }
}

@media only screen and (max-width: 900px) {
  .notop-mobile {
    margin-top: 0px;
  }
}

.spacer-2 {
  padding: 8px;
}

.top-space {
  padding-top: 30px;
}

.bottom-box {
  margin-top: 40px;
  margin-bottom: 30px;
  width: 100%;
}

@media only screen and (max-width: 900px) {
  .noheight-btn {
    height: 40px !important;
  }
}

@media only screen and (max-width: 900px) {
  .top-space-mobile {
    margin-top: 60px;
  }
}

.padding-left-right {
  padding-left: 50px;
  padding-right: 50px;
}

.extra-style {
  font-size: 24px;
  margin-bottom: 15px;
}
@media only screen and (max-width: 900px) {
  .extra-style {
    margin-top: 70px;
  }
}

.left-border {
  border-left: 1px solid #dddddd;
  padding-top: 0px;
}
@media only screen and (max-width: 900px) {
  .left-border {
    border: none;
  }
}

.no-align {
  text-align: left !important;
}

.no-hover {
  color: #7b7e80;
}
.no-hover .nav-arrow-right {
  opacity: 0.2;
}

.no-hover:hover {
  box-shadow: none !important;
  background: none !important;
  color: #7b7e80;
}

.number-big {
  font-size: 24px;
  color: #1576E1;
}

.map2 {
  width: 230px;
}

.tooltip-container {
  display: inline;
  margin-left: 4px;
}

/* font */
/* color */
/* text size */
/* utils */
input,
textarea {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #7b7e80;
}

input.form-control,
textarea.form-control {
  color: #7b7e80;
}
input.form-control:focus,
textarea.form-control:focus {
  color: #7b7e80;
}

input.form-control {
  height: 37px;
}

input.form-control.hidden-border.num-carte:focus {
  border: none !important;
}

input.form-control.hidden-border.dte-carte:focus {
  border: none !important;
}

input.form-control.hidden-border.cvc-carte:focus {
  border: none !important;
}

input[type=text]:focus {
  outline: 0 none !important;
}

input[type=password]:focus {
  border: 1px solid #1576E1;
  outline: 0 none !important;
}

input[type=checkbox] + label {
  display: block;
  margin: 0em;
  cursor: pointer;
  padding: 0em;
  height: 16px;
}

input[type=checkbox] {
  display: none;
}

select {
  background-color: white;
  font-family: "Open Sans", sans-serif;
  color: #7b7e80;
  background: url("images/double-arrow-icon.svg") 95% 50% no-repeat;
}
select.with-question-mark {
  padding: 10px;
  height: 42px;
  width: 100%;
}
select.no-border {
  border: none;
}
@media screen and (max-width: 900px) {
  select {
    width: 100%;
    appearance: none;
    background: url("images/double-arrow-icon.svg") 97% 50% no-repeat;
  }
  select.in-error {
    border-color: #1576E1;
  }
}

input[type=checkbox] + label:before {
  content: "✔";
  border: 0.1em solid #dddddd;
  border-radius: 0.25em;
  width: 30px;
  height: 30px;
  display: inline-block;
  padding: 1px 6px;
  margin-right: 0.1em;
  vertical-align: middle;
  color: transparent;
  transition: 0.1s;
}

.courriers-table tbody input[type=checkbox] + label:before {
  display: none;
}
.courriers-table tbody input[type=checkbox]:checked + label:before {
  display: unset;
}
.courriers-table tr:hover input[type=checkbox] + label:before {
  display: unset;
}

input[type=checkbox] + label:active:before {
  transform: scale(0);
}

input[type=checkbox]:checked + label:before {
  background-color: #1576E1;
  border-color: #1576E1;
  color: #fff;
}

input[type=checkbox]:disabled + label:before {
  transform: scale(1);
  background-color: #dddddd;
  border-color: #dddddd;
}

input[type=checkbox]:checked:disabled + label:before {
  transform: scale(1);
  background-color: #dddddd;
  border-color: #dddddd;
}

input[type=radio]:checked, input[type=radio]:not(:checked) {
  position: absolute;
}
input[type=radio]:checked + label, input[type=radio]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #136aca;
}
input[type=radio]:checked + label:before, input[type=radio]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
input[type=radio]:checked + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #1576E1;
  position: absolute;
  top: 3px;
  left: 3px;
  border: 1px solid #1576E1;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
input[type=radio]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #1576E1;
  position: absolute;
  top: 3px;
  left: 3px;
  border: 1px solid #1576E1;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
input[type=radio]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
input[type=radio]:checked + .cadre-radio {
  border: red 1px solid !important;
}

.input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-top: -1px;
  height: 35px;
  width: 35px;
  background-color: #7b7e80;
  border: 1px #7b7e80;
  display: block;
  padding-left: 10px !important;
  padding-top: 3px !important;
  padding-right: 16px !important;
}

.form-control:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: rgba(123, 126, 128, 0.5882352941);
}

.no-style-select {
  background: none;
  border: none;
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url("images/double-arrow-icon.svg") 100% 35% no-repeat;
  background-size: 12px;
}
@media only screen and (max-width: 900px) {
  .no-style-select {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: #7b7e80;
    margin-top: 0px;
    background: url("images/double-arrow-icon.svg") 100% 48% no-repeat;
  }
}

.cadre-filtre-input {
  margin-bottom: 24px;
  padding: 8px 0px 0px 0px;
  width: 100%;
  border-radius: 4px;
}
.cadre-filtre-input .question-form {
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  color: #7b7e80;
  margin-bottom: 4px;
}
.cadre-filtre-input input,
.cadre-filtre-input .custom-select > .field {
  border: solid 1px #dddddd;
}
.cadre-filtre-input .custom-select.in-error > .field {
  border-color: #1576E1;
}
.cadre-filtre-input p {
  margin-bottom: 0px;
}
.cadre-filtre-input .field {
  min-height: 35px;
}

.cadre-filtre-input input, .cadre-filtre-input textarea, .cadre-filtre-input select, .cadre-filtre-input .select-country > .input {
  border: solid 1px #dddddd;
  border-radius: 4px;
  color: #7b7e80;
}
.cadre-filtre-input input::placeholder, .cadre-filtre-input textarea::placeholder, .cadre-filtre-input select::placeholder, .cadre-filtre-input .select-country > .input::placeholder {
  color: #dddddd;
}
.cadre-filtre-input input.in-error, .cadre-filtre-input textarea.in-error, .cadre-filtre-input select.in-error, .cadre-filtre-input .select-country > .input.in-error {
  border-color: #1576E1;
}
.cadre-filtre-input .error-input {
  margin-top: 6px;
  color: #1576E1;
  font-size: 10px;
}

.select-country > .input > div {
  border: none;
}

select {
  border-radius: 4px;
  padding: 0.375rem 0.75rem;
}

.cadre-filtre-input.dateinput input {
  border-radius: 4px;
  font-size: 16px;
  color: #7b7e80;
  padding: 0.375rem 0.75rem;
  height: 35px;
}
.cadre-filtre-input.dateinput input::placeholder {
  color: #7b7e80;
}
.cadre-filtre-input.dateinput.in-error input {
  border-color: #1576E1;
}

.SingleDatePickerInput {
  border: none !important;
  width: 100%;
}

.DateInput, .SingleDatePicker {
  width: 100%;
}

.btn.btn-white {
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: #282c2e;
  border: 1px solid #dddddd;
  border-radius: 4px;
}

input.in-error,
textarea.in-error {
  background-image: url("images/times-regular.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 8px;
  background-size: 12px;
}

.dateinput.in-error input {
  background-image: url("images/times-regular.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 8px;
  background-size: 12px;
}

/* font */
/* color */
/* text size */
/* utils */
.text-primary {
  font-family: AvenirLTPro-Roman;
  font-size: 11px;
  color: #2a5063 !important;
}

.text-primary-drop {
  font-family: AvenirLTPro-Roman;
  font-size: 11px;
  color: #2a5063 !important;
}

.text-warning {
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  color: #ffc36f;
}

.text-success {
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  color: #2dcfb3;
}

.text-default {
  font-family: AvenirLTPro-Roman;
  color: #1576e1 !important;
  font-size: 11px;
}

.text-alert {
  font-family: AvenirLTPro-Roman;
  color: #1576E1;
  font-size: 11px;
}

.info {
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  color: #282c2e;
  text-align: right;
}

.upcase {
  text-transform: uppercase;
}

.blue {
  color: #1576E1;
}

.third-size {
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
}

.light-text {
  color: #7b7e80;
}

a {
  color: #282c2e;
  cursor: pointer;
}
a:disabled {
  cursor: not-allowed;
}
a .rectangle-title {
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: initial;
  text-decoration: initial;
}

/* font */
/* color */
/* text size */
/* utils */
.bottom-space-30 {
  margin-bottom: 30px;
}

.full-border {
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  color: #282c2e;
  border-bottom: 1px solid #ececec;
}

.no-margin {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.gray-background {
  background: whitesmoke;
}
@media screen and (min-width: 901px) {
  .gray-background {
    margin-top: 140px;
  }
}

.mobile-setting-breadcrumb {
  display: none;
}
@media screen and (max-width: 900px) {
  .mobile-setting-breadcrumb {
    color: #282c2e;
    font-size: 24px;
    display: inline-block;
  }
}

.header-child-setting {
  display: none;
}
.header-child-setting .title-head {
  margin-top: 20px;
  line-height: 0.8;
}

.sub-title {
  font-size: 18px;
  color: #7b7e80;
  padding-top: 20px;
  padding-left: 10px;
}

.top-margin {
  margin-top: 50px;
  height: 45px;
}

.left {
  float: left;
}

.margin-left {
  margin-left: 30px;
}

.margin-left10 {
  margin-left: 10px !important;
}

.logo-brand {
  width: 110px;
}

.flow-root {
  display: table;
  width: 100%;
}

.info-sub {
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  color: #7b7e80;
  display: -webkit-box;
}

.full-width {
  width: 100% !important;
}

.text-tag {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #7b7e80;
  height: 29px;
  border-radius: 14.5px;
  background-color: rgba(123, 126, 128, 0.1);
  float: left;
  padding: 5px;
  padding-left: 15px;
  margin-top: 10px;
  margin-right: 20px;
}

.padding50 {
  padding: 40px;
}

.spacer-mobile {
  display: none;
}

.width200 {
  width: 200px;
}

.notif {
  position: relative;
}

@media only screen and (max-width: 901px) {
  .header-child-setting {
    display: block;
    width: 100%;
    background: whitesmoke;
    left: 0px;
    padding: 10px 20px 20px 20px;
  }
  .header-child-setting .setting-link-back {
    padding: 20px 0 0 6px;
    display: block;
    height: auto;
  }
  .header-child-setting .setting-link-back img {
    margin-right: 8px;
  }
  .right-content-setting {
    margin-left: 0px;
    margin-top: 209px;
  }
  .top-margin {
    height: 70px;
  }
  .mobile-right {
    float: right;
    margin-left: 5px !important;
  }
  .double-bottom {
    margin-bottom: 100px !important;
  }
  .flow-root {
    height: 70px;
  }
  .carte-info-mobile {
    float: left;
    margin-top: -25px !important;
    position: relative;
    width: 130px;
  }
  .carte-height {
    height: 100px;
  }
  .carte-height-small {
    height: 80px;
  }
  .carte-paiement {
    margin-left: 0px;
  }
  .margin-left10 {
    margin-left: 0px !important;
  }
  .padding50 {
    padding: 0px;
    float: left;
    margin-left: 30%;
    padding-top: 30%;
  }
  .bloc-notification-large {
    width: 72%;
    margin-top: 10px;
  }
  .carte-group {
    position: absolute;
    width: 90%;
  }
  .spacer-mobile {
    display: block;
    padding: 20px;
  }
  .text-tag {
    margin-top: 50px;
    margin-bottom: -35px;
    width: 100%;
  }
}
/* font */
/* color */
/* text size */
/* utils */
.validation-error {
  color: red;
  font-size: 11px;
}

/* font */
/* color */
/* text size */
/* utils */
.react-confirm-alert-body {
  font-family: "Open Sans", sans-serif;
}
.react-confirm-alert-body button {
  background-color: #1576E1;
  color: white;
  border-radius: 4px;
}

.large-alert-popup {
  height: 70px;
  position: absolute;
  top: 0;
  right: 0;
}

.large-alert-popup.slide {
  animation: large_show_slide 1s ease forwards;
}

@keyframes large_show_slide {
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0%);
  }
}
.large-alert-popup.hide {
  animation: large_hide_slide 1s ease forwards;
}

@keyframes large_hide_slide {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}
.successNotification {
  background-color: #E7F5DC !important;
}

.successNotificationWthDesc {
  background-color: #E7F5DC !important;
}
.successNotificationWthDesc .ant-notification-notice-message {
  margin-bottom: 0 !important;
}

.errorNotification {
  background-color: #FFCDCC !important;
}

.errorNotificationWthDesc {
  background-color: #FFCDCC !important;
}
.errorNotificationWthDesc .ant-notification-notice-message {
  margin-bottom: 0 !important;
}

/* font */
/* color */
/* text size */
/* utils */
#react-confirm-alert .react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.5);
  z-index: 1004;
}
#react-confirm-alert .react-confirm-alert-overlay .react-confirm-alert-body {
  border-radius: 0;
}
#react-confirm-alert .react-confirm-alert-overlay .react-confirm-alert-body h1 {
  height: 33px;
  font-family: AvenirLTPro-Roman;
  font-size: 18px;
  color: #282c2e;
}

.fa-two-ways-angle svg {
  display: block;
  height: 14px;
}
.fa-two-ways-angle svg:last-child {
  margin-top: -4px;
}

/* font */
/* color */
/* text size */
/* utils */
.password-strength {
  height: 10px;
  display: flex;
  justify-content: space-between;
}
.password-strength.empty {
  visibility: hidden;
}
.password-strength .block {
  margin: 1px;
  border: 1px solid #dee2e6;
  width: 100%;
}
.password-strength.score-1 .block.highlight, .password-strength.score-2 .block.highlight {
  background-color: red;
}
.password-strength.score-3 .block.highlight {
  background-color: #d2800d;
}
.password-strength.score-4 .block.highlight, .password-strength.score-5 .block.highlight {
  background-color: #2dcfb3;
}

input[type=checkbox] + label.checkbox-label {
  padding-left: 26px;
}
input[type=checkbox] + label.checkbox-label::before {
  margin-left: -26px;
  margin-right: 12px;
}

/* font */
/* color */
/* text size */
/* utils */
/* Second Nav sidebar */
.sidebar-customize {
  background-color: #fafafa;
  right: initial;
}
@media only screen and (max-width: 900px) {
  .sidebar-customize {
    right: 0px;
  }
}

.root-sidebar-customize {
  margin-left: 232px;
  margin-top: 70px;
}
@media only screen and (max-width: 900px) {
  .root-sidebar-customize {
    margin-left: 0px;
  }
}

.gray-sidebar-profile {
  background: whitesmoke;
  position: fixed;
  top: 70px;
  margin-left: -40px;
  width: 378px !important;
  padding-left: 40px;
  margin-right: 44px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}
@media screen and (min-width: 901px) and (max-width: 946px) {
  .gray-sidebar-profile {
    width: 282px !important;
    height: 100%;
    padding-left: 10px;
    margin-right: 0px;
  }
  .gray-sidebar-profile a.section-button {
    padding: 9px 10px 10px 9px;
  }
}
@media only screen and (max-width: 901px) {
  .gray-sidebar-profile {
    width: 100% !important;
    margin: -10px;
    height: 102vh;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.gray-sidebar-profile .title-profil {
  font-family: AvenirLTPro-Roman;
  font-size: 24px;
  text-align: center;
  color: #282c2e;
  text-transform: capitalize;
}
.gray-sidebar-profile .profile-option {
  padding: 60px 0px 60px 32px;
}
.gray-sidebar-profile a.section-button {
  margin-left: 20px;
}

.gray-sidebar {
  background-color: #fafafa;
}
.gray-sidebar .nav-element {
  min-width: 220px;
  height: 88px;
  line-height: 88px;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #282c2e;
}
.gray-sidebar .nav-element a, .gray-sidebar .nav-element .disabled-nav-link {
  display: block;
  text-decoration: none;
  color: #282c2e;
}
.gray-sidebar .nav-element a .inner-settings-nav, .gray-sidebar .nav-element .disabled-nav-link .inner-settings-nav {
  margin: 0 26px 0 34px;
  border-bottom: solid 1px #f0efef;
}
.gray-sidebar .nav-element a .inner-settings-nav .nav-arrow-right, .gray-sidebar .nav-element .disabled-nav-link .inner-settings-nav .nav-arrow-right {
  width: 8.6px;
  height: 9.4px;
  float: right;
  margin-top: 40px;
}
.gray-sidebar .nav-element a:hover, .gray-sidebar .nav-element a.active {
  color: #1576e1;
  background: #e7f2ff;
}
.gray-sidebar .nav-element a:hover img, .gray-sidebar .nav-element a.active img {
  filter: invert(38%) sepia(67%) saturate(1385%) hue-rotate(185deg) brightness(91%) contrast(97%);
}
.gray-sidebar .nav-element a:hover .inner-settings-nav, .gray-sidebar .nav-element a.active .inner-settings-nav {
  border-bottom: none;
}
.gray-sidebar .nav-element .disabled-nav-link {
  opacity: 0.5;
  cursor: default;
}

/* font */
/* color */
/* text size */
/* utils */
.custom-select {
  position: relative;
  margin: 0;
  border: none;
  background: none;
  padding: 0;
  color: #7b7e80;
  font-family: "Open Sans", sans-serif;
  height: 35px;
}
.custom-select .field {
  border: 1px solid #dddddd;
  border-radius: 4px;
  display: flex;
  padding: 6px 8px 6px 12px;
  cursor: pointer;
}
.custom-select .field > .display {
  height: 23px;
}
.custom-select .field.placeholder-option .display {
  color: #dddddd;
}
.custom-select.no-border .field {
  border: none;
}
.custom-select.with-question-mark .options {
  box-shadow: 0px 14px 22px rgba(0, 0, 0, 0.1);
  margin-left: -1px;
  margin-right: -1px;
}
.custom-select.open {
  border: 1px solid #1576E1;
}
.custom-select.open .field {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.custom-select .display {
  flex: 1;
}
.custom-select .button {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  color: #dddddd;
}
.custom-select .options {
  background: white;
  border: 1px solid #dddddd;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  left: 0;
  position: absolute;
  padding: 0;
  right: 0;
  top: 100%;
  z-index: 3;
  margin-top: 2px;
}
.custom-select .option {
  cursor: pointer;
  padding: 14px 0px 12px 10px;
  min-height: 42px;
  border-bottom: 1px solid #dddddd;
}
.custom-select .option:hover {
  background-color: #F9F9F9;
}
.custom-select .option:last-child {
  border-bottom: none;
}
.custom-select .overlay {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
}

/* font */
/* color */
/* text size */
/* utils */
.CalendarMonth_table .DayPicker_weekHeader_li, .SingleDatePicker .DayPicker_weekHeader_li {
  color: rgba(123, 126, 128, 0.7);
}

.SingleDatePicker .SingleDatePicker_picker {
  top: 36px !important;
}

.CalendarMonth_table {
  margin-top: 10px;
}
.CalendarMonth_table td, .CalendarMonth_table th {
  border: none !important;
}
.CalendarMonth_table td.CalendarDay__selected_span, .CalendarMonth_table td.CalendarDay__selected:hover, .CalendarMonth_table td.CalendarDay__hovered_span:hover, .CalendarMonth_table td.CalendarDay__hovered_span, .CalendarMonth_table th.CalendarDay__selected_span, .CalendarMonth_table th.CalendarDay__selected:hover, .CalendarMonth_table th.CalendarDay__hovered_span:hover, .CalendarMonth_table th.CalendarDay__hovered_span {
  border: none;
  border-bottom: 1px solid #136aca !important;
  color: #136aca;
  background: #ffffff;
}
.CalendarMonth_table td.CalendarDay__selected, .CalendarMonth_table td.CalendarDay__default:hover, .CalendarMonth_table th.CalendarDay__selected, .CalendarMonth_table th.CalendarDay__default:hover {
  background: #1576E1;
  border: 1px solid #136aca;
  color: #ffffff;
}

/* font */
/* color */
/* text size */
/* utils */
.close {
  font-size: 2rem;
  width: 22px;
  height: 22px;
  margin-top: 24px;
  margin-right: 20px;
}

.modal-title {
  width: 100%;
  min-height: 32px;
}
.modal-title .close.historique-close {
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
}

.modal-content {
  border: 0px;
  border-radius: 16px;
}

.modal-header {
  border-bottom: none !important;
  padding-bottom: 10px !important;
}
@media only screen and (max-width: 900px) {
  .modal-header {
    display: block;
    padding-bottom: 0;
  }
}

.modal-footer {
  border-top: none !important;
}
.modal-footer.full-width {
  display: block;
}

.modal-large {
  width: 900px;
  max-width: 900px;
}
@media only screen and (max-width: 950px) {
  .modal-large {
    max-width: 100%;
  }
}
@media only screen and (max-width: 900px) {
  .modal-large {
    width: 103%;
    margin: -3px;
    margin-top: 88px;
    border: none !important;
  }
}
.modal-large .modal-content {
  border-radius: 8px !important;
}
.modal-large .modal-body {
  margin: 10px 50px 50px 50px;
}

.modal-fullsize {
  width: 100%;
  height: 100%;
  padding: 40px;
  margin: 0;
  max-width: initial;
  max-height: 100vh;
}

.gray-zone {
  background: whitesmoke;
  position: relative;
  margin-top: -15px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: -15px;
}
@media only screen and (max-width: 900px) {
  .gray-zone {
    margin-top: 24px;
    margin-left: 0;
  }
}

.title-popup {
  height: 33px;
  font-family: AvenirLTPro-Roman;
  font-size: 18px;
  color: #282c2e;
  float: left;
  margin-bottom: 0px;
  margin-top: 12px;
}
@media only screen and (max-width: 900px) {
  .title-popup {
    width: 100%;
    float: none;
    margin-top: 20px;
  }
}

#re-expedition .close {
  right: 0;
  position: absolute;
  top: 0;
  margin-top: 8px;
  margin-right: 8px;
}
#re-expedition .title-popup {
  float: none;
}
#re-expedition .main-title-popup {
  font-size: 22px;
}
#re-expedition .cart-title {
  margin-top: 24px;
}
#re-expedition .reexpedition-title {
  margin-top: 40px;
}
#re-expedition .gray-text {
  color: #7b7e80;
}
#re-expedition .black-text {
  color: #282c2e;
}

.dropdown-menu-popup {
  width: 290px;
  height: 35px;
  border-radius: 4px;
  border: solid 1px #dee2e6;
  color: #282c2e;
  padding: 7px;
  margin-top: 3px;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #282c2e;
  float: right;
}
@media only screen and (max-width: 900px) {
  .dropdown-menu-popup {
    width: 100%;
  }
}

.dropdownadresse {
  width: 290px !important;
  padding: 20px;
}
.dropdownadresse .adr-popup {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #282c2e;
}
.dropdownadresse .ville-popup {
  font-size: 11px;
  color: #7b7e80;
  margin-top: -10px;
}
.dropdownadresse .popup-element-plus {
  font-size: 13px;
  color: #1576E1;
  margin-top: 20px;
}
.dropdownadresse .popup-element-plus a {
  color: #1576E1;
}

.dropdown-divider-popup {
  width: 100%;
  overflow: hidden;
  border-top: 1px solid #dee2e6;
  font-family: "Open Sans", sans-serif;
}

.lettre-bloc {
  width: 269px;
  margin-top: 40px;
  margin-right: 22px;
  float: left;
}
@media only screen and (max-width: 900px) {
  .lettre-bloc {
    width: 99%;
  }
}

.title-lettre-bloc {
  font-family: AvenirLTPro-Roman;
  font-size: 18px;
  color: #282c2e;
}

.right-popup {
  float: right;
  width: auto;
  padding-top: 5px;
}

.dte-inside {
  float: left;
  margin-right: 20px;
  color: #7b7e80;
  font-size: 13px;
  margin-top: -3px;
}

.lettre-popup {
  border: solid 1px #f5f5f5;
}

.table-popup {
  width: 100%;
  font-size: 13px;
  color: #282c2e;
}
.table-popup tr {
  height: 31px;
  border-bottom: none !important;
}
.table-popup tr td {
  border-bottom: none !important;
}
.table-popup tr td span.already-queried {
  margin-left: 10px;
  color: #1576E1;
}
.table-popup .price {
  text-align: right;
}
.table-popup .delete {
  width: 35px;
  text-align: right;
  padding-bottom: 4px;
}
@media only screen and (max-width: 900px) {
  .table-popup {
    margin-top: 15px;
  }
}

.already-shipped-tooltip.tooltip .arrow::before {
  border-bottom-color: #1576E1;
}
.already-shipped-tooltip.tooltip .tooltip-inner {
  border-radius: 0;
  background: #1576E1;
}

.dte-table-popup {
  font-size: 11px;
  color: #7b7e80;
}

.arrow-info {
  margin-top: -2px;
  margin-left: 10px;
}

.flex-none {
  display: block !important;
}

.full-screen {
  width: 100%;
  height: 100vh;
  max-width: 100%;
  margin: 0;
  top: 0;
  left: 0;
  z-index: 9999;
}

.vertical-popup {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-top: 25px;
}
@media only screen and (max-width: 900px) {
  .vertical-popup {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.resume-popup {
  text-align: center;
}
.resume-popup .btn {
  margin: 20px auto 0 auto;
}

.left-popup {
  float: left;
  width: auto;
}

.right-popup {
  float: right;
  width: auto;
}

.padding-15 {
  padding-top: 15px;
}

.popup-button {
  margin-top: 30px !important;
}

.popup-link {
  font-size: 13px;
  color: #282c2e;
  text-align: center;
}

.historique-header {
  display: block !important;
}

.historique-divider {
  width: 97% !important;
  margin: auto;
}

.histo-num {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #282c2e;
  width: 150px;
  float: left;
}
@media only screen and (max-width: 900px) {
  .histo-num {
    float: none !important;
  }
}

.historique-cadre {
  margin-top: 16px;
  height: 118px;
}
@media only screen and (max-width: 900px) {
  .historique-cadre {
    margin-top: 16px;
    height: auto;
    border-bottom: 1px solid #dee2e6;
  }
}

.histo-dte {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #7b7e80;
}

.histo-content {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #282c2e;
  white-space: pre-line;
}

.modal-height {
  height: 560px;
  overflow-y: auto;
}
@media only screen and (max-width: 900px) {
  .modal-height {
    height: auto;
    border: none !important;
  }
}

.histo-name {
  text-align: right;
}

@media only screen and (max-width: 900px) {
  .modal-small {
    margin-top: 88px;
  }
}
.success-msg {
  font-family: AvenirLTPro-Roman;
  font-size: 24px;
  text-align: center;
  color: #191D20;
  margin-top: 20px;
  font-weight: 700;
}

.warning-msg {
  font-family: AvenirLTPro-Roman;
  font-size: 24px;
  text-align: center;
  color: #1576E1;
  margin-top: 20px;
}

.small-popup-content {
  width: 85%;
  margin: auto;
  text-align: center;
}

.popup-input {
  font-family: "Open Sans", sans-serif !important;
  font-size: 13px !important;
  color: #7b7e80;
}

.carte-add-input {
  margin-top: 50px;
  margin-bottom: 40px;
}

.hidden-border {
  border: none;
  width: 100%;
  padding-left: 30px;
}
@media only screen and (max-width: 900px) {
  .hidden-border {
    width: 44%;
    padding-left: 25px;
  }
}

.arrow-input {
  position: absolute;
  padding-left: 10px;
  pointer-events: none;
  z-index: 1;
  padding-top: 9px;
}

.arrow-msg {
  padding-top: 12px !important;
}

.dte-carte,
.cvc-carte {
  float: left;
  width: 110px;
}
@media only screen and (max-width: 900px) {
  .dte-carte,
  .cvc-carte {
    width: 90px;
  }
}

.carte-group {
  border: 1px solid #dddddd;
  height: 35px;
  border-radius: 5px;
  width: 100%;
}

.width100 {
  width: auto;
}
@media only screen and (max-width: 900px) {
  .width100 {
    width: 100%;
  }
}

.nomargin {
  margin-left: auto;
}
@media only screen and (max-width: 900px) {
  .nomargin {
    margin-left: 0px !important;
  }
}

.topbottom {
  margin-top: auto;
  margin-bottom: auto;
}
@media only screen and (max-width: 900px) {
  .topbottom {
    margin-top: -80px;
    margin-bottom: -30px;
  }
}

.declare-icon {
  position: absolute;
  right: 20px;
  top: 25px;
  z-index: 999;
}

.select-declare {
  height: 208px !important;
  border-radius: 4px;
  border: solid 1px #f5f5f5;
  margin-top: 1rem;
  font-family: AvenirLTPro-Roman;
  font-size: 13px !important;
}

.contact-box {
  border: 1px solid #f5f5f5;
  border-radius: 0.25rem;
  padding: 5px;
  float: left;
  margin-right: 9px;
  margin-bottom: 10px;
  width: 47%;
}
.contact-box .radio {
  float: left;
  padding: 10px;
}
@media only screen and (max-width: 900px) {
  .contact-box {
    border: 1px solid #f5f5f5;
    border-radius: 0.25rem;
    padding: 5px;
    float: left;
    margin-right: 9px;
    margin-bottom: 20px;
  }
}

.padding-top {
  padding-top: -30px;
}

.label-radio-add {
  padding: 6px;
  text-align: -webkit-center;
  color: rgba(40, 44, 46, 0.46);
}
.label-radio-add a {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
}

.link-plus {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #282c2e;
  margin-left: 16px;
  margin-top: -19px;
  z-index: 9999;
}

.label-radio {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #282c2e !important;
  margin-bottom: 0px;
}

.label-radio-sub {
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  color: #7b7e80;
}

.user-mails-share .radio {
  width: auto;
}
.user-mails-share .contact-box.contact input[type=radio]:checked + label:before, .user-mails-share .contact-box.contact input[type=radio]:not(:checked) + label:before {
  top: 10px;
}
.user-mails-share .contact-box.contact input[type=radio]:checked + label:after, .user-mails-share .contact-box.contact input[type=radio]:not(checked) + label:after {
  top: 13px;
}
.user-mails-share .custom.contact-box {
  float: none;
  width: 100%;
}
.user-mails-share .custom.contact-box input {
  width: 89%;
  padding-left: 2rem;
}
.user-mails-share .link-plus {
  margin-top: 1rem;
  text-decoration: underline;
  display: block;
  margin-left: 0;
}

@media only screen and (max-width: 900px) {
  .user-mails-share .custom.contact-box input {
    width: 78%;
  }
  .contact-box {
    width: 100%;
  }
  .contact-box .label-radio-sub {
    float: none;
    margin-top: 0;
  }
}
.forwarding-address {
  margin-bottom: 2px;
}
@media only screen and (max-width: 900px) {
  .forwarding-address {
    margin-bottom: 2px;
  }
}

.adresse-popup {
  font-family: "Open Sans", sans-serif;
  margin-bottom: 0px;
}

.adr-ligne1 {
  font-size: 13px;
  color: #282c2e;
}

.adr-ligne2 {
  font-size: 11px;
  color: #7b7e80;
}

@media only screen and (max-width: 900px) {
  .alert-warning {
    margin-top: -15px;
  }
}

@media only screen and (max-width: 900px) {
  .re-close {
    right: 0px !important;
    position: absolute;
    top: -30px;
  }
}

.mail-card {
  height: 60px;
  line-height: 60px;
  border-radius: 4px;
  border: solid 1px #D7D7D7;
  margin-bottom: 15px;
  width: 50%;
  vertical-align: middle;
}
.mail-card .scan-container {
  position: relative;
  width: fit-content;
}
.mail-card img {
  float: left;
  margin: auto;
}
.mail-card .overlay {
  z-index: 10;
  position: absolute;
  width: stretch;
  height: stretch;
  opacity: 0;
  transition: none;
  top: 0;
  left: 0;
  transform: none;
  -ms-transform: none;
  text-align: center;
}
.mail-card .overlay .see {
  width: inherit;
  height: inherit;
}
.mail-card .overlay .see svg {
  position: absolute;
}

/* font */
/* color */
/* text size */
/* utils */
.slogan {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #7b7e80;
  margin-top: -15px;
}

.full-height {
  height: 100vh !important;
}
@media screen and (max-width: 900px) {
  .full-height {
    height: auto !important;
  }
}

.bureaux-cadre {
  min-height: 182px;
  margin: auto;
}

.info-text {
  font-family: AvenirLTPro-Heavy;
  font-size: 10px;
  color: #7b7e80;
  text-transform: uppercase;
  width: 100%;
}
.info-text.info-text-light {
  color: #bdbdbd;
}

.title-cadre {
  font-family: AvenirLTPro-Roman;
  font-size: 24px;
  color: #282c2e;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 8px;
}

.text-align-left {
  text-align: left !important;
}

.cadre-margin-top {
  margin-top: 50px;
}

.oval-cadre {
  float: none !important;
  margin-top: -3px !important;
}

.fixed-right {
  position: fixed;
  right: 0;
  padding: 60px;
}

.top-aside {
  margin-top: 30px;
}

.label-btn {
  width: 150px;
}

.nav__menu_filtre {
  position: fixed;
  right: -477px;
  top: 0;
  width: 477px;
  max-width: 30%;
  height: 100%;
  background: #fafafa;
  transition: all 0.5s ease;
  z-index: 1002;
  padding-top: 70px;
  padding-left: 48px;
  overflow-x: hidden;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
}
.nav__menu_filtre h4 {
  padding-left: 15px;
}

.question-form-gray {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #282c2e;
  margin-top: -20px;
}

.icon-select {
  position: absolute;
  right: 40px;
  margin-top: -15px !important;
  z-index: 9999;
}
@media only screen and (max-width: 900px) {
  .icon-select {
    margin-top: 11px !important;
  }
}

.icon-select2 {
  position: absolute;
  right: 40px;
  margin-top: 10px !important;
  z-index: 2;
}
@media only screen and (max-width: 900px) {
  .icon-select2 {
    margin-top: 11px !important;
  }
}

.interval-cadre {
  height: 170px;
}

.filtre-close {
  position: absolute;
  right: 30px;
  top: 0px;
  opacity: 1;
  z-index: 99999;
}
@media only screen and (max-width: 900px) {
  .filtre-close {
    right: 0px;
    top: -15px;
  }
}

.filtre-close-arrow {
  width: 40px;
  height: 40px;
}

.under-top {
  margin-top: -60px !important;
}

.align-right {
  text-align: right;
}

.width100-special-btn {
  width: 90%;
  margin-left: 35px;
  padding: 15px;
}

.no-padding {
  padding-right: 0px;
  padding-left: 0px;
}

.spacer-3 {
  padding: 15px;
}

.inside-input {
  font-family: "Open Sans", sans-serif !important;
  font-size: 16px !important;
  color: #7b7e80 !important;
}

.width100-special-btn-2 {
  width: 96%;
  margin-left: 17px;
  padding: 24px;
  height: auto !important;
}

.width100-special-btn-3 {
  width: 100%;
  padding: 24px !important;
  height: auto !important;
}

.cadre-radio {
  width: 280px;
  height: 228px;
  border-radius: 2px;
  border: solid 1px #dddddd;
  text-align: center;
  padding: 20px;
  margin: auto;
}

.title-radio {
  font-family: AvenirLTPro-Heavy;
  font-size: 14px;
  color: #7b7e80;
  text-transform: uppercase;
  margin-top: 10px;
}

.no-height {
  height: auto !important;
}

.subtitle-cadre {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #7b7e80 !important;
}

.input-radio-center {
  width: 18px;
  margin: auto;
  margin-top: 5px;
}

.width98 {
  width: 98%;
  margin: auto;
}

.radio-active {
  border: solid 1px #1576E1;
}

.top30-desktop {
  margin-top: 30px;
}

.style-strong {
  font-size: 16px !important;
  font-family: "Open Sans", sans-serif !important;
  color: #7b7e80 !important;
}

.text-area {
  height: 165px;
}

.fullscreen {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0px;
  top: -1px;
  left: -1px;
}
.fullscreen .modal-content {
  min-height: 100vh;
}
.fullscreen .close {
  z-index: 10002;
  position: absolute;
  right: 30px;
}

.list-rooms .bureaux-cadre .row:hover {
  background-color: #ececec;
  cursor: pointer;
}

.carousel-room .carousel-item {
  width: 100%;
}

@media only screen and (min-width: 900px) {
  #cliquable-filtre:checked ~ .nav__menu_filtre {
    display: block;
    overflow-y: scroll;
    right: 0;
  }
  #cliquable-filtre:checked ~ .full-padding {
    width: 62% !important;
    -webkit-transition: width 2s;
    transition: width 0.4s;
    margin-left: 3%;
  }
  #cliquable-filtre:not(:checked) ~ .nav__menu_filtre {
    display: block;
    overflow-y: scroll;
    right: 0;
  }
  #cliquable-filtre:not(:checked) ~ .full-padding {
    width: 62% !important;
    -webkit-transition: width 2s;
    transition: width 0.4s;
    margin-left: 3%;
  }
}
@media only screen and (max-width: 900px) {
  #cliquable-filtre:checked ~ .nav__menu_filtre {
    display: block;
    overflow-y: scroll;
    right: 0;
    width: 100%;
    max-width: 100%;
  }
  #cliquable-filtre:not(:checked) ~ .full-padding {
    width: 100%;
    -webkit-transition: width 2s;
    transition: width 0.5s;
  }
  .bureaux-cadre {
    height: auto;
  }
  .title-cadre {
    padding-bottom: 0px !important;
  }
  .cadre-margin-top {
    margin-top: 0px;
    margin-bottom: 36px;
  }
  .mobile-info-left {
    padding: 0 30px;
  }
  .mobile-info-right {
    margin-top: 1rem;
    padding: 0 30px;
  }
  .room.bureaux-cadre .mobile-info-right .title-cadre {
    padding-top: 15px !important;
    border-top: 1px solid #ececec;
  }
  .service-fullscreen .submit.section-button {
    width: 100%;
    padding: 24px;
  }
  .label-btn {
    width: 150px !important;
  }
  .under-bottom {
    margin-bottom: -50px;
  }
  .no-float {
    float: none !important;
  }
  .question-form-gray {
    margin-top: 10px;
  }
  .top30 {
    margin-top: 30px;
  }
  .top60 {
    margin-top: -60px;
  }
  .width100-special-btn {
    margin-left: 15px;
  }
  .width100-special-btn-2 {
    width: 90%;
  }
  .size-mobile {
    font-size: 35px;
  }
  .relative-text {
    position: relative;
    width: 140%;
  }
  .spacer-3 {
    display: none;
  }
  .width98 {
    width: 89%;
  }
  .width100-special-btn-3 {
    padding: 12px;
    margin-top: -70px;
  }
  .fullscreen {
    width: 102% !important;
    max-width: 102% !important;
    margin: 0px;
    height: 100vh !important;
    top: 70px;
    left: -1px;
  }
  .fullscreen .modal-content {
    height: auto;
  }
}
/* font */
/* color */
/* text size */
/* utils */
.side-menu {
  height: 100%;
  width: 232px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #000F46;
  overflow-x: hidden;
  text-align: center;
}
@media only screen and (max-width: 900px) {
  .side-menu {
    display: none;
  }
}
.side-menu .side-menu-link {
  width: 100%;
  height: 35px;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: #ffffff;
  margin-bottom: 4px;
  border-left: 2px solid transparent;
}
.side-menu .side-menu-link a, .side-menu .side-menu-link .disabled-nav-link {
  text-decoration: none;
  font-size: 12px;
  color: #dddddd;
  display: flex;
  text-align: left;
  height: 100%;
  padding-top: 8px;
}
.side-menu .side-menu-link a:hover {
  color: #dddddd;
  text-decoration: none;
  height: 100%;
}
.side-menu .side-menu-link .disabled-nav-link {
  opacity: 0.5;
  cursor: default;
}
.side-menu .side-menu-link .side-menu-title {
  font-size: 14px;
}
.side-menu .side-menu-link .side-menu-image {
  opacity: 0.5;
  vertical-align: top;
}
.side-menu .side-menu-link .side-menu-image.without-opacity-reduction {
  opacity: 1;
}
.side-menu .side-menu-link .with-notification {
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
}
.side-menu .side-menu-link .rectangle-active .side-menu-image {
  opacity: 1;
}
.side-menu .side-menu-link .icon-active .side-menu-image {
  opacity: 1;
}
.side-menu .side-menu-link.subscription-menu-link {
  color: #1DBE6D;
}
.side-menu .side-menu-link.subscription-menu-link a {
  color: #1DBE6D;
}
.side-menu .side-menu-link:hover {
  border-left: 2px solid white;
}
.side-menu .side-menu-logo {
  margin-left: 20px;
  margin-top: 22px;
  width: 26px;
}
.side-menu .side-menu-logo-affiliated {
  margin-bottom: 10px;
}
.side-menu .shape {
  width: 18px;
  height: 18px;
  margin-left: 18px;
  margin-right: 15px;
}
.side-menu #first {
  margin-top: 40px;
}
.side-menu .rectangle-active {
  background-color: rgba(255, 255, 255, 0.2);
}
.side-menu .profile {
  position: relative;
}
.side-menu .profile .notification-menu {
  border-radius: 1.6px;
  background-color: #2583eb;
  font-family: "Open Sans", sans-serif;
  color: #ffffff;
  font-size: 8px;
  position: absolute;
  top: 12px;
  padding: 0.6px 2px 1px 2px;
}

.navbar {
  z-index: 999;
  display: flex;
  align-items: center;
}
.navbar .navbar-brand {
  display: none;
  font-size: inherit;
}
@media screen and (max-width: 900px) {
  .navbar .navbar-brand {
    width: 70px;
    height: 70px;
    display: flex;
    margin-right: 0px;
  }
}
.navbar .navbar-toggler {
  border: none;
}

.navbar-collapse {
  background-color: white;
  position: fixed;
  width: 100%;
  min-height: 100%;
  left: 0;
  right: 0;
  top: 70px;
  z-index: 999;
}
.navbar-collapse .side-menu-image {
  margin-right: 16px;
}
.navbar-collapse .navbar-nav {
  margin-top: 0px;
  padding-bottom: 100px;
  height: calc(100vh - 94px);
  overflow: auto;
}
.navbar-collapse .navbar-nav .nav-item {
  margin-bottom: 0;
  background: #000F46;
}
@media only screen and (max-width: 900px) {
  .navbar-collapse .navbar-nav .nav-item .nav-link {
    padding: 22px;
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    color: white;
    border: none;
    text-decoration: none;
    border-left: 2px solid transparent;
  }
  .navbar-collapse .navbar-nav .nav-item .nav-link.active, .navbar-collapse .navbar-nav .nav-item .nav-link:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  .navbar-collapse .navbar-nav .nav-item .nav-link:hover {
    border-left: 2px solid white;
  }
  .navbar-collapse .navbar-nav .nav-item.subscription-menu-link {
    color: #1DBE6D;
  }
  .navbar-collapse .navbar-nav .nav-item.subscription-menu-link a {
    color: #1DBE6D;
  }
}
.navbar-collapse .navbar-nav .nav-item.support {
  background: white;
}
.navbar-collapse .navbar-nav .nav-item.support .nav-link {
  color: black;
}

.dropdownplus.float-right {
  float: right;
}

/* font */
/* color */
/* text size */
/* utils */
#root:not(:empty) + #initialLoader {
  display: none;
}

#initialLoader {
  background-color: white;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
}

.loader-container {
  width: 36px;
  font-size: 0;
}

.loader-element {
  display: inline-block;
  background-color: #1576E1;
  width: 8px;
  height: 8px;
  margin: 2px;
  border-radius: 100%;
  -webkit-animation-fill-mode: "both";
  animation-fill-mode: "both";
}

.loader-el1 {
  -webkit-animation: animation-1ik4pk7 0.648311263s -0.151688737s infinite ease;
  animation: animation-1ik4pk7 0.648311263s -0.151688737s infinite ease;
}

.loader-el2 {
  -webkit-animation: animation-1ik4pk7 1.5362121149s 0.7362121149s infinite ease;
  animation: animation-1ik4pk7 1.5362121149s 0.7362121149s infinite ease;
}

.loader-el3 {
  -webkit-animation: animation-1ik4pk7 1.5545496895s 0.7545496895s infinite ease;
  animation: animation-1ik4pk7 1.5545496895s 0.7545496895s infinite ease;
}

.loader-el4 {
  -webkit-animation: animation-1ik4pk7 1.171954386s 0.371954386s infinite ease;
  animation: animation-1ik4pk7 1.171954386s 0.371954386s infinite ease;
}

.loader-el5 {
  -webkit-animation: animation-1ik4pk7 1.5885091312s 0.7885091312s infinite ease;
  animation: animation-1ik4pk7 1.5885091312s 0.7885091312s infinite ease;
}

.loader-el6 {
  -webkit-animation: animation-1ik4pk7 1.4400903607s 0.6400903607s infinite ease;
  animation: animation-1ik4pk7 1.4400903607s 0.6400903607s infinite ease;
}

.loader-el7 {
  -webkit-animation: animation-1ik4pk7 0.9037525899s 0.1037525899s infinite ease;
  animation: animation-1ik4pk7 0.9037525899s 0.1037525899s infinite ease;
}

.loader-el8 {
  -webkit-animation: animation-1ik4pk7 1.4829037514s 0.6829037514s infinite ease;
  animation: animation-1ik4pk7 1.4829037514s 0.6829037514s infinite ease;
}

.loader-el9 {
  -webkit-animation: animation-1ik4pk7 1.1718858529s 0.3718858529s infinite ease;
  animation: animation-1ik4pk7 1.1718858529s 0.3718858529s infinite ease;
}

/* font */
/* color */
/* text size */
/* utils */
@media screen and (min-width: 901px) {
  .section-map .location-container {
    width: 250px;
    margin: auto;
  }
}
.section-map .gmaps-example-container {
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
}
.section-map p {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #282c2e;
  margin-bottom: 5px;
}
.section-map p.street {
  margin: 22px 0 0 0;
}
.section-map .mobile-phone {
  width: 12px;
  height: 15px;
  margin-right: 9px;
}
.section-map .space-bottom {
  margin-bottom: 10px !important;
}
.section-map #icon-info {
  color: #7b7e80;
}
.section-map #icon-info a {
  color: #7b7e80;
  text-decoration: none;
}
.section-map #icon-info a:hover {
  text-decoration: underline;
}

.rectangle-5 {
  text-align: center;
  border-right: solid 1px #ececec;
  margin-left: -10%;
  margin-right: 10%;
}

.bitmap {
  display: block;
  height: 100px;
  padding-top: 30px;
}

.next-facture {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #282c2e;
  text-align: left;
}
.next-facture .date {
  margin-top: 5px;
  color: #7b7e80;
}

label.next-facture {
  font-weight: bold;
  margin-bottom: 15px;
}

.label-copy {
  font-family: AvenirLTPro-Roman;
  font-size: 13px;
  color: #7b7e80;
  text-align: left;
  margin-bottom: 15px;
}

.label-copy-2 {
  height: 18px;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  text-align: right;
  color: #282c2e;
  float: right;
  text-align: left;
  padding-right: 21px;
}

.notification-content {
  margin-left: 15px;
}
.notification-content.parcel {
  margin-left: 20px;
  color: #dbb052;
  font-size: 18px;
  position: relative;
  top: 2px;
}

.notification-content-sidebar {
  display: inline-block;
  margin-left: 10px;
}
.notification-content-sidebar.parcel {
  margin-left: 10px;
  top: 0;
}

.profil-small-title {
  font-family: AvenirLTPro-Heavy;
  font-size: 14px;
  color: #282c2e;
  text-transform: uppercase;
}

.profil-info {
  padding: 35px;
}
@media only screen and (max-width: 900px) {
  .profil-info {
    position: absolute;
    width: 100%;
  }
}
.profil-info p {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #7b7e80;
  width: 68%;
  float: right;
}

@media only screen and (max-width: 900px) {
  .mobile-center {
    text-align: center;
    margin-top: 35px;
  }
}

@media only screen and (max-width: 900px) {
  .rectangle-2 {
    width: 42.33% !important;
  }
}

@media only screen and (max-width: 900px) {
  .rectangle-4 {
    width: 42.33% !important;
  }
}

@media only screen and (max-width: 900px) {
  .rectangle-5 {
    margin-bottom: 25px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    border-right: none;
  }
}

.section-dashboard {
  padding: 0 45px;
}
@media only screen and (max-width: 1024px) {
  .section-dashboard {
    padding: 0 28px;
  }
}
@media only screen and (max-width: 900px) {
  .section-dashboard {
    padding: 0 25px;
    margin-bottom: 36px;
  }
}
.section-dashboard .progress-and-steps-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 44px;
}
@media screen and (max-width: 1024px) {
  .section-dashboard .progress-and-steps-container {
    justify-content: center;
  }
}
.section-dashboard .progress-and-steps-container .progress-circle-container {
  text-align: left;
}
.section-dashboard .progress-and-steps-container .progress-circle-container .progress-circle {
  margin: 1rem 1rem 1rem 0;
}
.section-dashboard .progress-and-steps-container .progress-circle-container .progress-circle span {
  color: #282c2e;
  background-color: white;
}
.section-dashboard .progress-and-steps-container .customer-complete-container {
  font-size: 13px;
  color: #7b7e80;
  padding-left: 20px;
}
@media screen and (max-width: 1024px) {
  .section-dashboard .progress-and-steps-container .customer-complete-container {
    padding-left: 0px;
    text-align: center;
  }
}
.section-dashboard .progress-and-steps-container .customer-complete-container .steps-count {
  padding-bottom: 10px;
}
.section-dashboard .progress-and-steps-container .customer-complete-container .steps-count strong {
  color: #1576E1;
  font-size: 20px;
}
.section-dashboard .progress-and-steps-container .customer-complete-container a {
  color: #7b7e80;
  font-size: 13px;
  text-decoration: underline;
}
@media screen and (min-width: 1200px) {
  .section-dashboard .first-row {
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 1199px) {
  .section-dashboard .first-row {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media screen and (max-width: 1199px) {
  .section-dashboard .second-row {
    margin-top: 0px;
    padding-top: 0px;
  }
}
.section-dashboard .usermails-container a {
  padding-bottom: 50px;
  display: block;
}
@media screen and (max-width: 900px) {
  .section-dashboard .usermails-container a {
    display: none;
  }
}
.section-dashboard .no-user-mails, .section-dashboard .no-invoice {
  text-align: center;
}
.section-dashboard .no-user-mails .msg, .section-dashboard .no-invoice .msg {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #282c2e;
  text-align: center;
}
.section-dashboard .no-user-mails img, .section-dashboard .no-invoice img {
  width: 100%;
  max-width: 80px;
  margin-bottom: 15px;
}
.section-dashboard .cadre-services .row.nopadding {
  padding: 0;
}
.section-dashboard .cadre-services .grid-row {
  display: flex;
}
.section-dashboard .cadre-services .grid-row .service-element {
  padding: 0px 15px 15px 0px;
}
@media screen and (max-width: 900px) {
  .section-dashboard .cadre-services .grid-row .service-element {
    padding: 0px 0px 15px 0px;
  }
}
.section-dashboard .cadre-services .grid-row .rectangles-services {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 4px;
  text-align: center;
  margin: 0 10px 10px 0px;
  border: solid 1px #ececec;
  cursor: pointer;
  flex-shrink: 2;
  flex-grow: 2;
}
.section-dashboard .cadre-services .grid-row .rectangles-services.service-soon {
  cursor: not-allowed;
  opacity: 0.6;
}
.section-dashboard .cadre-services .grid-row .rectangles-services:hover:not(.service-soon) {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  text-decoration: none;
  border: none;
}
@media only screen and (max-width: 900px) {
  .section-dashboard .cadre-services .grid-row .rectangles-services {
    margin: 9px auto;
  }
}
.section-dashboard .cadre-services .grid-row .rectangles-services .rectangle-title {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #282c2e;
  text-decoration: none !important;
  display: inline-block;
  margin-top: 10px;
  text-align: center;
  max-width: 108px;
}
.section-dashboard .subscription-current-container {
  text-align: center;
}
.section-dashboard .subscription-current-container h2 {
  text-align: left;
}
@media screen and (max-width: 900px) {
  .section-dashboard .subscription-current-container .subscription-inner {
    padding: 24px 24px 8px 24px;
    border: 1px solid #ececec;
  }
}
@media screen and (min-width: 901px) {
  .section-dashboard .subscription-current-container .subscription-inner {
    max-width: 150px;
  }
}
.section-dashboard .subscription-current-container .subscription-inner .subscription-current {
  font-family: AvenirLTPro-Heavy;
  text-transform: uppercase;
  font-size: 10px;
  color: #1576e1;
  border-radius: 4px;
  background-color: #e4f6ff;
  padding: 6px 0px 4px 0px;
  display: block;
  margin: 6px auto 20px auto;
  width: 142px;
}
.section-dashboard .subscription-current-container .subscription-inner .section-title-2 {
  padding-bottom: 10px;
  text-transform: uppercase;
  font-family: AvenirLTPro-Heavy;
  font-size: 14px;
}
.section-dashboard .last-invoices-container .last-invoices-outer {
  border-left: 1px solid #ececec;
  margin-left: -30px;
  padding-left: 30px;
}
.section-dashboard .last-invoices-container .last-invoices-outer .facture {
  width: 100%;
}
.section-dashboard .last-invoices-container .last-invoices-outer .facture td {
  border-bottom: none !important;
  text-align: left;
  vertical-align: top;
}
.section-dashboard .last-invoices-container .last-invoices-outer .facture td.price {
  min-width: 5.5rem;
}
.section-dashboard .last-invoices-container .last-invoices-outer .facture td:first-child {
  padding-bottom: 15px;
}
.section-dashboard .last-invoices-container .last-invoices-outer .facture td .download-img-icon {
  margin-top: -6px;
}
.section-dashboard .last-invoices-container .last-invoices-outer .facture .btn.download {
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 1rem;
}
.section-dashboard .last-invoices-container .last-invoices-outer .facture .btn.download img {
  margin-bottom: 0.4rem;
}
.section-dashboard .last-invoices-container .last-invoices-outer .go-to-all-invoices {
  display: block;
  text-align: center;
}
.section-dashboard .location-container ul {
  width: 150px;
  margin-top: 10px;
  padding-inline-start: 0px;
}
.section-dashboard .location-container ul li {
  font-size: 12px;
  margin: 0;
  color: #7b7e80;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.section-dashboard .location-container #openingHoursToggler {
  font-size: 12px;
  color: #7b7e80;
  text-decoration: underline;
}

@media only screen and (max-width: 900px) {
  .bitmap-2 {
    width: 115px;
  }
}

@media (min-width: 1600px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
}
.row.second-row > div {
  margin-bottom: 30px;
}

.subscription-inner {
  margin: auto;
}
.subscription-inner > h2 {
  text-align: center;
}

@media screen and (max-width: 991px) {
  .section-map .location-container, .subscription-inner {
    margin: unset !important;
  }
  .subscription-inner > h2 {
    text-align: left;
  }
}
a:not([href]):not([tabindex]) {
  color: unset;
}

.progress-circle-container {
  text-align: center;
}

.progress-circle {
  position: relative;
  display: inline-block;
  margin: 1rem;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  background-color: rgb(188, 228, 222);
}
.progress-circle:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: colorload 2s;
  animation: colorload 2s;
}
.progress-circle sup {
  top: -2px;
  left: 6px;
}
.progress-circle span {
  font-size: 1.5rem;
  color: #2dcfb3;
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  width: 89px;
  height: 89px;
  line-height: 89px;
  margin-left: -44px;
  margin-top: -45px;
  text-align: center;
  border-radius: 50%;
  background: #f5f5f5;
  z-index: 1;
}
.progress-circle span:after {
  content: "";
  font-weight: 600;
  color: #2dcfb3;
}
.progress-circle.progress-0:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(90deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-1:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(93.6deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-2:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(97.2deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-3:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(100.8deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-4:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(104.4deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-5:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(108deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-6:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(111.6deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-7:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(115.2deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-8:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(118.8deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-9:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(122.4deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-10:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(126deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-11:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(129.6deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-12:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(133.2deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-13:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(136.8deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-14:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(140.4deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-15:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(129.6deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-16:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(133.2deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-17:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(136.8deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-18:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(140.4deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-19:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(144deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-20:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(151.2deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-21:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(158.6deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-22:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(161.2deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-23:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(165.6deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-24:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(169.2deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-25:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(180deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-26:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(183.6deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-27:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(187.2deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-28:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(190.8deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-29:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(194.4deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-30:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(198deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-31:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(201.6deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-32:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(205.2deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-33:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(208.8deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-34:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(212.4deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-35:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(216deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-40:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(234deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-36:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(219.6deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-37:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(223.2deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-38:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(226.8deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-39:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(230.4deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-41:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(237.6deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-42:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(241.2deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-43:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(244.8deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-44:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(248.4deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-45:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(252deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-46:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(255.6deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-47:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(259.2deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-48:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(262.8deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-49:after {
  background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(266.4deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-50:after {
  background-image: linear-gradient(-90deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-51:after {
  background-image: linear-gradient(-86.4deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-52:after {
  background-image: linear-gradient(-82.6deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-53:after {
  background-image: linear-gradient(-79.2deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-54:after {
  background-image: linear-gradient(-75.6deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-55:after {
  background-image: linear-gradient(-72deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-56:after {
  background-image: linear-gradient(-68.4deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-57:after {
  background-image: linear-gradient(-64.8deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-58:after {
  background-image: linear-gradient(-61.2deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-59:after {
  background-image: linear-gradient(-57.6deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-60:after {
  background-image: linear-gradient(-54deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-61:after {
  background-image: linear-gradient(-50.4deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-62:after {
  background-image: linear-gradient(-46.8deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-63:after {
  background-image: linear-gradient(-43.2deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-64:after {
  background-image: linear-gradient(-39.6deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-65:after {
  background-image: linear-gradient(-36deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-66:after {
  background-image: linear-gradient(-32.4deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-67:after {
  background-image: linear-gradient(-28.8deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-68:after {
  background-image: linear-gradient(-25.2deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-69:after {
  background-image: linear-gradient(-21.6deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-70:after {
  background-image: linear-gradient(-18deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-71:after {
  background-image: linear-gradient(-14.4deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-72:after {
  background-image: linear-gradient(-10.8deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-73:after {
  background-image: linear-gradient(-7.2deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-74:after {
  background-image: linear-gradient(-3.6deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-75:after {
  background-image: linear-gradient(0deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-76:after {
  background-image: linear-gradient(3.6deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-77:after {
  background-image: linear-gradient(7.2deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-78:after {
  background-image: linear-gradient(10.8deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-79:after {
  background-image: linear-gradient(14.4deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-80:after {
  background-image: linear-gradient(18deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-81:after {
  background-image: linear-gradient(21.6deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-82:after {
  background-image: linear-gradient(25.2deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-83:after {
  background-image: linear-gradient(28.8deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-84:after {
  background-image: linear-gradient(32.4deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-85:after {
  background-image: linear-gradient(36deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-86:after {
  background-image: linear-gradient(39.2deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-87:after {
  background-image: linear-gradient(43.2deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-88:after {
  background-image: linear-gradient(46.8deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-89:after {
  background-image: linear-gradient(50.4deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-90:after {
  background-image: linear-gradient(54deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-91:after {
  background-image: linear-gradient(57.6deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-92:after {
  background-image: linear-gradient(61.2deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-93:after {
  background-image: linear-gradient(64.8deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-94:after {
  background-image: linear-gradient(68.4deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-95:after {
  background-image: linear-gradient(72deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-96:after {
  background-image: linear-gradient(75.6deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-97:after {
  background-image: linear-gradient(79.2deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-98:after {
  background-image: linear-gradient(82.8deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-99:after {
  background-image: linear-gradient(86.4deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}
.progress-circle.progress-100:after {
  background-image: linear-gradient(90deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef);
}

@-webkit-keyframes colorload {
  0% {
    opacity: 0;
    transform: rotate(0);
  }
  100% {
    opacity: 1;
    transform: rotate(360deg);
  }
}
/* font */
/* color */
/* text size */
/* utils */
.subscription-current-white {
  width: 139px;
  height: 18px;
  border-radius: 4px;
  border: solid 1px rgba(40, 44, 46, 0.2);
  background-color: #ffffff;
  font-family: AvenirLTPro-Heavy;
  font-size: 8px;
  color: #7b7e80;
  text-align: center;
  padding: 3px;
  margin: auto;
}

.special-top {
  margin-top: 0px !important;
}

.special-top-2 {
  margin-top: 15px !important;
}

.delete-side-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.no-border {
  border-right: 0px !important;
}

.with-border {
  border: solid 1px rgba(40, 44, 46, 0.2);
  border-radius: 4px;
  width: 100%;
  margin-top: -9px;
  padding-top: 40px;
}

.padding15 {
  margin-left: 25px !important;
}

.padding-full {
  padding: 30px;
}

.inline-white {
  display: inline-block;
  background: white !important;
  width: 104%;
  margin-left: -10px;
  margin-right: -9px;
}

.abonnement-ul {
  padding-left: 15px !important;
  color: #282c2e;
}
.abonnement-ul li {
  color: #282c2e;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  text-align: left;
  padding: 10px;
}

.icon-li {
  width: 10.8px;
  height: 11.2px;
  margin-right: 10px;
}

.gray-color {
  color: #7b7e80;
}

.sub-li {
  margin-left: 23px;
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  color: #1576e1;
}

.inside-line-abn {
  margin-top: 30px;
  border-bottom: 1px solid #ececec;
  margin-bottom: 30px;
}

.full-width-30 {
  width: 32%;
}

.section-p {
  color: #282c2e;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
}

.side-title {
  margin-top: 156px;
  font-family: AvenirLTPro-Roman;
  font-size: 24px;
  color: #282c2e;
}

.side-title-2 {
  margin-top: 69px;
  font-family: AvenirLTPro-Roman;
  font-size: 24px;
  color: #282c2e;
}

.cadre-side {
  width: 435px;
  height: 351px;
  border-radius: 2px;
  padding: 30px;
  color: white;
  background-repeat: no-repeat;
  background-position: 100% 30px;
}

.green-side {
  background-color: #2dcfb3;
  background-image: url("images/house-icon-green.svg");
}

.red-side {
  background-color: #1576E1;
  background-image: url("images/building-icon.svg");
}

.violet-side {
  background-color: #311e40;
  background-image: url("images/violet-building.svg");
}

.price-side {
  font-family: AvenirLTPro-Roman;
  font-size: 42px;
}
.price-side span {
  font-size: 21px;
}

.white {
  color: #ffffff !important;
}

.aside-ul {
  padding-left: 0px !important;
}
.aside-ul li {
  padding: 3px;
  color: #ffffff !important;
}

.success-btn {
  color: #2dcfb3;
  box-shadow: 0 10px 19px -8px rgba(0, 0, 0, 0.32);
}

.alert-btn {
  color: #1576E1;
  box-shadow: 0 10px 19px -8px rgba(0, 0, 0, 0.32);
}

.violet-btn {
  color: #311e40;
  box-shadow: 0 10px 19px -8px rgba(0, 0, 0, 0.32);
}

.house-icon-large {
  position: absolute;
  right: -21px;
}

.large-pic-1 {
  width: 251px;
  height: 190px;
}

.large-pic-2 {
  width: 251px;
  height: 240px;
}

.large-pic-3 {
  width: 251px;
  height: 257px;
}

.pic-abonnement img {
  margin: 5px;
}

.abonnement2 {
  border-left: 1px solid #ececec;
  padding-top: 5px;
}

.margin-auto {
  margin: auto;
  height: auto !important;
}

.full-width-abn {
  width: 31%;
  margin-left: 15px;
}

@media only screen and (max-width: 900px) {
  .cadre-color {
    width: 100%;
    height: auto;
  }
  .cadre-color-2 {
    width: 90%;
    height: auto;
    margin-bottom: 15px;
  }
  .pic-abonnement {
    display: none;
  }
  .side-title {
    margin-top: -50px;
    margin-bottom: 30px;
  }
  .side-title-2 {
    margin-top: 0px;
  }
  .with-border {
    margin-left: 0px;
  }
  .padding-full {
    padding: 20px;
    margin-top: -40px;
  }
  .full-width-30 {
    width: 100%;
    margin-left: -11px;
  }
  .label-radio-sub {
    float: right;
    margin-top: -22px;
  }
  .btn-mobile-large {
    margin-left: 15px !important;
    width: 90%;
    margin-top: 18px;
  }
  .section-p {
    color: #7b7e80;
  }
  .cadre-abn-2 {
    margin-top: 30px;
    border-top: 1px solid #ececec;
    padding-top: 30px;
  }
}
/* font */
/* color */
/* text size */
/* utils */
@media only screen and (max-width: 900px) {
  .page-subscription .service {
    display: block;
  }
  .page-subscription .service .left-box {
    width: 100%;
    float: none;
  }
  .page-subscription .service .right-box {
    width: 100%;
    float: none;
    margin-top: 10px;
  }
}
/* font */
/* color */
/* text size */
/* utils */
.regularize_modal {
  position: fixed;
  z-index: 1015;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #F5F7FB;
  overflow: auto;
  padding: 24px;
}
.regularize_modal .error-input {
  color: #CD100C;
}
.regularize_modal .content {
  width: 95%;
  margin: auto;
  max-width: 1000px;
}
.regularize_modal .progress {
  height: 8px;
}
.regularize_modal .progress .progress-bar {
  border-radius: 4px;
}
.regularize_modal .boxes {
  margin: 40px auto;
}
.regularize_modal .boxes .box {
  background: white;
  border-radius: 6px;
  padding: 24px;
}
.regularize_modal .boxes .box-left {
  width: calc(60% - 12px);
  float: left;
  min-height: 192px;
}
@media screen and (max-width: 900px) {
  .regularize_modal .boxes .box-left {
    width: 100%;
    float: unset;
  }
}
.regularize_modal .boxes .box-right {
  float: right;
  width: calc(40% - 12px);
}
@media screen and (max-width: 900px) {
  .regularize_modal .boxes .box-right {
    width: 100%;
    float: unset;
    margin-top: 40px;
  }
}
.regularize_modal .boxes .box-right .continue-button {
  margin-top: 48px;
  text-align: center;
  padding: 10px;
  background: #1576E1;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  text-decoration: none;
  display: block;
}
.regularize_modal .boxes::after {
  content: "";
  display: block;
  clear: both;
}
.regularize_modal .stripe-element {
  padding: 12px 12px 10px 12px;
  border: 1px solid #D3DBE1;
  background: white;
  border-radius: 8px;
  width: 100%;
  font-size: 16px;
}
.regularize_modal .stripe-element-small {
  padding: 12px 12px 10px 12px;
  border: 1px solid #D3DBE1;
  background: white;
  border-radius: 8px;
  width: 120px;
  font-size: 16px;
}
.regularize_modal input[type=checkbox] {
  display: inline;
  margin-right: 16px;
}

/* font */
/* color */
/* text size */
/* utils */
/* font */
/* color */
/* text size */
/* utils */
@keyframes shrinkUserMailsSection {
  0% {
    width: calc(100% - 45px);
    padding-left: 45px;
  }
  100% {
    width: calc(100% - 406px);
    @media only screen and (max-width: 1200px) {
      padding-left: 10px;
    }
  }
}
.opened {
  width: calc(100% - 406px);
  padding-left: 45px;
}
@media only screen and (min-width: 901px) {
  .opened {
    animation: 0.4s ease-out 0s 1 shrinkUserMailsSection;
  }
}
@media only screen and (max-width: 1200px) {
  .opened {
    padding-left: 10px;
  }
}

@media only screen and (min-width: 901px) {
  .user-mails-section.closed {
    width: calc(100% - 45px);
    padding: 0 0 0 45px;
  }
}
@keyframes slideInFromRight {
  0% {
    right: -444px;
  }
  100% {
    right: 0px;
  }
}
.nav__menu,
.nav__menu_2 {
  position: fixed;
  top: 0;
  width: 406px;
  height: 100%;
  background: #fafafa;
  transition: all 0.5s ease;
  z-index: 1002;
  padding-top: 149px;
  padding-left: 48px;
  overflow-x: hidden;
  display: block;
  overflow-y: scroll;
  right: 0;
  animation: 0.4s ease-out 0s 1 slideInFromRight;
}

.menu__helper {
  z-index: 1001;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: none;
}

.gh11-sidebar {
  width: 110%;
}

.gh12-sidebar {
  font-size: 18px;
  margin: 44px 0px 20px 0px;
}

.menuIcon {
  margin-top: -5px !important;
  margin-left: -10px !important;
  margin-right: 10px;
}

.plus {
  width: 29px;
  height: 20px;
  border-radius: 4px;
  border: solid 1px #bcbdbd;
  float: right;
  top: 0.7rem;
  cursor: pointer;
  position: relative;
}
.plus .button {
  height: 20px;
}
.plus .button svg {
  font-size: 18px;
  position: absolute;
  top: 0px;
  left: 4px;
  color: #282c2e;
}

.padding4 {
  padding-top: 4px;
}

.dte {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #7b7e80;
  vertical-align: middle;
  float: left;
}

.categorie {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: normal;
  color: #1576e1;
  border-radius: 12px;
  background-color: #e4f6ff;
  margin-left: auto;
  margin-right: auto;
  float: left;
  vertical-align: middle;
  line-height: 23px;
  padding-left: 10px;
  cursor: pointer;
}
.categorie .arrow-button {
  margin-top: 7px;
}

.oval {
  float: left;
  margin-top: 9px;
  margin-left: 12px;
  margin-right: 12px;
  width: 3px;
}

.arrow-button {
  width: 6px;
  float: right;
  margin-right: 8px;
  margin-top: 7px;
}

.arrow-button-large {
  width: 12px;
  float: right;
  margin-right: 15px;
  margin-top: 0px;
}

.cadre-1 {
  width: 100%;
  height: 187px;
  display: contents;
  margin: 10px 0;
}

.overlay {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.etape-courrier {
  position: relative;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #7b7e80;
}
.etape-courrier .follow-user-mail {
  color: #7b7e80;
  text-decoration: underline;
  display: block;
  padding-left: 28px;
}
.etape-courrier .forwarding-date-expected,
.etape-courrier .sender-name {
  padding-left: 30px;
}
.etape-courrier .sender-name {
  position: absolute;
  font-weight: 700;
}
.etape-courrier .sender-name::first-letter {
  text-transform: capitalize;
}
.etape-courrier .forwarding-date {
  display: inline-block;
  float: right;
}
.etape-courrier .destruction-date-expected {
  display: block;
  float: none;
  margin-left: -27px;
  background-color: #fff8fa;
  margin-right: -32px;
  padding: 27px;
  margin-top: 18px;
  color: #eb4968;
}
.etape-courrier .access-and-hours-container {
  padding-left: 28px;
}
.etape-courrier .sidebar-clickable-link {
  display: inline-block;
  padding-left: 0px;
  color: #7b7e80;
  text-decoration: underline;
}

.icon-spacer {
  padding: 6px;
}

.etape-icon {
  width: 18px;
  height: 18px;
  border-radius: 3.2px;
  background-color: #311e41;
  color: white;
  padding-top: 4px;
  text-align: center;
  vertical-align: middle;
  font-family: AvenirLTPro-Roman;
  font-size: 11px;
  color: #ffffff;
  margin-right: 12px;
  margin-bottom: 0px !important;
}

.select {
  position: relative;
  display: block;
  overflow: hidden;
  font-family: AvenirLTPro-Roman;
  font-size: 10px;
  color: #1576e1 !important;
  border-radius: 12px;
  background-color: #e4f6ff !important;
  margin-left: auto;
  margin-right: auto;
  float: left;
  width: 84px;
  height: 20px;
  vertical-align: middle;
  line-height: 20px;
  padding-left: 10px;
}

.select::after {
  content: "▼";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0 1em;
  background: #e4f6ff !important;
  pointer-events: none;
}
.select:hover::after {
  color: #1576e1 !important;
}
.select::after {
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}

.user-mails-sidebar .dropdown .plus.button {
  color: #282c2e;
}

#submenu-item {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #282c2e;
  height: 32px;
  line-height: 32px;
  vertical-align: middle;
  text-decoration: none;
  background-color: red;
}

.dropdown-item-plus {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 35px;
  height: 42px !important;
  cursor: pointer;
}

.dropdown-item-plus:hover {
  background-color: #f5f5f5 !important;
}

.tools-hovers {
  display: none;
}
.tools-hovers img {
  cursor: pointer;
  float: right;
  margin: 8px 5px 0px 5px;
}
.tools-hovers .loader-destroy {
  float: right;
  margin: 8px 5px 0px 5px;
  position: relative;
}

.text-left {
  float: left;
  line-height: 35px;
}

.sub-hover {
  height: 35px;
}

.sub-hover:active {
  background-color: #f5f5f5 !important;
}

.sub-hover:hover > .tools-hovers {
  display: block !important;
}

.sub-hover:hover ~ .tools-hovers {
  display: block;
}

.sub-first {
  height: 41px !important;
}

.sub-first:hover {
  background-color: #ffffff !important;
}

.input-plus {
  height: 31px;
  border-radius: 4px;
  border: solid 1px #f0efef;
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
}

#input-plus-group button[type=submit] {
  cursor: pointer;
}

.icon-button-input {
  position: absolute;
  right: 10px;
  top: 9px;
}

.dropdown-cadre {
  width: 189px !important;
  height: 189px !important;
}

.header_sidebar {
  display: none !important;
}

.user-mails-sidebar .sidebar-row img.default {
  display: block;
}
@media screen and (min-width: 901px) {
  .user-mails-sidebar .return-back {
    display: none;
  }
}
.user-mails-sidebar .btn {
  margin-top: 15px;
}
.user-mails-sidebar .right {
  margin-right: 0;
  line-height: 23px;
}
.user-mails-sidebar .dte {
  color: #282c2e;
}
.user-mails-sidebar .oval {
  margin-top: 6px;
}
.user-mails-sidebar .dropdown-tag {
  width: auto;
}
.user-mails-sidebar .dropdown-tag .tag-name {
  margin-right: 25px;
  font-size: 12px;
}
.user-mails-sidebar .dropdown-tag .dropdown-item {
  min-width: 200px;
  padding-left: 1.6rem;
  padding-right: 1rem;
}
.user-mails-sidebar .dropdown-tag .dropdown-item:nth-child(2) {
  margin-top: 16px;
}
.user-mails-sidebar .dropdown-tag .dropdown-item:first-child {
  padding-left: 1rem;
}
.user-mails-sidebar .dropdown-tag .dropdown-item .form-edit {
  width: 100%;
}
.user-mails-sidebar .dropdown-tag .dropdown-item .form-edit button.edit svg.fa-check {
  margin-right: 0px;
}
.user-mails-sidebar .dropdown-tag form {
  width: 100%;
  position: relative;
}
.user-mails-sidebar .dropdown-tag form .loader {
  position: absolute;
  right: 4px;
  top: 9px;
}
.user-mails-sidebar .dropdown-tag form button {
  background: transparent;
  border: none;
  position: absolute;
  right: 0;
  line-height: 40px;
  cursor: pointer;
  padding-top: 0px;
}
.user-mails-sidebar .dropdown-tag .msg {
  font-size: 13px;
  color: #282c2e;
}

@media only screen and (max-width: 900px) {
  .header_sidebar {
    display: block !important;
    position: absolute;
    background: white;
    width: 112%;
    margin-top: -100px;
    margin-left: -13%;
    padding-top: 100px;
    padding-left: 30px;
  }
  .group-botton {
    margin-top: 130px;
  }
}
#re-expedition .lettre {
  margin: 8px 15px;
  height: 44px;
  display: block;
}
@media only screen and (max-width: 900px) {
  #re-expedition .lettre {
    height: 125px;
  }
}
#re-expedition .error {
  clear: both;
  background-color: rgba(255, 195, 111, 0.15);
  color: #d2800d;
  font-size: 13px;
  padding: 1rem;
}
#re-expedition .remove-user-mail-button {
  cursor: pointer;
}

@media only screen and (min-width: 901px) {
  .hidden-desktop {
    display: none;
  }
}
.user-mails-section {
  display: flex;
  flex-flow: column nowrap;
}
@media only screen and (max-width: 900px) {
  .user-mails-section {
    padding: 0 20px;
  }
  .user-mails-section h1 {
    margin-bottom: 20px;
  }
}
.user-mails-section .top-row {
  height: 40px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  flex-flow: row nowrap;
}
@media (max-width: 1199px) {
  .user-mails-section .top-row {
    padding-top: 20px;
    flex-flow: column nowrap;
  }
}
.user-mails-section .top-row .credits-container {
  margin-right: 20px;
}
@media (max-width: 1199px) {
  .user-mails-section .top-row .credits-container {
    margin-right: 0;
  }
}

.nav__menu,
.nav__menu_2 {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
}

th.date_of_receipt_th {
  min-width: 148px;
}

.courriers-table tr {
  height: 60px;
}
.courriers-table tr:hover:not(.selected) {
  box-shadow: 7px 7px 25px 0 rgba(0, 0, 0, 0.1);
}
.courriers-table tr:hover {
  background: #ffffff !important;
}
.courriers-table tr.before-selected {
  box-shadow: 0 -20px 20px -20px rgba(0, 0, 0, 0.1) inset;
}
.courriers-table tr.last-selected {
  box-shadow: 7px 7px 25px 0 rgba(0, 0, 0, 0.1);
}
.courriers-table th {
  width: auto;
  height: 14px;
  font-family: AvenirLTPro-Roman;
  font-size: 13px;
  font-weight: normal;
  color: #282c2e;
}
.courriers-table th:hover {
  box-shadow: none;
}
.courriers-table td {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #7b7e80;
  vertical-align: middle;
}
.courriers-table td.first-col {
  color: #282c2e;
  font-size: 14px;
}
.courriers-table td:last-child {
  text-align: right;
}
.courriers-table td.download {
  text-decoration: underline;
}
.courriers-table td .tag-name {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
}
.courriers-table .w10 {
  max-width: 20px;
  text-align: center;
}
.courriers-table .tag-name.to-define {
  font-style: italic;
}
.courriers-table.loading th, .courriers-table.loading td {
  position: relative;
  background-color: #ffffff;
  color: #ffffff !important;
}
.courriers-table.loading th *, .courriers-table.loading td * {
  visibility: hidden;
}
.courriers-table.loading th:before, .courriers-table.loading td:before {
  content: "";
  position: absolute;
  color: #f0f0f0;
  background-color: #f0f0f0;
  border-radius: 7px;
  top: 23px;
  left: 10%;
  right: 10%;
  bottom: 23px;
  z-index: 1;
}
.courriers-table.loading tbody tr:nth-child(odd) th:before, .courriers-table.loading tbody tr:nth-child(odd) td:before {
  right: 50%;
}

.head-table:hover {
  box-shadow: none !important;
  background: #ffffff !important;
}

.no-user-mails, .no-invoice {
  text-align: center;
}
.no-user-mails .msg, .no-invoice .msg {
  text-align: center;
  font-size: 16px;
  color: #282c2e;
}
.no-user-mails img, .no-invoice img {
  width: 100%;
  max-width: 180px;
  margin-bottom: 30px;
  margin-top: 90px;
}

.gh11 {
  color: #282c2e !important;
}

.new {
  background-color: #fff8fa;
}

.line {
  width: 7px;
  height: 3px;
  margin-bottom: 2px;
  margin-right: 5px;
  border: solid 1px;
}

.line_warning {
  border: solid 1px #ffc36f;
  background: #ffc36f;
}

.line_default {
  border: solid 1px #1576e1;
  background: #1576e1;
}

.line_alert {
  border: solid 1px #1576E1;
  background: #1576E1;
}

.line_blue {
  border: solid 1px #2a5063;
  background: #2a5063;
}

.line_success {
  border: solid 1px #2dcfb3;
  background: #2dcfb3;
}

.oval-red {
  width: 4px;
  height: 4px;
  background-color: #1576E1;
  border-radius: 16px;
  position: relative;
  top: 5px;
  left: 10px;
}

.oval-eye {
  width: 13px;
}

.icon-arrow-left {
  width: 15px;
  height: 12px;
  margin-right: 4px;
  margin-top: -2px;
}

.return-back {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #1576E1;
}

.mails-filters-container {
  display: flex;
  flex-flow: row nowrap;
}
@media (max-width: 1199px) {
  .mails-filters-container {
    padding-top: 20px;
  }
}
@media (max-width: 900px) {
  .mails-filters-container {
    flex-flow: column nowrap;
  }
}

.user-mails-filters {
  display: flex;
  gap: 20px;
}
@media (max-width: 1199px) {
  .user-mails-filters {
    margin-left: auto;
  }
}
@media (max-width: 900px) {
  .user-mails-filters {
    margin-left: 0;
    gap: 0px;
    flex-flow: column nowrap;
  }
}
.user-mails-filters .date-range-picker {
  box-shadow: 0 3px 6px -1px 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 15px;
  top: 45px;
  z-index: 1000;
}
.user-mails-filters .filters-list {
  order: 1;
  display: flex;
  flex-flow: row nowrap;
  gap: 20px;
}
@media (max-width: 900px) {
  .user-mails-filters .filters-list {
    gap: 0px;
    order: 2;
    flex-flow: column nowrap;
  }
}
.user-mails-filters .filters-list .filter {
  background-color: #1576E1;
  border-radius: 4px;
  padding: 10px;
  margin-right: 1rem;
  color: #ffffff;
  font-size: 13px;
  display: inline-block;
}
@media (max-width: 900px) {
  .user-mails-filters .filters-list .filter {
    margin-right: 0;
  }
}
.user-mails-filters .filters-list .filter .remove-filter {
  display: inline;
  margin-left: 1rem;
  cursor: pointer;
}
.user-mails-filters .dropdown {
  order: 2;
}
@media (max-width: 900px) {
  .user-mails-filters .dropdown {
    order: 1;
  }
}
.user-mails-filters .dropdown.show .add-filter {
  background-color: #1576E1;
  color: #ffffff;
}
.user-mails-filters .dropdown .filtre-btn {
  top: auto !important;
  left: auto !important;
  min-width: 200px;
}
@media screen and (max-width: 900px) {
  .user-mails-filters .dropdown .filtre-btn {
    top: 0px !important;
  }
}
.user-mails-filters .dropdown .filtre-btn a {
  padding: 15px 20px;
  text-decoration: none;
  font-size: 13px;
}
.user-mails-filters .dropdown .filtre-btn a.subitem {
  padding-left: 40px;
}
.user-mails-filters .dropdown .dropdown-item:hover {
  background: #f5f5f5;
}

#dropdown-filtre:focus {
  background: #1576E1 !important;
  color: #ffffff !important;
}

.mobile-hidden {
  display: none;
}

.mobile-hidden {
  display: none;
}

.checkbox {
  margin-top: 10px;
}

.carousel-user-mail-query {
  margin-top: 10px;
}
.carousel-user-mail-query .carousel-inner {
  width: 70%;
  margin-left: 15%;
  min-height: 330px;
  background-color: whitesmoke;
  border-radius: 4px;
}
@media only screen and (max-width: 900px) {
  .carousel-user-mail-query .carousel-inner {
    min-height: 200px;
  }
}
.carousel-user-mail-query .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='ff3465' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}
.carousel-user-mail-query .carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='ff3465' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}
.carousel-user-mail-query .carousel-item {
  padding: 20px;
}
.carousel-user-mail-query .carousel-item .tag-name {
  float: right;
}

.carousel .carousel-item.active ~ .carousel-item.active {
  display: none;
}

@media only screen and (max-width: 900px) {
  .btn.btn-custom-right {
    width: 100%;
  }
  .nav__menu,
  .nav__menu_2 {
    padding-top: 84px;
    padding-bottom: 60px;
    width: 100%;
    z-index: 1;
  }
  .user-mails-filters .add-filter {
    float: none;
    text-align: center;
  }
  .user-mails-filters .filters-list {
    float: none;
  }
  .user-mails-filters .filters-list .filter {
    margin-top: 10px;
  }
  .user-mails-filters .date-range-picker {
    right: 0;
  }
  .carousel-user-mail-query .carousel-control-prev, .carousel-user-mail-query .carousel-control-next {
    width: 6%;
  }
  .carousel-user-mail-query .carousel-inner {
    width: 86%;
    margin-left: 7%;
  }
  .carousel-user-mail-query .carousel-item .text-default.right-popup {
    float: left;
    clear: both;
  }
}
@media only screen and (min-width: 901px) and (max-width: 1199px) {
  .user-mails-section .top-row {
    height: auto;
  }
}
@media only screen and (min-width: 334px) and (max-width: 900px) {
  .user-mails-filters .DayPicker {
    margin-right: 15px;
  }
  .user-mails-section .top-row {
    height: auto;
    flew-flow: column nowrap;
    align-items: stretch;
  }
}
#no-user-mail-modal .title-popup .count {
  color: #1576E1;
  margin-left: 0.5rem;
}
#no-user-mail-modal .text {
  margin: 30px 0;
}
#no-user-mail-modal .text p {
  margin-bottom: 0.5rem;
}
#no-user-mail-modal .img-container {
  width: 50%;
}
@media only screen and (max-width: 900px) {
  #no-user-mail-modal .img-container {
    width: 100%;
  }
}
#no-user-mail-modal .bitmap {
  height: 200px;
  margin: auto;
}
#no-user-mail-modal .default-link {
  margin-top: 1rem;
  font-size: 14px;
}
#no-user-mail-modal .default-link svg {
  margin-right: 0.5rem;
}

/* font */
/* color */
/* text size */
/* utils */
.nowidth {
  width: 100% !important;
}

.second-title {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  text-align: center;
  color: #7b7e80;
}

.cadre-title {
  font-family: AvenirLTPro-Roman;
  font-size: 18px;
  color: #282c2e;
}

.cadre-content {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 1.54;
  color: #7b7e80;
}

.paddin-faq {
  padding-bottom: 35px;
  padding-top: 35px;
}

@media only screen and (max-width: 900px) {
  .mobile-title-faq {
    font-size: 35px;
    margin-bottom: -5px;
  }
  .paddin-faq {
    padding-bottom: 0px;
    padding-top: 0px;
  }
}
/* font */
/* color */
/* text size */
/* utils */
.profile-container {
  padding-bottom: 134px;
}
.profile-container .profile-hint {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #7b7e80;
  padding: 0 35px;
  text-align: justify;
  height: auto;
}
@media screen and (max-width: 900px) {
  .profile-container .profile-hint {
    height: auto;
  }
}
.profile-container .cadre-profil {
  padding: 40px;
}
@media screen and (max-width: 1199px) {
  .profile-container .cadre-profil {
    padding: 20px;
  }
}
.profile-container .cadre-profil .profile-element {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  padding: 24px 0px;
  border-bottom: 1px solid #dddddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 900px) {
  .profile-container .cadre-profil .profile-element {
    display: block;
  }
  .profile-container .cadre-profil .profile-element .info-label {
    display: flex;
    justify-content: space-between;
  }
}
.profile-container .cadre-profil .profile-element:last-child {
  border-bottom: none;
}
.profile-container .cadre-profil .profile-element .info-value {
  position: relative;
  top: 2px;
  margin-left: 5px;
}
@media screen and (max-width: 900px) {
  .profile-container .cadre-profil .profile-element .info-value {
    display: block;
    text-align: center;
    padding: 0px;
    margin: 20px 0 0 0;
  }
}
.profile-container .cadre-profil .profile-element .info-value a {
  color: #1576E1;
  padding-left: 20px;
}
.profile-container .cadre-profil .profile-element a:hover {
  text-decoration: none;
}
.profile-container .cadre-profil .profile-element .file-action-container svg[data-icon=eye] {
  font-size: 14px;
  color: #1576E1;
}
.profile-container .cadre-profil .profile-element .file-action-container label {
  color: #1576E1;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
}
.profile-container .cadre-profil .profile-element .file-action-container a.primary-button {
  background-color: #1576E1;
  color: #ffffff;
  display: inline-block;
}
.profile-container .cadre-profil .profile-element .file-action-container a.primary-button:hover {
  text-decoration: none;
  color: #ffffff;
  background-color: #0e519b;
}
.profile-container .cadre-profil .profile-element .file-action-container .recto-verso a {
  padding: 0 0.5rem;
}
@media screen and (max-width: 900px) {
  .profile-container .cadre-profil .profile-element .file-action-container {
    width: 100%;
  }
}
.profile-container .li-profil {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #282c2e;
  height: 29px;
  line-height: 29px;
  margin-bottom: 26px;
}
.profile-container .li-profil span {
  padding: 10px 0px 0px 20px;
}
.profile-container .li-profil.disabled .verticalLine {
  border-color: rgba(123, 126, 128, 0.3);
}
.profile-container .ul-btn {
  position: relative;
  margin-top: -2px;
  width: auto;
}
.profile-container .verticalLine {
  position: relative;
  height: 12px;
  width: 1px;
  border-left: 1px solid #2dcfb3;
  left: 16px;
  top: 9px;
}
.profile-container .verticalLine.disabled {
  border-color: rgba(123, 126, 128, 0.3);
}
.profile-container .after-btn {
  top: 8px;
}
.profile-container .download-buttons {
  display: flex;
}
@media screen and (max-width: 1220px) {
  .profile-container .download-buttons {
    flex-direction: column;
  }
}
.profile-container .download-contract-button {
  padding: 60px 0px 12px 0px;
}
.profile-container .download-contract-button.secondary {
  margin-left: 58px;
}
@media screen and (max-width: 960px) {
  .profile-container .download-contract-button .section-button {
    padding: 6px 20px 10px 20px;
  }
}
@media screen and (max-width: 1220px) {
  .profile-container .download-contract-button {
    padding: 15px 0px 12px 0px;
  }
}
.profile-container .profil-title {
  font-family: AvenirLTPro-Heavy;
  font-size: 14px;
  color: #282c2e;
  text-transform: uppercase;
  line-height: 30px;
  margin-bottom: 15px;
}
@media screen and (max-width: 1220px) {
  .profile-container .profil-title {
    margin-bottom: 5px;
  }
}
.profile-container .profil-title.top-margin {
  margin-bottom: 0;
}
.profile-container .profil-title.little-title {
  text-transform: none;
}
.profile-container .profil-title .info-shape {
  margin-right: 24px;
}
.profile-container .cadre-profil {
  border-radius: 2px;
  border: solid 2px #2dcfb3;
  margin-top: 20px;
}
.profile-container .bottom-5 {
  padding-bottom: 20px;
}
.profile-container .bottom-5 .top-20 {
  margin-top: 20px;
}
.profile-container .no-font-weight {
  font-weight: normal;
}
.profile-container .alerts {
  border-radius: 4px;
  margin-left: 15px;
  font-size: 11px;
  padding: 2px 8px 4px 8px;
  position: relative;
  top: -2px;
  height: 20px;
  display: inline-block;
}
.profile-container .valide-alert {
  background-color: #2dcfb3;
  color: white;
}
.profile-container .attente-alert {
  background-color: #7b7e80;
  color: white;
}
.profile-container .notif-alert {
  background-color: #1576E1;
  color: white;
}
.profile-container .warning-alert {
  background-color: #ffc36f;
  color: white;
}
.profile-container .error-alert {
  background-color: #eb4968;
  color: white;
}
.profile-container .auto-width {
  width: auto !important;
}
.profile-container .arrow-right-aside {
  width: 8px;
  margin-left: 10px;
  display: none;
}
.profile-container .right-content-setting {
  margin-left: 30%;
  padding: 0px 36px;
  display: grid;
}
@media screen and (min-width: 1480px) {
  .profile-container .right-content-setting {
    margin-left: 25%;
  }
}
@media screen and (min-width: 901px) and (max-width: 1199px) {
  .profile-container .right-content-setting {
    margin-left: 40%;
  }
}
.profile-container .right-content-setting .disabled .cadre-profil {
  border-color: #dddddd;
}
.profile-container .progress-circle span {
  font-family: AvenirLTPro-Roman;
  font-size: 24px;
  color: #282c2e;
}
.profile-container sup {
  font-size: 12px;
}
@media only screen and (max-width: 900px) {
  .profile-container .desktop-imp {
    display: none !important;
  }
  .profile-container .leftdown {
    padding-left: 26px !important;
  }
  .profile-container .arrow-right-aside {
    display: -webkit-inline-box;
  }
  .profile-container .cadre-profil {
    border: none !important;
    padding: 0;
  }
}

.profile-container-mobile .profile-menu {
  padding: 0px 16px 60px 16px;
}
.profile-container-mobile .profile-menu h1.title-head {
  margin-top: 50px;
  font-family: PublicoText-Bold;
  font-weight: 700;
  font-size: 24px;
  padding-left: 35px;
}
.profile-container-mobile .profile-menu .title-profil {
  font-size: 24px;
  text-align: center;
  text-transform: capitalize;
  padding: 28px 0px 46px 0px;
}
.profile-container-mobile .profile-menu .li-profil a {
  text-decoration: none;
}
.profile-container-mobile .profile-menu .li-profil a.section-button {
  margin-left: 20px;
}
.profile-container-mobile .postal_procuration_kbis_block {
  padding: 0px 20px 80px 20px;
}
.profile-container-mobile .informations-block {
  padding: 50px 12px 80px 12px;
}
.profile-container-mobile .legal-files-block {
  padding: 0px 12px 80px 12px;
}

#upload-modal .file-action-container label {
  color: #1576E1;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
}

/* font */
/* color */
/* text size */
/* utils */
.title-head {
  width: auto;
  max-padding-left: 45px;
  font-family: "Open Sans", sans-serif;
  color: #282c2e;
  font-size: 18px;
  font-weight: 700;
}

.detail-service {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: #7b7e80;
  margin-bottom: 30px;
}

.pic-large {
  margin-bottom: 50px;
  background-color: #F5F7FB;
  padding: 22px 21px 18px 20px;
  border-radius: 4px;
}
.pic-large img {
  text-align: left;
  height: 40px;
  margin-bottom: 20px;
}
.pic-large button.btn-service {
  text-decoration: none;
  border: none;
  font-size: 14px;
  line-height: 18px;
  background-color: #F5F7FB;
  font-family: "OpenSans-semibold";
  padding-left: 0;
  color: #1576e1;
}
.pic-large button.btn-service:hover {
  cursor: pointer;
}
.pic-large button.btn-service svg {
  margin-left: 15px;
}

.service-nav {
  font-family: AvenirLTPro-Heavy;
  font-size: 14px;
  color: #282c2e;
  text-transform: uppercase;
}
.service-nav.disabled {
  opacity: 0.5;
}
.service-nav.disabled a {
  cursor: not-allowed;
}
.service-nav .add-service-link:before {
  content: "+";
  padding-right: 10px;
}

.tel-number {
  padding: 10px;
  font-family: AvenirLTPro-Roman;
  font-size: 18px;
}

.display-filtre {
  display: none;
}
@media only screen and (max-width: 900px) {
  .display-filtre {
    display: block;
  }
}

.price-right {
  float: right;
  font-size: 11px;
  font-family: "Open Sans", sans-serif;
}
@media only screen and (max-width: 900px) {
  .price-right {
    float: right;
    margin-top: -34px;
  }
}

.arrow-width {
  width: 8px;
}

.bottom-service {
  position: absolute;
  bottom: 35px;
  width: 75%;
}

.soon {
  width: 35px;
  border-radius: 4px;
  border: solid 1px #1576E1;
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: #1576E1;
  text-transform: none;
  text-align: center;
  float: right;
  position: relative;
  top: 42%;
  right: 15%;
  line-height: 12px;
}

.hover-valide:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid #2dcfb3;
}

.link-btn-service {
  position: absolute;
  bottom: 19px;
  width: 90%;
  text-align: center;
  margin: 15px;
}
@media screen and (max-width: 900px) {
  .link-btn-service {
    position: relative;
    margin: auto;
    display: block;
    text-align: center;
    width: 80%;
    margin-top: 50px;
  }
}

.slider-image {
  width: 230px;
  float: left !important;
}
.slider-image img {
  width: auto !important;
  min-height: 180px;
}
@media only screen and (max-width: 900px) {
  .slider-image {
    width: 100%;
    float: none !important;
  }
}

.pic-carousel {
  width: 100%;
}

.text-left {
  text-align: left;
}

.return-link {
  position: absolute;
  cursor: pointer;
}
@media only screen and (max-width: 900px) {
  .return-link {
    margin-left: 18px;
  }
}

@media only screen and (max-width: 900px) {
  .no-bottom {
    margin-bottom: 0px;
  }
}

.no-bottom-desktop {
  margin-bottom: 0px !important;
}

.SingleDatePicker_picker {
  position: absolute;
  height: 298px !important;
  overflow-x: visible;
  overflow-y: visible;
  display: inline-block;
  z-index: 999999;
}

.dte-input {
  margin-bottom: 0px;
}

.full-padding .msg {
  text-align: center;
}
.full-padding .icon-info {
  color: #7b7e80;
}
.full-padding .icon-info img {
  margin-right: 0.5rem;
}

.room.bureaux-cadre {
  height: auto;
  border: solid 1px #ececec;
}
.room.bureaux-cadre .row {
  min-height: 180px;
}
.room.bureaux-cadre .title-cadre {
  padding: 8px 0;
}
.room.bureaux-cadre .icon-info {
  color: #7b7e80;
}
.room.bureaux-cadre .icon-info img {
  margin-top: -3px;
  margin-right: 6px;
}
.room.bureaux-cadre .equipments {
  font-size: 0.9rem;
  text-transform: initial;
  font-family: AvenirLTPro-Roman;
}

.carousel-room {
  min-height: 180px;
}
.carousel-room .carousel-inner {
  min-height: 180px;
}
.carousel-room .carousel-inner .carousel-item {
  min-height: 180px;
}
.carousel-room .carousel-inner .carousel-item .shadow-inset {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.5) inset;
  top: 0;
  left: 0;
  cursor: pointer;
}
.carousel-room .carousel-inner .carousel-item img {
  object-fit: cover;
  object-position: center;
}
.carousel-room .carousel-indicators li {
  border-radius: 50%;
  width: 7px;
  height: 7px;
  margin: 0 12px;
}

.nav__menu_filtre {
  padding: 0;
}
.nav__menu_filtre .SingleDatePickerInput, .nav__menu_filtre .DateInput, .nav__menu_filtre .DateInput_input {
  background-color: transparent;
}
.nav__menu_filtre .DateInput_input.DateInput_input__focused {
  background-color: white;
}
.nav__menu_filtre .row.full-height {
  height: 100vh;
}
.nav__menu_filtre .form-container {
  padding: 40px;
}
.nav__menu_filtre .form-container h4 {
  font-family: AvenirLTPro-Roman;
}

@media screen and (max-width: 900px) {
  .header-child-services {
    display: block;
    width: 100%;
    background: whitesmoke;
    left: 0px;
    padding: 80px 20px 14px 20px;
  }
}
.header-child-services .category-breadcrumb {
  font-family: AvenirLTPro-Roman;
}
@media screen and (max-width: 900px) {
  .header-child-services .category-breadcrumb {
    font-size: 14px;
    color: #282c2e;
    text-transform: uppercase;
  }
}
@media screen and (max-width: 900px) {
  .header-child-services .link-btn-service {
    width: 100%;
    margin-top: 16px;
  }
}

.service-fullscreen {
  z-index: 1003;
  position: absolute;
  top: 0;
  background: white;
  left: 0;
  width: 100%;
  min-height: 100%;
  min-height: 100vh;
}
@media screen and (max-width: 900px) {
  .service-fullscreen {
    padding: 50px;
  }
}
@media screen and (min-width: 901px) {
  .service-fullscreen .hotline-form {
    padding-top: 50px;
  }
}
.service-fullscreen .service-inner-form {
  max-width: 660px;
}
.service-fullscreen .service-inner-form h4 {
  padding-left: 15px;
}
.service-fullscreen .section-button {
  padding: 1.5rem;
  width: 100%;
}
@media screen and (max-width: 900px) {
  .service-fullscreen .section-button {
    padding: 7px;
    width: 100%;
    text-align: center;
  }
}
.service-fullscreen .error-input {
  position: absolute;
}
.service-fullscreen .close {
  width: auto;
  height: auto;
}
.service-fullscreen > .row {
  width: 100%;
  margin-left: 0;
}
.service-fullscreen .hint {
  position: relative;
  padding-bottom: 0.5rem;
  margin-bottom: 30px;
}
@media screen and (min-width: 901px) {
  .service-fullscreen .hint {
    padding-left: 15px;
  }
}

.price-summary {
  padding: 2rem 3rem;
  color: #282c2e;
  background-color: #fafafa;
  margin: 2rem auto;
  text-align: left;
}
.price-summary .dates {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}
.price-summary .detail {
  color: #858889;
  margin-bottom: 0.5rem;
}
.price-summary .detail.total {
  color: #282c2e;
  margin-top: 1.5rem;
}
.price-summary .detail.total .price {
  color: #282c2e;
}
.price-summary .detail .price {
  float: right;
  color: #858889;
}

.terms {
  margin-top: 1rem;
  font-size: 11px;
  color: #7b7e80;
}

.hotline-form .headset-div {
  text-align: center;
  margin-top: 66px;
  margin-bottom: 42px;
  padding: 1rem;
  border: 1px solid #dee2e6;
  min-height: 246px;
}
.hotline-form .headset-div.selected {
  border-color: #1576E1;
}
.hotline-form .headset-div:not(.selected) {
  opacity: 0.8;
}
@media screen and (max-width: 900px) {
  .hotline-form .headset-div {
    margin: 20px 10px;
  }
}
.hotline-form .headset-div .radio {
  width: 18px;
  height: 18px;
  margin: auto;
}
.hotline-form .hotline-sumup {
  background: whitesmoke;
  padding: 30px;
  border: none;
  color: #282c2e;
  margin: 50px 0px 0px 0px;
}
.hotline-form .hotline-sumup .icon-info {
  color: #7b7e80;
}
.hotline-form .hotline-sumup .icon-info .align-right {
  color: #282c2e;
  margin-right: 0;
}
.hotline-form .hotline-sumup .title-popup {
  margin-top: 0px;
}

.hotline-form-submit button[type=submit] {
  margin-top: 10px;
}

.form-booking .custom-select .options {
  max-height: 263px;
  overflow-y: auto;
}
.form-booking .hours-select .custom-select .options {
  max-height: 140px;
}
.form-booking button {
  margin-top: 25px;
}

.add-service-text {
  color: #1576E1;
  font-size: 11px;
  text-transform: uppercase;
}

/* font */
/* color */
/* text size */
/* utils */
/* Sidebar */
.sidebar-settings h1.title-service.no-center {
  padding-left: 30px;
}

/* Modal with sidebar offset */
#regularize-risks-modal {
  max-width: 740px;
}
@media screen and (min-width: 901px) {
  #regularize-risks-modal {
    margin-left: calc(50% + 232px / 2);
    transform: translateX(-50%);
  }
}
#regularize-risks-modal .modal-header {
  padding: 0 22px;
}
#regularize-risks-modal .modal-header img {
  margin-right: 16px;
}
#regularize-risks-modal .modal-body {
  margin: 0;
  margin-bottom: 20px;
  padding: 0 22px;
}
#regularize-risks-modal .modal-body .section-warning {
  margin-top: 16px;
}

/* Main Content */
.settings-block {
  padding: 50px 45px 160px 35px;
}
@media screen and (max-width: 900px) {
  .settings-block {
    padding: 60px 16px 160px 16px;
  }
}
.settings-block .subtitle-invoices {
  font-family: AvenirLTPro-Roman;
  margin-bottom: 30px;
  font-size: 24px;
  width: 100%;
  color: #282c2e;
}
.settings-block .subtitle-sidebar {
  font-family: AvenirLTPro-Roman;
  margin-bottom: 30px;
  font-size: 24px;
  width: 100%;
  color: #282c2e;
}
.settings-block .subtitle-sidebar button.section-button {
  float: right;
}
@media screen and (max-width: 900px) {
  .settings-block .subtitle-sidebar {
    font-size: 18px;
    margin-bottom: 80px;
  }
  .settings-block .subtitle-sidebar button.section-button {
    margin-top: 40px;
  }
}
.settings-block .primary-link {
  text-decoration: underline;
}
.settings-block .no-link {
  color: #7b7e80;
}
.settings-block .xtop-margin {
  margin-top: 110px;
}
@media screen and (max-width: 900px) {
  .settings-block .xtop-margin {
    margin-top: 40px;
  }
}
.settings-block.notifications-section {
  max-width: 900px;
}
.settings-block.notifications-section .notification-element {
  width: 100%;
}
.settings-block.notifications-section .notification-element .bloc-notification-large {
  width: 80%;
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 30px;
}
@media screen and (max-width: 900px) {
  .settings-block.notifications-section .notification-element .bloc-notification-large {
    margin-top: 0px;
  }
}
.settings-block.notifications-section .notification-element .bloc-notification-right {
  display: inline-block;
  text-align: right;
  width: 20%;
  vertical-align: middle;
}
@media screen and (max-width: 900px) {
  .settings-block.notifications-section .notification-element .bloc-notification-right {
    margin-left: -5%;
  }
}
.settings-block.notifications-section .notification-element .bloc-notification-right .checkbox {
  padding: 0;
  margin: 0;
}
.settings-block.notifications-section .column-email {
  float: right;
  clear: both;
  font-size: 13px;
  font-weight: normal;
  color: #7b7e80;
  padding-top: 6px;
}
.settings-block.notifications-section .column-email .column-title {
  font-weight: bold;
  margin-bottom: 5px;
}
.settings-block.notifications-section .column-email .email a {
  margin-left: 0.5rem;
  text-transform: none;
  font-size: 13px;
}
.settings-block.invoices-section .regularize-header {
  margin-bottom: 64px;
}
.settings-block.invoices-section .regularize-header .alert-message {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 16px;
  background: #F2E6E6;
  border-radius: 8px;
}
.settings-block.invoices-section .regularize-header .alert-message .alert-header {
  display: flex;
  gap: 16px;
}
.settings-block.invoices-section .regularize-header .alert-message .alert-header img {
  width: 18px;
  height: 18px;
}
.settings-block.invoices-section .regularize-header .alert-message .alert-header span {
  font-size: 14px;
  line-height: 150%;
  color: #CD100C;
  display: block;
}
.settings-block.invoices-section .regularize-header .alert-message span.alert-button {
  display: flex;
  flex-direction: row;
  padding: 11px 12px;
  gap: 10px;
  background: #ffffff;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  align-items: center;
  text-align: center;
}
.settings-block.invoices-section .regularize-header .regularize-message {
  justify-content: space-between;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 16px;
  border: 1px solid #D3DBE1;
  border-radius: 8px;
}
.settings-block.invoices-section .regularize-header .regularize-message .regularize-sum-title {
  font-size: 12px;
}
.settings-block.invoices-section .regularize-header .regularize-message .regularize-sum-price {
  font-size: 14px;
  display: block;
  font-weight: 500;
}
.settings-block.invoices-section .regularize-header .regularize-message .regularize-button {
  gap: 10px;
  padding: 11px 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  background: #CD100C;
  border-radius: 6px;
  cursor: pointer;
}
.settings-block.invoices-section .regularize-header .time-message {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  font-size: 12px;
  color: #7b7e80;
}
.settings-block.invoices-section .regularize-header .time-message img {
  margin: 0;
}
.settings-block.invoices-section table tbody td {
  padding-top: 20px;
  padding-bottom: 20px;
}
.settings-block.invoices-section table tbody td a {
  font-size: 13px;
  text-decoration: underline;
}
@media screen and (max-width: 900px) {
  .settings-block.invoices-section table td {
    padding: 10px 5px !important;
  }
}
@media screen and (max-width: 900px) {
  .settings-block.invoices-section table th {
    padding: 10px 5px !important;
  }
}
.settings-block.invoices-section.loading table tbody td span,
.settings-block.invoices-section.loading table tbody td a {
  background-color: #f0f0f0;
  color: transparent !important;
  border-radius: 7px;
}
@media screen and (max-width: 900px) {
  .settings-block.invoices-section table tbody td span.id-setting:before {
    content: "n°";
  }
}
@media screen and (min-width: 901px) {
  .settings-block.invoices-section .download-invoice-link {
    margin-left: 15px;
  }
}
.settings-block.invoices-section .subtitle {
  font-weight: bold;
}
.settings-block.invoices-section .head {
  font-weight: bold;
  font-size: 12px;
}
@media only screen and (max-width: mobile-breakpoint-i) {
  .settings-block.invoices-section .head {
    display: none;
  }
}
.settings-block.invoices-section .invoice-container.enabled:hover {
  box-shadow: 7px 7px 25px 0 rgba(0, 0, 0, 0.1);
}
.settings-block.invoices-section .invoice-container.enabled:hover .ligne-setting {
  border-bottom: none;
}
.settings-block.invoices-section .invoice-container .ligne-setting {
  padding: 22px 20px 3px 0px;
  width: 95%;
  margin-left: 5%;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: mobile-breakpoint-i) {
  .settings-block.invoices-section .invoice-container .ligne-setting {
    flex-wrap: wrap;
  }
  .settings-block.invoices-section .invoice-container .ligne-setting .name-setting {
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .settings-block.invoices-section .invoice-container .ligne-setting {
    flex-wrap: wrap;
  }
  .settings-block.invoices-section .invoice-container .ligne-setting .invoice-actions {
    width: 100%;
  }
}
.settings-block.invoices-section .invoice-container .id-setting, .settings-block.invoices-section .invoice-container .name-setting {
  font-size: 13px;
  color: #282c2e;
  text-transform: none;
}
.settings-block.invoices-section .invoice-container .info-setting {
  font-size: 13px;
  color: #7b7e80;
  text-transform: none;
}
@media screen and (max-width: 900px) {
  .settings-block.invoices-section .invoice-container .info-setting {
    text-align: right;
    padding-right: 12px;
  }
}
.settings-block.invoices-section .invoice-container .invoice-actions p {
  display: inline-block;
}
.settings-block.invoices-section .invoice-container .invoice-actions .price-setting {
  font-size: 13px;
  color: #282c2e;
  text-transform: none;
  margin-right: 15px;
  font-weight: bold;
  display: block;
}
.settings-block.invoices-section .invoice-container .invoice-actions .oval {
  float: none;
  margin-top: -4px;
  margin-left: 4px;
  margin-right: 4px;
  width: 3px;
}
@media screen and (max-width: 900px) {
  .settings-block.invoices-section .invoice-container td.label {
    min-width: 7rem;
  }
  .settings-block.invoices-section .invoice-container td.info-setting {
    min-width: 7.5rem;
  }
  .settings-block.invoices-section .invoice-container td.invoice-actions {
    min-width: 12rem;
  }
}
.settings-block.invoices-section .invoice-container .label span {
  display: block;
}
.settings-block.invoices-section .invoice-container .label > * {
  margin-left: 0;
}
.settings-block.invoices-section .invoice-container .invoice-actions > span.status {
  display: inline-block;
  border-radius: 10px;
  background-color: rgba(45, 207, 179, 0.1);
  color: #2dcfb3;
  font-size: 11px;
  font-family: AvenirLTPro-Roman;
  padding: 5px 15px;
  padding-bottom: 4px;
}
.settings-block.invoices-section .invoice-container.unpaid .invoice-actions > span.status {
  color: #eb4968;
  background-color: rgba(235, 73, 104, 0.1);
}
.settings-block.invoices-section .invoice-container.unpaid .label > a.regularize-invoice {
  display: inline-block;
  color: #eb4968;
  margin-left: 1rem;
}
.settings-block.invoices-section .invoice-container.unpaid .download-unpaid {
  width: 2rem;
  padding-left: 0;
  padding-right: 0;
}
.settings-block.invoices-section .invoice-container.disabled {
  cursor: default;
}
.settings-block.invoices-section .invoice-container.disabled .download-invoice-link {
  cursor: default;
  opacity: 0.5;
}
.settings-block.invoices-section .invoice-container.disabled .download-invoice-link:hover {
  text-decoration: underline;
}
.settings-block.payment-section h2 button img,
.settings-block.payment-section h2 button svg {
  padding-right: 10px;
}
.settings-block.payment-section .payment-element {
  padding: 20px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
}
.settings-block.payment-section .payment-element .method-title,
.settings-block.payment-section .payment-element .action-links,
.settings-block.payment-section .payment-element .method-sumup {
  display: inline-flex;
  height: 60px;
  width: 300px;
  vertical-align: middle;
  align-items: center;
}
.settings-block.payment-section .payment-element .method-title p,
.settings-block.payment-section .payment-element .action-links p,
.settings-block.payment-section .payment-element .method-sumup p {
  margin: 0px;
}
.settings-block.payment-section .payment-element .method-title {
  min-width: 90px;
}
.settings-block.payment-section .payment-element .method-sumup {
  min-width: 110px;
}
.settings-block.payment-section .payment-element .action-links {
  min-width: 206px;
}
.settings-block.payment-section .payment-element .action-links p {
  margin-left: auto;
  font-size: 13px;
}
.settings-block.payment-section .payment-element .action-links p span {
  font-size: 13px;
}
.settings-block.payment-section .payment-element .method-sumup .carte-paiement {
  width: 35px;
  height: 18px;
  margin-left: -60px;
  margin-right: 26px;
  color: #7b7e80;
}
.settings-block.payment-section .payment-element .method-sumup .card-status {
  margin-top: 10px;
  margin-left: 10px;
}
.settings-block.payment-section .payment-element .method-sumup .info-sub {
  font-size: 11px;
}
.settings-block.payment-section .payment-element.default {
  background-color: rgba(21, 118, 225, 0.1);
  padding: 0 20px;
  margin: 20px 0;
}
@media screen and (max-width: 1240px) {
  .settings-block.payment-section .payment-element {
    display: block;
    text-align: center;
  }
  .settings-block.payment-section .payment-element .method-title,
  .settings-block.payment-section .payment-element .action-links,
  .settings-block.payment-section .payment-element .method-sumup {
    height: auto;
    display: block;
    width: 100%;
    text-align: center;
  }
  .settings-block.payment-section .payment-element .method-title {
    padding-bottom: 14px;
  }
  .settings-block.payment-section .payment-element .action-links {
    padding-top: 14px;
  }
  .settings-block.payment-section .payment-element .last4 {
    padding-top: 10px;
    display: block;
  }
  .settings-block.payment-section .payment-element .method-sumup .carte-paiement {
    margin: auto;
  }
  .settings-block.payment-section .payment-element .info-sub {
    display: block;
  }
  .settings-block.payment-section .subtitle-sidebar {
    text-align: center;
    margin: 0;
  }
  .settings-block.payment-section .subtitle-sidebar button.section-button {
    float: none;
    display: block;
    margin: 30px auto 20px auto;
  }
}

#payment-source-create {
  max-width: 600px;
}
#payment-source-create .modal-body {
  margin-top: -20px;
}
#payment-source-create .modal-body .modal-content {
  padding: 0 15px;
}
#payment-source-create ul.nav.nav-tabs li.nav-item {
  width: 50%;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
}
#payment-source-create ul.nav.nav-tabs li.nav-item.active {
  border-bottom: 1px solid #1576E1;
}
#payment-source-create ul.nav.nav-tabs li.nav-item a.nav-link {
  color: #7b7e80;
}
#payment-source-create ul.nav.nav-tabs li.nav-item a.nav-link:visited {
  color: #7b7e80;
}
#payment-source-create ul.nav.nav-tabs li.nav-item a.nav-link:focus, #payment-source-create ul.nav.nav-tabs li.nav-item a.nav-link:hover, #payment-source-create ul.nav.nav-tabs li.nav-item a.nav-link:active, #payment-source-create ul.nav.nav-tabs li.nav-item a.nav-link.active {
  color: #282c2e;
}
#payment-source-create .tab-content {
  margin-bottom: -15px;
  padding-top: 15px;
}
#payment-source-create .tab-content .tab-pane .cardSection,
#payment-source-create .tab-content .tab-pane .sepaSection {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
}
#payment-source-create .tab-content .tab-pane .cardSection .sepa-error-message,
#payment-source-create .tab-content .tab-pane .sepaSection .sepa-error-message {
  margin-right: 14%;
  margin-left: 14%;
  margin-top: 5%;
}
#payment-source-create .tab-content .tab-pane .cardSection .sepa-error-message .sepa-error-message-text,
#payment-source-create .tab-content .tab-pane .sepaSection .sepa-error-message .sepa-error-message-text {
  margin-top: 5%;
}
#payment-source-create .tab-content .tab-pane .cardSection button,
#payment-source-create .tab-content .tab-pane .sepaSection button {
  margin: 0 15px;
}
@media screen and (max-width: 900px) {
  #payment-source-create .tab-content .tab-pane .cardSection button,
  #payment-source-create .tab-content .tab-pane .sepaSection button {
    margin: 0 5px;
  }
}
#payment-source-create .tab-content .tab-pane .sepaSection input.owner-account {
  border: 1px solid rgba(123, 126, 128, 0.57);
  border-radius: 4px;
  width: 100%;
  max-width: 460px;
  margin-bottom: 20px;
  padding: 8px 5px;
  font-size: 14px !important;
}
#payment-source-create .tab-content .tab-pane .sepaSection input.owner-account::placeholder {
  color: rgba(123, 126, 128, 0.57);
}
#payment-source-create .tab-content .tab-pane .sepaSection input.owner-account:focus {
  border: 1px solid rgba(123, 126, 128, 0.57) !important;
  outline: 0 none !important;
}
#payment-source-create .tab-content .tab-pane .sepaSection .legals {
  font-size: 9px;
  margin-top: 1rem;
  text-align: justify;
}
#payment-source-create .tab-content .tab-pane .sepaSection .select-country {
  width: 100%;
  max-width: 460px;
  margin: 0 auto 20px auto;
  margin-bottom: 20px;
  font-size: 14px !important;
}
#payment-source-create .tab-content .tab-pane .sepaSection .select-country::placeholder {
  color: rgba(123, 126, 128, 0.57);
}
#payment-source-create .tab-content .tab-pane .sepaSection .select-country:focus {
  border: 1px solid rgba(123, 126, 128, 0.57) !important;
  outline: 0 none !important;
}

.cardSection .cb-wrapper,
.cardSection .sepa-wrapper,
.sepaSection .cb-wrapper,
.sepaSection .sepa-wrapper {
  font-family: "Open Sans", sans-serif;
  border: 1px solid rgba(123, 126, 128, 0.57);
  border-radius: 4px;
  padding: 8px 6px 6px 6px;
  margin: 0px auto 30px auto;
  max-width: 460px;
  width: 100%;
  height: 37px;
}
.cardSection .cb-wrapper .form-errors,
.cardSection .sepa-wrapper .form-errors,
.sepaSection .cb-wrapper .form-errors,
.sepaSection .sepa-wrapper .form-errors {
  color: red;
  font-size: 11px;
  margin-top: 11px;
}

.cardSection .cb-wrapper {
  margin-top: 3rem;
}

#retry-payment-modal .modal-body {
  padding-bottom: 2rem;
}
#retry-payment-modal .modal-body .choices {
  display: flex;
  justify-content: space-around;
}
#retry-payment-modal .modal-body .choices .radio {
  display: inline;
}
#retry-payment-modal .modal-body .choices .radio label {
  width: auto;
}
#retry-payment-modal .modal-body .stripe .title {
  width: auto;
  min-height: auto;
  font-size: 14px;
  margin: 2rem 0 0.5rem 0;
}
#retry-payment-modal .modal-body .stripe .hint {
  color: #7b7e80;
  font-size: 11px;
}
#retry-payment-modal .modal-body .stripe .cardSection .cb-wrapper {
  margin: 0;
}
#retry-payment-modal .modal-body .stripe .payment-source-selector .radio {
  font-family: "Open Sans", sans-serif;
  border: 1px solid rgba(123, 126, 128, 0.57);
  border-radius: 4px;
  padding: 8px 6px 6px 6px;
  margin-bottom: 7px;
  max-width: 460px;
  width: 100%;
  height: 37px;
}
#retry-payment-modal .modal-body .stripe .payment-source-selector .radio .info-sub {
  display: inline;
  float: right;
}
#retry-payment-modal .modal-body .stripe .submit {
  margin-top: 15px;
}
#retry-payment-modal .modal-body .stripe .form-errors {
  color: red;
  font-size: 11px;
  margin-top: 11px;
}

.confirm-password {
  margin-top: -10px;
}

.legals-section .choice h1 {
  font-size: 24px;
}
.legals-section .choice p {
  color: #7b7e80;
  font-size: 13px;
  font-family: AvenirLTPro-Roman;
}
.legals-section .choice .action {
  margin-top: 3rem;
}
.legals-section .choice .action img {
  max-width: 90px;
  max-height: 90px;
  margin: 10px 20px;
}
.legals-section .choice .action .btn:hover {
  color: inherit;
}

.terms {
  font-family: AvenirLTPro-Roman;
  color: #282c2e;
  font-size: 13px;
}
.terms h1 {
  text-align: center;
  font-size: 35px;
}
.terms .slogan {
  text-align: center;
  color: #1576E1;
  margin-top: -10px;
  margin-bottom: 15px;
  font-size: 13px;
}
.terms h2, .terms h3, .terms h4 {
  font-size: 18px;
  margin-top: 30px;
}
.terms p, .terms a {
  color: #7b7e80;
  line-height: 1.6;
  margin-bottom: 15px;
}
.terms ul {
  list-style: circle;
}
.terms ul li {
  color: #7b7e80;
  font-size: 13px;
}

.termination-survey {
  max-width: 600px;
  margin: auto;
  min-height: 100vh;
  font-family: AvenirLTPro-Roman;
}
.termination-survey h1 {
  margin: 1rem 0;
  text-align: center;
  font-size: 35px;
}
.termination-survey h2 {
  font-size: 24px;
}
.termination-survey label {
  font-size: 18px;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  margin: 2rem 0;
}

a[href^="http://maps.google.com/maps"] {
  display: none !important;
}

a[href^="https://maps.google.com/maps"] {
  display: none !important;
}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

.gmap-container {
  width: 100%;
  height: 500px;
}

.gmap-close {
  background-color: white;
  border-radius: 20px;
  position: absolute;
  width: 40px;
  height: 40px;
  right: 10px;
  top: 10px;
  z-index: 99999;
  border: 3px solid rgba(0, 0, 0, 0.4705882353);
}
.gmap-close button.close {
  height: 100%;
  width: 100%;
  margin: 0;
}

/* font */
/* color */
/* text size */
/* utils */
.hotline-form {
  max-width: 660px;
  margin: auto;
}
.hotline-form .slogan {
  margin-top: -4px;
}
.hotline-form .primary-link {
  font-family: AvenirLTPro-Heavy;
}
.hotline-form textarea::placeholder {
  font-size: 11px;
}

/* font */
/* color */
/* text size */
/* utils */
.download-img-icon {
  color: #7b7e80;
  background-color: white;
}

/* font */
/* color */
/* text size */
/* utils */
.ant-modal-mask {
  z-index: 1005 !important;
}

.ant-modal-wrap {
  z-index: 1006 !important;
}

.modal-form .modal-content {
  padding: 0;
}
.modal-form .modal-content .modal-header {
  padding: 0 16px;
  border-bottom: 1px solid #dddddd !important;
}
.modal-form .modal-content .modal-header .modal-title {
  padding-top: 12px;
  font-size: 18px;
}
.modal-form .modal-content .modal-header .modal-title button {
  opacity: 1;
  margin: 0;
  padding: 0;
  color: #282c2e;
  position: relative;
  display: inline-block;
  top: auto;
  right: auto;
  width: auto;
  height: auto;
  font-size: 1.4rem;
}
.modal-form .modal-content .modal-header .modal-title .title-popup {
  margin: 0;
  float: none;
  display: inline;
  height: auto;
}
.modal-form .modal-content .modal-body {
  margin-top: 0px;
}
.modal-form .modal-content .modal-footer {
  padding-top: 0px;
}
.modal-form.user-mails-share .custom.contact-box {
  border: none;
  position: relative;
}
.modal-form.user-mails-share .custom.contact-box .radio {
  position: absolute;
}
.modal-form.user-mails-share .custom.contact-box .radio label {
  display: none;
}
.modal-form.user-mails-share .custom.contact-box input {
  width: 100%;
}
.modal-form.user-mails-share .custom.contact-box svg.fa-envelope {
  position: absolute;
  top: 16px;
  left: 14px;
  font-size: 14px;
  color: #7b7e80;
}
.modal-form.modal-usermail-query .modal-content {
  padding: 0px;
}
.modal-form.modal-usermail-query .modal-content button.close {
  font-size: 1.6rem;
  opacity: 1;
  color: #282c2e;
}
.modal-form.modal-map {
  width: 730px;
  max-width: initial;
}
.modal-form.modal-map .modal-content {
  padding: 0;
}
.modal-form.modal-map .modal-content .modal-body {
  padding: 0;
}
.modal-form.modal-map .modal-content .modal-body > .row {
  padding: 0;
}

.ant-notification {
  z-index: 10000;
}

.communication-notifications {
  position: fixed;
  top: 69px;
  left: 232px;
  width: calc(100% - 232px);
  z-index: 3;
}
.communication-notifications .notification {
  font-size: 13px;
  background: #FFE4ED;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
  color: #282C2E;
}
.communication-notifications .notification a {
  color: #282C2E;
}
.communication-notifications .notification .close.historique-close {
  position: relative;
  right: 0;
  margin-top: 0;
  margin-right: 0;
  top: -3px;
}
.communication-notifications .notification .close.historique-close > svg {
  display: block;
}
@media only screen and (max-width: 900px) {
  .communication-notifications {
    left: 1rem;
    right: 1rem;
    top: 5rem;
    width: auto;
  }
}
.communication-notifications.demo .notification {
  padding: 0.5rem 1rem;
}
.communication-notifications.demo .section-button {
  margin-bottom: 0;
  display: inline-block;
  text-transform: uppercase;
  padding: 10px;
  margin-left: 2rem;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
}
@media only screen and (max-width: 1700px) {
  .communication-notifications.demo {
    height: auto;
  }
  .communication-notifications.demo .section-button {
    margin-left: 0;
    margin-top: 0.5rem;
  }
}

.demo-continue-modal h3 {
  font-size: 16px;
  padding-top: 20px;
  font-family: AvenirLTPro-Roman;
}
.demo-continue-modal .modal-footer {
  justify-content: center;
}
.demo-continue-modal button {
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  min-width: 180px;
  text-align: center;
}

.demo-catch-lead-modal h1 {
  font-family: AvenirLTPro-Roman;
  font-size: 21px;
  font-weight: 900;
  margin: 0;
  display: inline-block;
}
.demo-catch-lead-modal .line {
  width: 197px;
  margin-top: 4px;
  height: 2px;
}
.demo-catch-lead-modal p {
  font-family: AvenirLTPro-Roman;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.6;
  margin: 2rem 0;
}
.demo-catch-lead-modal .error-input {
  text-align: left;
}
.demo-catch-lead-modal button {
  text-transform: uppercase;
}
.demo-catch-lead-modal #avis-verifies {
  text-align: center;
  position: relative;
  margin: 2rem 0;
}
.demo-catch-lead-modal #avis-verifies .overlay {
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* font */
/* color */
/* text size */
/* utils */
.placeholder-option .display {
  color: #a9abae !important;
  opacity: 1;
}

.form-control::placeholder {
  color: #a9abae !important;
  opacity: 1;
}

.contact-message-modal .modal-header button.close {
  font-size: 60px;
  font-weight: 100;
  opacity: 1;
  position: absolute;
  right: 42px;
  top: 24px;
  width: 36px;
  margin: 0;
  padding: 0;
  height: auto;
  width: auto;
  z-index: 9999999;
}
.contact-message-modal .modal-body .support-description .support-description-element p strong {
  color: #3477da;
}
.contact-message-modal .modal-body .support-message-and-description {
  margin: auto;
  max-width: 980px;
}
.contact-message-modal .modal-body .support-message-and-description .support-contact-message {
  font-size: 15px;
}
.contact-message-modal .modal-body .support-message-and-description .support-contact-message p a {
  color: #3477da;
  text-decoration: underline;
}
.contact-message-modal .modal-body .support-message-and-description .support-description .support-description-element {
  font-size: 14px;
  text-align: center;
}
.contact-message-modal .modal-body .support-message-and-description .support-description .support-description-element.support-phone img {
  padding: 7px 0px;
}
.contact-message-modal .modal-body .support-message-and-description .support-description .support-description-element strong {
  color: #1576E1;
}
.contact-message-modal .modal-body img {
  margin: auto;
  display: block;
}
.contact-message-modal .modal-body h1 {
  font-family: AvenirLTPro-Roman;
  font-size: 34px;
  display: block;
  text-align: center;
  padding: 30px 0px;
}
@media screen and (max-width: 900px) {
  .contact-message-modal .modal-body h1 {
    font-size: 35px;
  }
}
.contact-message-modal .modal-body form {
  max-width: 950px;
  margin: auto;
}
.contact-message-modal .modal-body form label {
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  color: black;
  padding: 8px 0px;
  height: auto;
}
.contact-message-modal .modal-body form .customInputFile .custom-file-label {
  text-align: center;
  color: #1576E1;
}
.contact-message-modal .modal-body form .customInputFile .custom-file-label:after {
  content: "+";
  height: 44px;
  background-color: #1576E1;
  color: white;
}
.contact-message-modal .modal-body form button[type=submit] {
  background-color: #1576E1;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  border-radius: 4px;
  padding: 14px 70px;
  display: block;
  margin: 40px auto;
  border: none;
}
.contact-message-modal .modal-body form button[type=submit]:hover {
  background-color: #0e519b;
}
.contact-message-modal .modal-body form button[type=submit]:disabled {
  background: #d0d1d1;
  border: 1px solid #d0d1d1 !important;
}

/* font */
/* color */
/* text size */
/* utils */
@media screen and (max-width: 900px) {
  .contact-of-sharing-container h2.subtitle-sidebar {
    margin-bottom: 20px;
  }
}
.contact-of-sharing-container h3 {
  font-size: 18px;
  font-family: AvenirLTPro-Roman;
  margin-top: 56px;
}
.contact-of-sharing-container p.desc {
  font-size: 13px;
  padding-bottom: 14px;
}
.contact-of-sharing-container .add-button {
  text-align: right;
}
@media screen and (max-width: 900px) {
  .contact-of-sharing-container .add-button {
    margin-top: 30px;
    text-align: center;
  }
}
.contact-of-sharing-container .contact-of-sharing-element {
  margin-top: 34px;
  padding-bottom: 12px;
}
.contact-of-sharing-container .contact-of-sharing-element .contact-full-name {
  min-width: 30%;
  text-transform: capitalize;
}
.contact-of-sharing-container .contact-of-sharing-element .contact-category {
  background-color: rgba(21, 118, 225, 0.1);
  padding: 4px 10px 6px 10px;
  border-radius: 10px;
  display: inline-block;
  color: #1576e1;
  text-transform: capitalize;
}
@media screen and (max-width: 900px) {
  .contact-of-sharing-container .contact-of-sharing-element .contact-category {
    float: right;
  }
}

.modal-form.contact-of-sharing-modal .modal-content .modal-header {
  text-align: center;
}
.modal-form.contact-of-sharing-modal .modal-content .modal-header .modal-title .title-popup {
  font-family: AvenirLTPro-Heavy;
  display: block;
}
.modal-form.contact-of-sharing-modal .modal-content .modal-footer {
  padding-top: 12px;
}

/* font */
/* color */
/* text size */
/* utils */
.page-team {
  margin: 0px 45px;
}
@media screen and (max-width: 900px) {
  .page-team {
    margin: 0px 24px;
  }
}
.page-team .team-section-contact {
  margin-top: 70px;
}
.page-team .team-section-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}
.page-team .team-section-title h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
  align-self: center;
}
@media screen and (max-width: 900px) {
  .page-team .team-section-title h2 {
    font-size: 18px;
  }
}
.page-team .team-row {
  display: flex;
  border-bottom: 1px solid #dddddd;
  height: 60px;
  font-size: 14px;
}
@media screen and (max-width: 1199px) {
  .page-team .team-row {
    justify-content: space-between;
  }
}
.page-team .team-row .team-column {
  display: inline-block;
  align-self: center;
  padding-left: 10px;
}
.page-team .team-row .team-column.team-name {
  width: 40%;
}
@media screen and (max-width: 1199px) {
  .page-team .team-row .team-column.team-name {
    width: 100%;
  }
}
.page-team .team-row .team-column.team-name div:first-child {
  text-transform: uppercase;
}
.page-team .team-row .team-column.team-role {
  width: 20%;
  color: #7B7E80;
}
@media screen and (max-width: 1199px) {
  .page-team .team-row .team-column.team-role {
    display: none;
  }
}
.page-team .team-row .team-column.team-permission {
  width: 35%;
  color: #7B7E80;
}
@media screen and (max-width: 1199px) {
  .page-team .team-row .team-column.team-permission {
    display: none;
  }
}
.page-team .team-row .team-column.team-actions {
  width: 5%;
}
.page-team .team-row .team-column.team-contact-name {
  width: 40%;
  color: #7B7E80;
}
@media screen and (max-width: 1199px) {
  .page-team .team-row .team-column.team-contact-name {
    width: 80%;
  }
}
.page-team .team-row .team-column.team-category {
  width: 20%;
  color: #7B7E80;
}
@media screen and (max-width: 1199px) {
  .page-team .team-row .team-column.team-category {
    display: none;
  }
}
.page-team .team-row .team-column.team-contact-email {
  width: 35%;
  color: #7B7E80;
}
@media screen and (max-width: 1199px) {
  .page-team .team-row .team-column.team-contact-email {
    display: none;
  }
}
.page-team .team-row.first {
  height: 22px;
  padding-bottom: 2px;
}
.page-team .team-dropdown-button {
  border-radius: 6px;
}
.page-team .team-dropdown-button:hover {
  background: #F9F9F9;
}

.team-dropdown .ant-dropdown-menu {
  background: #282C2E;
  border-radius: 4px;
}
.team-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  color: #FFFFFF;
}
.team-dropdown .ant-dropdown-menu .ant-dropdown-menu-item a {
  text-decoration: none;
}
.team-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background: #1576E1;
}

.team-modal .ant-modal-content {
  border-radius: 8px;
}
.team-modal .ant-modal-title {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}
.team-modal .team-label {
  margin-top: 20px;
  margin-bottom: 10px;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}
.team-modal .error-input {
  font-size: 10px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #FF3465;
}
.team-modal .ant-checkbox-wrapper {
  margin-top: 20px;
}
.team-modal.team-modal-upsell .ant-modal-footer {
  font-family: "Open Sans", sans-serif;
  background: #F9F9F9;
  text-align: center;
  padding: 30px 40px;
  color: #7B7E80;
}

.page-referrer {
  margin: 0px 45px;
}
@media screen and (max-width: 1050px) {
  .page-referrer {
    margin: 0px 24px;
  }
}
.page-referrer h1 {
  width: 50%;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: #282C2E;
}
@media screen and (max-width: 1050px) {
  .page-referrer h1 {
    width: 100%;
  }
}
.page-referrer .referrer_top {
  display: flex;
  column-gap: 25px;
  margin: 35px 0 58px 0;
}
@media screen and (max-width: 1050px) {
  .page-referrer .referrer_top {
    display: block;
    column-gap: unset;
  }
}
.page-referrer .referrer_top > div {
  padding: 24px;
  border-radius: 8px;
}
.page-referrer .referrer_top .referrer_share {
  border: 1px solid #D3DBE1;
  width: 359px;
}
@media screen and (max-width: 1050px) {
  .page-referrer .referrer_top .referrer_share {
    width: 100%;
    margin-bottom: 34px;
  }
}
.page-referrer .referrer_top .referrer_share h3 {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  margin-bottom: 34px;
}
.page-referrer .referrer_top .referrer_share .referrer_summary {
  padding: 12px 16px;
  background: #E7F2FF;
  border-radius: 6px;
  margin-bottom: 16px;
  display: flex;
  column-gap: 32px;
}
.page-referrer .referrer_top .referrer_share .referrer_summary .bloc .bloc-title {
  color: #75818C;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  margin-bottom: 8px;
}
.page-referrer .referrer_top .referrer_share .referrer_summary .bloc .bloc-content {
  color: #282C2E;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
.page-referrer .referrer_top .referrer_share .referrer_code {
  padding: 12px 16px;
  background: #F5F7FB;
  border-radius: 6px;
}
.page-referrer .referrer_top .referrer_share .referrer_code .referrer_code_txt {
  color: #75818C;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: inline-block;
}
.page-referrer .referrer_top .referrer_share .referrer_code .referrer_code_digit {
  cursor: pointer;
  display: inline-block;
  padding: 0 30px 0 10px;
}
.page-referrer .referrer_top .referrer_share .referrer_code .referrer_code_icon {
  display: inline-block;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-image: url("images/copy.svg");
  vertical-align: bottom;
}
.page-referrer .referrer_top .referrer_share .referrer_code .referrer_code_icon:hover {
  background-image: url("images/copy-hover.svg");
}
.page-referrer .referrer_top .referrer_rules {
  background: #F5F7FB;
  width: calc(100% - 359px - 25px);
}
@media screen and (max-width: 1050px) {
  .page-referrer .referrer_top .referrer_rules {
    width: 100%;
    margin-bottom: 32px;
  }
}
.page-referrer .referrer_top .referrer_rules h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #282C2E;
  margin-bottom: 16px;
}
.page-referrer .referrer_top .referrer_rules p {
  color: #282C2E;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
}
.page-referrer .referrer_top .referrer_rules .referrer_condition {
  color: #1576E1;
}
.page-referrer .referrer_overview h2 {
  font-size: 20px;
  line-height: 28px;
  color: #282C2E;
  font-weight: 700;
}
.page-referrer .referrer_overview h2 img {
  margin-right: 28px;
}
.page-referrer .referrer_overview .blocs {
  border: 1px solid #D3DBE1;
  padding: 24px;
  border-radius: 8px;
  display: flex;
  column-gap: 32px;
  flex-wrap: wrap;
}
@media screen and (max-width: 1050px) {
  .page-referrer .referrer_overview .blocs {
    display: block;
  }
}
.page-referrer .referrer_overview .blocs .bloc {
  width: calc(33% - 32px);
  padding-bottom: 24px;
}
@media screen and (max-width: 1050px) {
  .page-referrer .referrer_overview .blocs .bloc {
    width: 100%;
  }
}
.page-referrer .referrer_overview .blocs .bloc .bloc-title {
  color: #75818C;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  margin-bottom: 8px;
}
.page-referrer .referrer_overview .blocs .bloc .bloc-content {
  color: #282C2E;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
.page-referrer .referrer_overview .blocs .bloc-double {
  width: calc(66% - 32px);
}
.page-referrer .referrer_rewards {
  margin-top: 50px;
}
.page-referrer .referrer_rewards h2 {
  font-size: 20px;
  line-height: 28px;
  color: #282C2E;
  font-weight: 700;
}
.page-referrer .referrer_rewards h2 img {
  margin-right: 28px;
}
.page-referrer .referees-outer .referees {
  width: 100%;
}
.page-referrer .referees-outer .referees thead {
  background: #F5F7FB;
}
.page-referrer .referees-outer .referees th, .page-referrer .referees-outer .referees td {
  font-size: 14px;
  font-weight: 700px;
  line-height: 21px;
  padding: 15px;
  color: #282C2E;
}
.page-referrer .referees-outer .referees td {
  font-weight: 400px;
}
.page-referrer .referees-outer .referees th {
  font-weight: 700px;
  border: none !important;
}

.referees-outer .error-input, .modal .error-input {
  margin-top: 6px;
  color: #1576E1;
  font-size: 10px;
}

.cancelation_procedure * {
  font-family: "Inter", "Open Sans";
  max-width: 650px;
}
.cancelation_procedure h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}
.cancelation_procedure .ant-form-item {
  max-width: 456px;
}
.cancelation_procedure .ant-form-item-label label {
  color: #191D20 !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 150% !important;
}
.cancelation_procedure .ant-form-item-required::before {
  display: none !important;
}
.cancelation_procedure .ant-select-selector {
  height: 55px !important;
  border: 1px solid #D3D7DA !important;
  border-radius: 8px !important;
  padding: 14px !important;
}
.cancelation_procedure .ant-select-selector .ant-select-selection-item {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}
.cancelation_procedure .ant-input {
  height: 55px !important;
  border: 1px solid #D3D7DA !important;
  border-radius: 8px !important;
  padding: 14px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}
.cancelation_procedure .ant-form-item-extra {
  background: #F5F7FB;
  border-radius: 8px;
  padding: 10px 10px 10px 44px;
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  margin: 5px 0;
}
.cancelation_procedure .ant-form-item-extra::before {
  content: "i";
  background: #1576E1;
  position: absolute;
  top: 12px;
  left: 12px;
  color: white;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  text-align: center;
  padding-top: 2px;
  font-size: 11px;
  font-weight: 400;
}
.cancelation_procedure .ant-radio-wrapper {
  margin-bottom: 8px;
  font-size: 16px;
}
.cancelation_procedure .form-item-submit {
  margin-top: 50px;
}
.cancelation_procedure .form-item-submit.flex-right .ant-form-item-control {
  align-items: flex-end;
}
.cancelation_procedure .ant-upload {
  width: 100%;
}
.cancelation_procedure .ant-upload button {
  width: 100%;
  height: 49px !important;
  border: 1px solid #D3D7DA !important;
  border-radius: 8px !important;
  padding: 14px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #6C767F;
  position: relative;
  padding-right: 40px !important;
  text-align: left;
}
.cancelation_procedure .ant-upload .anticon-upload {
  color: #1576E1;
  position: absolute;
  right: 10px;
  font-size: 20px;
  top: 12px;
}
.cancelation_procedure .upsell {
  padding: 26px 168px 26px 26px;
  border: 1px solid #D3DBE1;
  border-radius: 8px;
  margin-top: 27px;
  margin-bottom: 15px;
  position: relative;
}
.cancelation_procedure .upsell h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
}
.cancelation_procedure .upsell .price {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  margin-bottom: 21px;
}
.cancelation_procedure .upsell .description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #6C767F;
}
.cancelation_procedure .upsell img {
  position: absolute;
  right: 26px;
  top: 26px;
}
.cancelation_procedure .help {
  background: #F5F7FB;
  border-radius: 8px;
  padding: 18px 120px 18px 24px;
  margin-bottom: 48px;
  position: relative;
}
.cancelation_procedure .help h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #171A1C;
}
.cancelation_procedure .help p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #6C767F;
}
.cancelation_procedure .help.warning {
  background: #FFE4ED;
}
.cancelation_procedure .help.warning::after {
  content: "!";
  background: #FB82A9;
}
.cancelation_procedure .help::after {
  content: "✔";
  color: white;
  background: #1576E1;
  height: 32px;
  width: 32px;
  border-radius: 100%;
  position: absolute;
  top: 15px;
  right: 23px;
  text-align: center;
  font-size: 16px;
  padding-top: 3px;
}
.cancelation_procedure .ant-form-item-control {
  display: flex;
  flex-direction: column;
}
.cancelation_procedure .ant-form-item-control > div {
  order: 5;
}
.cancelation_procedure .ant-form-item-control .ant-form-item-control-input {
  order: 2;
}
.cancelation_procedure .ant-form-item-control .ant-form-item-extra {
  order: 3;
}
.cancelation_procedure .input-with-info .ant-form-item-extra {
  order: 1;
}
.cancelation_procedure .ant-btn-primary {
  background: #1576E1;
  border-radius: 8px;
  font-weight: 600;
  font-size: 15px;
  line-height: 125%;
  text-align: center;
  color: #FFFFFF;
  padding: 14px 24px;
  height: auto;
}
.cancelation_procedure .ant-btn-default {
  background: white;
  border-radius: 8px;
  font-weight: 600;
  font-size: 15px;
  line-height: 125%;
  text-align: center;
  color: #1576E1;
  padding: 14px 24px;
  height: auto;
  border: 1px solid #1576E1;
}
.cancelation_procedure .btns {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
}
.cancelation_procedure .btns button {
  flex-grow: 1;
}

@media (max-width: 1000px) {
  .btns {
    display: block !important;
    gap: 20px !important;
  }
  .btns .ant-btn {
    width: 100% !important;
    margin-bottom: 16px !important;
  }
}

