@import '../var'

.no-center
  text-align: left !important

.center
  text-align: center

.no-transform
  text-transform: none

.right-topmargin
  float: right
  margin-top: 5px

#fullwidth
  width: auto!important

.width100-mobile
  @media only screen and (max-width: $mobile-breakpoint-i)
    width: 100% !important

.width100
  width: 100%
  text-align: center

.section-top
  margin-top: -15px
  margin-bottom: 25px

.col-special-3
  width: 40%

.nomargin-left-right

  margin-left: 0px
  margin-right: 0px

.margin-right
  margin-right: 15px

.text-align-left
  text-align: left !important

.hidden-mobile
  @media only screen and (max-width: $mobile-breakpoint-i)
    display: none

.arrow-right
  width: 7.6px
  height: 9.4px
  opacity: 0.6

.right
  float: right
  width: auto
  margin-right: 18%
  color: $table-color

.float-none
  float: none!important
  
.auto-margin
  margin: auto!important

.bottom10
  margin-bottom: 10px

.spacer-right
  margin-right: 15px !important

.spacer
  padding: 15px

.under-space
  margin-top: 12px

.margin55
  @media only screen and (max-width: $mobile-breakpoint-i)
    margin-top: 55px

.margin22
  @media only screen and (max-width: $mobile-breakpoint-i)
    margin-top: 22px

.full-padding
  margin-top: -30px
  overflow-y: -webkit-paged-x

  @media only screen and (max-width: $mobile-breakpoint-i)
    margin-left: 0px !important
    padding: 15px

.notop-mobile
  @media only screen and (max-width: $mobile-breakpoint-i)
    margin-top: 0px

.spacer-2
  padding: 8px

.top-space
  padding-top: 30px

.bottom-box
  margin-top: 40px
  margin-bottom: 30px
  width: 100%

.noheight-btn
  @media only screen and (max-width: $mobile-breakpoint-i)
    height: 40px!important

.top-space-mobile
  @media only screen and (max-width: $mobile-breakpoint-i)
    margin-top: 60px

.padding-left-right
  padding-left: 50px
  padding-right: 50px

.extra-style
  font-size: 24px
  margin-bottom: 15px

  @media only screen and (max-width: $mobile-breakpoint-i)
    margin-top: 70px

.left-border
  border-left: 1px solid $gray-color
  padding-top: 0px

  @media only screen and (max-width: $mobile-breakpoint-i)
    border: none
.no-align
  text-align: left !important

.no-hover
  color: $table-color
  .nav-arrow-right
    opacity: 0.2
.no-hover:hover
  box-shadow: none !important
  background: none !important
  color: $table-color
  
.number-big
  font-size: $large-size
  color: $primary-color

.map2
  width: 230px

.tooltip-container
  display: inline
  margin-left: 4px