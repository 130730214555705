@import '../var'

.password-strength
  height: 10px
  display: flex
  justify-content: space-between
  &.empty
    visibility: hidden
  .block
    margin: 1px
    border: 1px solid $color-border
    width: 100%
  &.score-1, &.score-2
    .block.highlight
      background-color: $error-color
  &.score-3
    .block.highlight
      background-color: $warning-color
  &.score-4, &.score-5
    .block.highlight
      background-color: $success-color