@import '../var'

.section-button
  border-radius: 4px
  font-family: $font-primary
  font-size: $third-size !important
  text-decoration: none
  padding: 9px 24px 10px 24px
  cursor: pointer
  border: none
  position: relative

  &.btn-block
    svg
      position: absolute
      left: 40px
      top: 12px

  &:not(.btn-block)
    svg
      margin-right: 14px

  &.disabled
    background-color: $disabled-button
    color: white !important
    border-color: transparent
    cursor: default


.btn:disabled
  background: $disabled
  border: 1px solid $disabled!important

.section-button.submit
  background-color: $primary-color
  color: $white-color
  &:disabled
    background-color: $gray-color
    color: $white-color
    cursor: default

  &:hover:not(:disabled)
    background-color: $hover-color

  .submit-loader
    display: inline-block
    width: 20px
    position: relative

// color button
.primary-button
  background-color: $primary-color !important
  color: $white-color
.primary-button:hover
  text-decoration: none
  color: $white-color
  background-color: darken($primary-color, 15%) !important

.red-button
  background-color: $red-color
  color: $white-color
.red-button:hover
  text-decoration: none
  color: $white-color

.black-button
  background-color: $black-color
  color: $white-color
.black-button:hover
  text-decoration: none

.gray-button
  background-color: $gray-color !important
  color: $white-color
.gray-button:hover
  text-decoration: none
  color: white

.white-button
  border: solid 1px $gray-color!important
  background: $white-color
  color: $secondary-color

.transparent-button
  border: solid 1px $gray-color!important
  background: transparent
  color: $secondary-color

.button-pic
  width: 15px
  height: 15px!important
  margin-bottom: 1px!important
  margin-right: 10px

// alignement button
.btn-align-right
  float: right

.auto-button
  margin-left: auto
  margin-right: auto

// size button

// Type file

input[type="file"]
  cursor: pointer

.svg-white
  fill: white

.plus-utils-button
  border-radius: 4px
  font-family: $font-primary
  font-size: $third-size
  padding: 9px 24px 10px 24px
  position: relative
  cursor: pointer
  svg
    margin-right: 8px
    font-size: 17px
    vertical-align: middle
