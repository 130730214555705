@import './var'

.page-team
  margin: 0px 45px

  @media screen and (max-width: $mobile-breakpoint-i)
    margin: 0px 24px

  .team-section-contact
    margin-top: 70px

  .team-section-title
    display: flex
    justify-content: space-between
    margin-bottom: 25px

    h2
      font-family: $font-primary
      font-size: 22px
      align-self: center

      @media screen and (max-width: $mobile-breakpoint-i)
        font-size: 18px

  .team-row
    display: flex
    border-bottom: 1px solid $gray-color
    height: 60px
    font-size: 14px

    @media screen and (max-width: $xlarge-breakpoint-i)
      justify-content: space-between

    .team-column
      display: inline-block
      align-self: center
      padding-left: 10px

      &.team-name
        width: 40%
        @media screen and (max-width: $xlarge-breakpoint-i)
          width: 100%
        div:first-child
          text-transform: uppercase

      &.team-role
        width: 20%
        color: #7B7E80

        @media screen and (max-width: $xlarge-breakpoint-i)
          display: none

      &.team-permission
        width: 35%
        color: #7B7E80

        @media screen and (max-width: $xlarge-breakpoint-i)
          display: none

      &.team-actions
        width: 5%

      &.team-contact-name
        width: 40%
        color: #7B7E80

        @media screen and (max-width: $xlarge-breakpoint-i)
          width: 80%

      &.team-category
        width: 20%
        color: #7B7E80

        @media screen and (max-width: $xlarge-breakpoint-i)
          display: none

      &.team-contact-email
        width: 35%
        color: #7B7E80

        @media screen and (max-width: $xlarge-breakpoint-i)
          display: none

    &.first
      height: 22px
      padding-bottom: 2px

  .team-dropdown-button
    border-radius: 6px

    &:hover
      background: #F9F9F9

.team-dropdown
  .ant-dropdown-menu
    background: #282C2E
    border-radius: 4px

    .ant-dropdown-menu-item
      color: #FFFFFF

      a
        text-decoration: none

    .ant-dropdown-menu-item:hover
      background: #1576E1

.team-modal
  .ant-modal-content
    border-radius: 8px

  .ant-modal-title
    font-family: $font-primary
    font-weight: bold

  .team-label
    margin-top: 20px
    margin-bottom: 10px
    display: block
    font-family: $font-primary
    font-style: normal
    font-weight: 600
    font-size: 14px
    line-height: 18px

  .error-input
    font-size: 10px
    line-height: 18px
    display: flex
    align-items: center
    color: #FF3465

  .ant-checkbox-wrapper
    margin-top: 20px

  &.team-modal-upsell
    .ant-modal-footer
      font-family: $font-primary
      background: #F9F9F9
      text-align: center
      padding: 30px 40px
      color: #7B7E80
