@import '../var'

.title
  width: 208px
  min-height: 57px
  font-family: $font-secondary
  font-size: $title-size
  color: $secondary-color

  @media screen and (max-width: $mobile-breakpoint-i)
    font-size: $mobile-title-size

.title-sidebar
  width: 208px
  height: 57px
  font-family: $font-secondary
  font-size: $title-size
  color: $secondary-color

.section-title
  height: 19px
  font-family: $font-bold
  font-size: 14px
  color: $secondary-color
  text-transform: uppercase
  margin-bottom: 28px

  @media only screen and (max-width: $xlarge-breakpoint-o)
    margin-top: 46px

  @media only screen and (max-width: $mobile-breakpoint-i)
    text-align: left

.cadre-services
  .section-title
    font-size: 14px
  .grid-row
    padding: 0