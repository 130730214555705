
@import 'var'

.close
  font-size: 2rem
  width: 22px
  height: 22px
  margin-top: 24px
  margin-right: 20px

.modal-title
  width: 100%
  min-height: 32px
  .close.historique-close
    width: 32px
    height: 32px
    margin: 0
    padding: 0


.modal-content
  border: 0px
  border-radius: 16px

.modal-header
  border-bottom: none!important
  padding-bottom: 10px !important
  @media only screen and (max-width: $mobile-breakpoint-i)
    display: block
    padding-bottom: 0

.modal-footer
  border-top: none!important

  &.full-width
    display: block

.modal-large
  width: 900px
  max-width: 900px

  @media only screen and (max-width: 950px)
    max-width: 100%

  @media only screen and (max-width: $mobile-breakpoint-i)
    width: 103%
    margin: -3px
    margin-top: 88px
    border: none!important

  .modal-content
    border-radius: 8px !important

  .modal-body
    margin: 10px 50px 50px 50px

.modal-fullsize
  width: 100%
  height: 100%
  padding: 40px
  margin: 0
  max-width: initial
  max-height: 100vh

.gray-zone
  background: whitesmoke
  position: relative
  margin-top: -15px
  border-top-right-radius: 5px
  border-bottom-right-radius: 5px
  margin-bottom: -15px

  @media only screen and (max-width: $mobile-breakpoint-i)
    margin-top: 24px
    margin-left: 0

.title-popup
  height: 33px
  font-family: $font-secondary
  font-size: $primary-size
  color: $secondary-color
  float: left
  margin-bottom: 0px
  margin-top: 12px

  @media only screen and (max-width: $mobile-breakpoint-i)
    width: 100%
    float: none
    margin-top: 20px

#re-expedition
  .close
    right: 0
    position: absolute
    top: 0
    margin-top: 8px
    margin-right: 8px
  .title-popup
    float: none
  .main-title-popup
    font-size: 22px
  .cart-title
    margin-top: 24px
  .reexpedition-title
    margin-top: 40px
  .gray-text
    color: $grey-text
  .black-text
    color: $secondary-color

.dropdown-menu-popup
  width: 290px
  height: 35px
  border-radius: 4px
  border: solid 1px $color-border
  color: $secondary-color
  padding: 7px
  margin-top: 3px
  font-family: $font-primary
  font-size: $third-size
  color: $secondary-color
  float: right

  @media only screen and (max-width: $mobile-breakpoint-i)
    width: 100%

.dropdownadresse
  width: 290px !important
  padding: 20px
  .adr-popup
    font-family: $font-primary
    font-size: $third-size
    color: $secondary-color
  .ville-popup
    font-size: 11px
    color: $table-color
    margin-top: -10px
  .popup-element-plus
    font-size: 13px
    color: $primary-color
    margin-top: 20px
    a
      color: $primary-color

.dropdown-divider-popup
  width: 100%
  overflow: hidden
  border-top: 1px solid $color-border
  font-family: $font-primary

.lettre-bloc
  width: 269px
  margin-top: 40px
  margin-right: 22px
  float: left

  @media only screen and (max-width: $mobile-breakpoint-i)
    width: 99%


.title-lettre-bloc
  font-family: $font-secondary
  font-size: $primary-size
  color: $secondary-color
.right-popup
  float: right
  width: auto
  padding-top: 5px
.dte-inside
  float: left
  margin-right: 20px
  color: $table-color
  font-size: $third-size
  margin-top: -3px
.lettre-popup
  border: solid 1px $hove-color
.table-popup
  width: 100%
  font-size: $third-size
  color: $secondary-color
  tr
    height: 31px
    border-bottom: none!important
    td
      border-bottom: none!important

      span.already-queried
        margin-left: 10px
        color: $primary-color
  .price
    text-align: right
  .delete
    width: 35px
    text-align: right
    padding-bottom: 4px

  @media only screen and (max-width: $mobile-breakpoint-i)
    margin-top: 15px

.already-shipped-tooltip.tooltip
  .arrow::before
    border-bottom-color: $primary-color
  .tooltip-inner
    border-radius: 0
    background: $primary-color

.dte-table-popup
  font-size: $medium-size
  color: $table-color
.arrow-info
  margin-top: -2px
  margin-left: 10px

.flex-none
  display: block!important

.full-screen
  width: 100%
  height: 100vh
  max-width: 100%
  margin: 0
  top: 0
  left: 0
  z-index: 9999

.vertical-popup
  justify-content: space-between
  display: flex
  flex-direction: column
  height: 100%
  width: 100%
  padding-top: 25px
  @media only screen and (max-width: $mobile-breakpoint-i)
    padding-top: 0
    padding-bottom: 0

.resume-popup
  text-align: center
  .btn
    margin: 20px auto 0 auto

.left-popup
  float: left
  width: auto

.right-popup
  float: right
  width: auto

.padding-15
  padding-top: 15px
.popup-button
  margin-top: 30px!important
.popup-link
  font-size: $third-size
  color: $secondary-color
  text-align: center

.historique-header
  display: block !important
.historique-divider
  width: 97% !important
  margin: auto
.histo-num
  font-family: $font-primary
  font-size: $third-size
  color: $secondary-color
  width: 150px
  float: left

  @media only screen and (max-width: $mobile-breakpoint-i)
    float: none!important

.historique-cadre
  margin-top: 16px
  height: 118px

  @media only screen and (max-width: $mobile-breakpoint-i)
    margin-top: 16px
    height: auto
    border-bottom: 1px solid $color-border

.histo-dte
  font-family: $font-primary
  font-size: $third-size
  color: $table-color
.histo-content
  font-family: $font-primary
  font-size: 13px
  color: $secondary-color
  white-space: pre-line
.modal-height
  height: 560px
  overflow-y: auto

  @media only screen and (max-width: $mobile-breakpoint-i)
    height: auto
    border: none!important

.histo-name
  text-align: right

@media only screen and (max-width: $mobile-breakpoint-i)
  .modal-small
    margin-top: 88px


.success-msg
  font-family: $font-secondary
  font-size: 24px
  text-align: center
  color: #191D20
  margin-top: 20px
  font-weight: 700
.warning-msg
  font-family: $font-secondary
  font-size: 24px
  text-align: center
  color: $primary-color
  margin-top: 20px
.small-popup-content
  width: 85%
  margin: auto
  text-align: center

.popup-input
  font-family: $font-primary !important
  font-size: $third-size !important
  color: $table-color

.carte-add-input
  margin-top: 50px
  margin-bottom: 40px

.hidden-border
  border: none
  width: 100%
  padding-left: 30px

  @media only screen and (max-width: $mobile-breakpoint-i)
    width: 44%
    padding-left: 25px

.arrow-input
  position: absolute
  padding-left: 10px
  pointer-events: none
  z-index: 1
  padding-top: 9px

.arrow-msg
  padding-top: 12px !important

.dte-carte,
.cvc-carte
  float: left
  width: 110px

  @media only screen and (max-width: $mobile-breakpoint-i)
    width: 90px

.carte-group
  border: 1px solid $gray-color
  height: 35px
  border-radius: 5px
  width: 100%
.width100
  width: auto

  @media only screen and (max-width: $mobile-breakpoint-i)
    width: 100%

.nomargin
  margin-left: auto

  @media only screen and (max-width: $mobile-breakpoint-i)
    margin-left: 0px!important

.topbottom
  margin-top: auto
  margin-bottom: auto

  @media only screen and (max-width: $mobile-breakpoint-i)
    margin-top: -80px
    margin-bottom: -30px

.declare-icon
  position: absolute
  right: 20px
  top: 25px
  z-index: 999

.select-declare
  height: 208px !important
  border-radius: 4px
  border: solid 1px $hove-color
  margin-top: 1rem
  font-family: $font-secondary
  font-size: $third-size !important

.contact-box
  border: 1px solid $hove-color
  border-radius: 0.25rem
  padding: 5px
  float: left
  margin-right: 9px
  margin-bottom: 10px
  width: 47%

  .radio
    float: left
    padding: 10px

  @media only screen and (max-width: $mobile-breakpoint-i)
    border: 1px solid #f5f5f5
    border-radius: 0.25rem
    padding: 5px
    float: left
    margin-right: 9px
    margin-bottom: 20px

.padding-top
  padding-top: -30px
.label-radio-add
  padding: 6px
  text-align: -webkit-center
  color: rgba(40, 44, 46, 0.46)
  a
    font-family: $font-primary
    font-size: $third-size
.link-plus
  font-family: $font-primary
  font-size: $third-size
  color: $secondary-color
  margin-left: 16px
  margin-top: -19px
  z-index: 9999
.label-radio
  font-family: $font-primary
  font-size: $third-size
  color: $secondary-color!important
  margin-bottom: 0px

.label-radio-sub
  font-family: $font-primary
  font-size: $medium-size
  color: $table-color

.user-mails-share
  .radio
    width: auto
  .contact-box.contact
    input[type="radio"]:checked + label:before, input[type="radio"]:not(:checked) + label:before
      top: 10px
    input[type="radio"]:checked + label:after, input[type="radio"]:not(checked) + label:after
      top: 13px
  .custom.contact-box
    float: none
    width: 100%
    input
      width: 89%
      padding-left: 2rem
  .link-plus
    margin-top: 1rem
    text-decoration: underline
    display: block
    margin-left: 0
@media only screen and (max-width: $mobile-breakpoint-i)
  .user-mails-share
    .custom.contact-box
      input
        width: 78%
  .contact-box
    width: 100%
    .label-radio-sub
      float: none
      margin-top: 0

.forwarding-address
  margin-bottom: 2px
  @media only screen and (max-width: $mobile-breakpoint-i)
    margin-bottom: 2px

.adresse-popup
  font-family: $font-primary
  margin-bottom: 0px
.adr-ligne1
  font-size: $third-size
  color: $secondary-color
.adr-ligne2
  font-size: $medium-size
  color: $table-color

.alert-warning
  @media only screen and (max-width: $mobile-breakpoint-i)
    margin-top: -15px

.re-close
  @media only screen and (max-width: $mobile-breakpoint-i)
    right: 0px !important
    position: absolute
    top: -30px

.mail-card
  height: 60px
  line-height: 60px
  border-radius: 4px
  border: solid 1px #D7D7D7
  margin-bottom: 15px
  width: 50%
  vertical-align: middle

  .scan-container
    position: relative
    width: fit-content

  img
    float: left
    margin: auto

  .overlay
    z-index: 10
    position: absolute
    width: stretch
    height: stretch
    opacity: 0
    transition: none
    top: 0
    left: 0
    transform: none
    -ms-transform: none
    text-align: center

    .see
      width: inherit
      height: inherit

      svg
        position: absolute
