$font-color-1: #282C2E
$font-color-3: #75818C


.page-referrer
  margin: 0px 45px
  @media screen and (max-width: 1050px)
    margin: 0px 24px
  h1
    width: 50%
    font-weight: 600
    font-size: 24px
    line-height: 34px
    color: $font-color-1
    @media screen and (max-width: 1050px)
      width: 100%
  .referrer_top
    display: flex
    column-gap: 25px
    margin: 35px 0 58px 0
    @media screen and (max-width: 1050px)
      display: block
      column-gap: unset
    > div
      padding: 24px
      border-radius: 8px
    .referrer_share
      border: 1px solid #D3DBE1
      width: 359px
      @media screen and (max-width: 1050px)
        width: 100%
        margin-bottom: 34px
      h3
        font-size: 14px
        line-height: 21px
        font-weight: 600
        margin-bottom: 34px
      .referrer_summary
        padding: 12px 16px
        background: #E7F2FF
        border-radius: 6px
        margin-bottom: 16px
        display: flex
        column-gap: 32px
        .bloc
          .bloc-title
            color: $font-color-3
            font-size: 14px
            line-height: 21px
            font-weight: 400
            margin-bottom: 8px
          .bloc-content
            color: $font-color-1
            font-weight: 600
            font-size: 20px
            line-height: 28px

      .referrer_code
        padding: 12px 16px
        background: #F5F7FB
        border-radius: 6px
        .referrer_code_txt
          color: $font-color-3
          font-weight: 400
          font-size: 14px
          line-height: 21px
          display: inline-block
        .referrer_code_digit
          cursor: pointer
          display: inline-block
          padding: 0 30px 0 10px
        .referrer_code_icon
          display: inline-block
          cursor: pointer
          width: 24px
          height: 24px
          background-image: url('images/copy.svg')
          vertical-align: bottom
          &:hover
            background-image: url('images/copy-hover.svg')

    .referrer_rules
      background: #F5F7FB
      width: calc(100% - 359px - 25px)
      @media screen and (max-width: 1050px)
        width: 100%
        margin-bottom: 32px
      h3
        font-weight: 600
        font-size: 16px
        line-height: 24px
        color: $font-color-1
        margin-bottom: 16px
      p
        color: $font-color-1
        font-weight: 400
        font-size: 14px
        line-height: 21px
        margin: 0
      .referrer_condition
        color: #1576E1

  .referrer_overview
    h2
      font-size: 20px
      line-height: 28px
      color: $font-color-1
      font-weight: 700
      img
        margin-right: 28px
    .blocs
      border: 1px solid #D3DBE1
      padding: 24px
      border-radius: 8px
      display: flex
      column-gap: 32px
      flex-wrap: wrap
      @media screen and (max-width: 1050px)
        display: block
      .bloc
        width: calc(33% - 32px)
        padding-bottom: 24px
        @media screen and (max-width: 1050px)
          width: 100%
        .bloc-title
          color: $font-color-3
          font-size: 14px
          line-height: 21px
          font-weight: 400
          margin-bottom: 8px
        .bloc-content
          color: $font-color-1
          font-weight: 600
          font-size: 20px
          line-height: 28px
      .bloc-double
        width: calc(66% - 32px)

  .referrer_rewards
    margin-top: 50px
    h2
      font-size: 20px
      line-height: 28px
      color: $font-color-1
      font-weight: 700
      img
        margin-right: 28px

  .referees-outer
    .referees
      width: 100%
      thead
        background: #F5F7FB
      th, td
          font-size: 14px
          font-weight: 700px
          line-height: 21px
          padding: 15px
          color: $font-color-1
      td
        font-weight: 400px
      th
        font-weight: 700px
        border: none !important

.referees-outer .error-input, .modal .error-input
  margin-top: 6px
  color: #1576E1
  font-size: 10px
