@import '../var'

/* Second Nav sidebar */

.sidebar-customize
  background-color: #fafafa
  right: initial
  @media only screen and (max-width: $mobile-breakpoint-i)
    right: 0px

.root-sidebar-customize
  margin-left: $sidemenu-size
  margin-top: $topbar-height
  @media only screen and (max-width: $mobile-breakpoint-i)
    margin-left: 0px

.gray-sidebar-profile
  background: whitesmoke
  position: fixed
  top: $topbar-height
  margin-left: -40px
  width: 378px !important
  padding-left: 40px
  margin-right: 44px
  height: 100vh
  overflow-x: hidden
  overflow-y: auto

  @media screen and (min-width: $mobile-breakpoint-o) and (max-width: 946px)
    width: 282px !important
    height: 100%
    padding-left: 10px
    margin-right: 0px

    a.section-button
      padding: 9px 10px 10px 9px

  @media only screen and (max-width: $mobile-breakpoint-o)
    width: 100% !important
    margin: -10px
    height: 102vh
    position: fixed
    overflow-y: auto
    overflow-x: hidden

  .title-profil
    font-family: $font-secondary
    font-size: $large-size
    text-align: center
    color: $secondary-color
    text-transform: capitalize

  .profile-option
    padding: 60px 0px 60px 32px

  a.section-button
    margin-left: 20px

.gray-sidebar
  background-color: $gray-white

  .nav-element
    min-width: 220px
    height: 88px
    line-height: 88px
    font-family: $font-primary
    font-size: $third-size
    color: $secondary-color

    a, .disabled-nav-link
      display: block
      text-decoration: none
      color: #282c2e

      .inner-settings-nav
        margin: 0 26px 0 34px
        border-bottom: solid 1px $gray-border-color

        .nav-arrow-right
          width: 8.6px
          height: 9.4px
          float: right
          margin-top: 40px

    a
      &:hover,
      &.active
        color: #1576e1
        background: #e7f2ff

        img
          filter: invert(38%) sepia(67%) saturate(1385%) hue-rotate(185deg) brightness(91%) contrast(97%)

        .inner-settings-nav
          border-bottom: none

    .disabled-nav-link
      opacity: 0.5
      cursor: default
