@import var

.profile-container
  padding-bottom: 134px

  .profile-hint
    font-family: $font-primary
    font-size: $third-size
    color: $grey-text
    padding: 0 35px
    text-align: justify
    height: auto

    @media screen and (max-width: $mobile-breakpoint-i)
      height: auto

  .cadre-profil
    padding: 40px
    @media screen and (max-width: $xlarge-breakpoint-i)
      padding: 20px

    .profile-element
      font-family: $font-primary
      font-size: $third-size
      padding: 24px 0px
      border-bottom: 1px solid $gray-color
      display: flex
      justify-content: space-between
      align-items: center

      @media screen and (max-width: $mobile-breakpoint-i)
        display: block

        .info-label
          display: flex
          justify-content: space-between

      &:last-child
        border-bottom: none

      .info-value
        position: relative
        top: 2px
        margin-left: 5px
        @media screen and (max-width: $mobile-breakpoint-i)
          display: block
          text-align: center
          padding: 0px
          margin: 20px 0 0 0

        a
          color: $primary-color
          padding-left: 20px

      a:hover
        text-decoration: none

      .file-action-container
        svg[data-icon="eye"]
          font-size: $second-size
          color: $primary-color

        label
          color: $primary-color
          font-family: $font-primary
          cursor: pointer

        a.primary-button
          background-color: $primary-color
          color: $white-color
          display: inline-block
          &:hover
            text-decoration: none
            color: $white-color
            background-color: darken($primary-color, 15%)

        .recto-verso
          a
            padding: 0 0.5rem

        @media screen and (max-width: $mobile-breakpoint-i)
          width: 100%


  .li-profil
    font-family: $font-primary
    font-size: $third-size
    color: $secondary-color
    height: 29px
    line-height: 29px
    margin-bottom: 26px

    span
      padding: 10px 0px 0px 20px

    &.disabled
      .verticalLine
        border-color: $disabled-button

  .ul-btn
    position: relative
    margin-top: -2px
    width: auto

  .verticalLine
    position: relative
    height: 12px
    width: 1px
    border-left: 1px solid #2dcfb3
    left: 16px
    top: 9px

    &.disabled
      border-color: $disabled-button


  .after-btn
    top: 8px

  .download-buttons
    display: flex
    @media screen and (max-width: 1220px)
      flex-direction: column

  .download-contract-button
    padding: 60px 0px 12px 0px
    &.secondary
      margin-left: 58px
    @media screen and (max-width: 960px)
      .section-button
        padding: 6px 20px 10px 20px
    @media screen and (max-width: 1220px)
      padding: 15px 0px 12px 0px

  .profil-title
    font-family: $font-bold
    font-size: 14px
    color: $secondary-color
    text-transform: uppercase
    line-height: 30px
    margin-bottom: 15px
    @media screen and (max-width: 1220px)
      margin-bottom: 5px
    &.top-margin
      margin-bottom: 0

    &.little-title
      text-transform: none

    .info-shape
      margin-right: 24px

  .cadre-profil
    border-radius: 2px
    border: solid 2px #2dcfb3
    margin-top: 20px

  .bottom-5
    padding-bottom: 20px

    .top-20
      margin-top: 20px

  .no-font-weight
    font-weight: normal

  .alerts
    border-radius: 4px
    margin-left: 15px
    font-size: 11px
    padding: 2px 8px 4px 8px
    position: relative
    top: -2px
    height: 20px
    display: inline-block

  .valide-alert
    background-color: $success_color
    color: white

  .attente-alert
    background-color: $table-color
    color: white

  .notif-alert
    background-color: $primary-color
    color: white

  .warning-alert
    background-color: $table-color_2
    color: white

  .error-alert
    background-color: $red-color
    color: white

  .auto-width
    width: auto !important

  .arrow-right-aside
    width: 8px
    margin-left: 10px
    display: none


  .right-content-setting
    margin-left: 30%
    padding: 0px 36px
    display: grid

    @media screen and (min-width: 1480px)
      margin-left: 25%

    @media screen and (min-width: $mobile-breakpoint-o) and (max-width: $xlarge-breakpoint-i)
      margin-left: 40%


    .disabled
      .cadre-profil
        border-color: $gray-color

  .progress-circle
    span
      font-family: $font-secondary
      font-size: $large-size
      color: $secondary-color

  sup
    font-size: $default-size


  @media only screen and (max-width: $mobile-breakpoint-i)
    .desktop-imp
      display: none !important

    .leftdown
      padding-left: 26px !important

    .arrow-right-aside
      display: -webkit-inline-box

    .cadre-profil
      border: none !important
      padding: 0


.profile-container-mobile
  .profile-menu
    padding: 0px 16px 60px 16px

    h1.title-head
      margin-top: 50px
      font-family: $PublicoText-Bold
      font-weight: $weight-bold
      font-size: 24px
      padding-left: 35px

    .title-profil
      font-size: 24px
      text-align: center
      text-transform: capitalize
      padding: 28px 0px 46px 0px

    .li-profil
      a
        text-decoration: none

        &.section-button
          margin-left: 20px

  .postal_procuration_kbis_block
    padding: 0px 20px 80px 20px

  .informations-block
    padding: 50px 12px 80px 12px

  .legal-files-block
    padding: 0px 12px 80px 12px

#upload-modal
  .file-action-container
    label
      color: $primary-color
      font-family: $font-primary
      cursor: pointer
