@import '../var'

.dropdown-item,
button
  &:hover,
  &:focus
    outline: none

.dropdown-element
  height: 50px

.dropdown-item
  outline-color: transparent

  svg
    margin-top: -5px
    margin-left: -10px
    margin-right: 10px

.dropdown-item:active
  background: #f8f9fa

.dropdown-divider
  width: 80%
  margin: 5% 10% 5% 10%
