
@import 'var'
.nowidth
  width: 100%!important
.second-title
  font-family: $font-primary
  font-size: $sub-size
  text-align: center
  color: $table-color
.cadre-title
  font-family: $font-secondary
  font-size: $primary-size
  color: $secondary-color
.cadre-content
  font-family: $font-primary
  font-size: $third-size
  line-height: 1.54
  color: $table-color
.paddin-faq
  padding-bottom: 35px
  padding-top: 35px


@media only screen and (max-width: $mobile-breakpoint-i)
  .mobile-title-faq
    font-size: 35px
    margin-bottom: -5px
  .paddin-faq
    padding-bottom: 0px
    padding-top: 0px

