a[href^="http://maps.google.com/maps"]
  display: none !important

a[href^="https://maps.google.com/maps"]
  display: none !important

.gmnoprint a, .gmnoprint span, .gm-style-cc
  display: none

.gmnoprint div
  background: none !important

.gmap-container
  width: 100%
  height: 500px

.gmap-close
  background-color: white
  border-radius: 20px
  position: absolute
  width: 40px
  height: 40px
  right: 10px
  top: 10px
  z-index: 99999
  border: 3px solid #00000078

  button.close
    height: 100%
    width: 100%
    margin: 0
