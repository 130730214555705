@import 'var'

.section-map
  .location-container
    @media screen and (min-width: $mobile-breakpoint-o)
      width: 250px
      margin: auto

  .gmaps-example-container
    width: 100%
    margin-bottom: 10px
    cursor: pointer

  p
    font-family: $font-primary
    font-size: $third-size
    color: $secondary-color
    margin-bottom: 5px
    &.street
      margin: 22px 0 0 0
  .mobile-phone
    width: 12px
    height: 15px
    margin-right: 9px
  .space-bottom
    margin-bottom: 10px !important
  #icon-info
    color: $table-color
    a
      color: $table-color
      text-decoration: none
    a:hover
      text-decoration: underline

.rectangle-5
  text-align: center
  border-right: solid 1px $primary-gray
  margin-left: -10%
  margin-right: 10%

.bitmap
  display: block
  height: 100px
  padding-top: 30px

.next-facture
  font-family: $font-primary
  font-size: $third-size
  color: $secondary-color
  text-align: left

  .date
    margin-top: 5px
    color: $table-color

label.next-facture
  font-weight: bold
  margin-bottom: 15px

.label-copy
  font-family: $font-secondary
  font-size: $third-size
  color: $table-color
  text-align: left
  margin-bottom: 15px

.label-copy-2
  height: 18px
  font-family: $font-primary
  font-size: $third-size
  text-align: right
  color: $secondary-color
  float: right
  text-align: left
  padding-right: 21px

.notification-content
  margin-left: 15px
  &.parcel
    margin-left: 20px
    color: #dbb052
    font-size: 18px
    position: relative
    top: 2px
.notification-content-sidebar
  display: inline-block
  margin-left: 10px
  &.parcel
    margin-left: 10px
    top: 0

.profil-small-title
  font-family: $font-bold
  font-size: $second-size
  color: $secondary-color
  text-transform: uppercase

.profil-info
  padding: 35px

  @media only screen and (max-width: $mobile-breakpoint-i)
    position: absolute
    width: 100%

  p
    font-family: $font-primary
    font-size: $third-size
    color: $table-color
    width: 68%
    float: right


.mobile-center
  @media only screen and (max-width: $mobile-breakpoint-i)
    text-align: center
    margin-top: 35px


.rectangle-2
  @media only screen and (max-width: $mobile-breakpoint-i)
    width: 42.33%!important

.rectangle-4
  @media only screen and (max-width: $mobile-breakpoint-i)
    width: 42.33%!important

.rectangle-5
  @media only screen and (max-width: $mobile-breakpoint-i)
    margin-bottom: 25px
    margin-left: 0px!important
    margin-right: 0px!important
    border-right: none

.section-dashboard
  padding: 0 45px
  @media only screen and (max-width: $tablet-breakpoint)
    padding: 0 28px
  @media only screen and (max-width: $mobile-breakpoint-i)
    padding: 0 25px
    margin-bottom: 36px

  .progress-and-steps-container
    display: flex
    flex-wrap: wrap
    justify-content: flex-start
    align-items: center
    margin-top: 44px

    @media screen and (max-width: 1024px)
      justify-content: center

    .progress-circle-container
      text-align: left

      .progress-circle
        margin: 1rem 1rem 1rem 0

        span
          color: $secondary-color
          background-color: white

    .customer-complete-container
      font-size: $third-size
      color: $grey-text
      padding-left: 20px

      @media screen and (max-width: 1024px)
        padding-left: 0px
        text-align: center


      .steps-count
        padding-bottom: 10px
        strong
          color: $primary-color
          font-size: 20px

      a
        color: $grey-text
        font-size: $third-size
        text-decoration: underline

  .first-row
    @media screen and (min-width: $xlarge-breakpoint-o)
      padding-bottom: 20px

    @media screen and (max-width: $xlarge-breakpoint-i)
      margin-top: 0
      margin-bottom: 0
      padding-top: 0
      padding-bottom: 0

  .second-row
    @media screen and (max-width: $xlarge-breakpoint-i)
      margin-top: 0px
      padding-top: 0px

  .usermails-container

    a
      padding-bottom: 50px
      display: block

      @media screen and (max-width: $mobile-breakpoint-i)
        display: none

  .no-user-mails, .no-invoice
    text-align: center
    .msg
      font-family: $font-primary
      font-size: $third-size
      color: $secondary-color
      text-align: center
    img
      width: 100%
      max-width: 80px
      margin-bottom: 15px

  .cadre-services
    .row.nopadding
      padding: 0

    .grid-row
      display: flex

      .service-element
        padding: 0px 15px 15px 0px

        @media screen and (max-width: $mobile-breakpoint-i)
          padding: 0px 0px 15px 0px

      .rectangles-services
        width: 100%
        height: 100%
        display: block
        border-radius: 4px
        text-align: center
        margin: 0 10px 10px 0px
        border: solid 1px $primary-gray
        cursor: pointer
        flex-shrink: 2
        flex-grow: 2

        &.service-soon
            cursor: not-allowed
            opacity: 0.6

        &:hover:not(.service-soon)
          box-shadow: $shadow-menu
          background-color: $white-color
          text-decoration: none
          border: none

        @media only screen and (max-width: $mobile-breakpoint-i)
          margin: 9px auto

        .rectangle-title
          font-family: $font-primary
          font-size: $third-size
          color: $secondary-color
          text-decoration: none !important
          display: inline-block
          margin-top: 10px
          text-align: center
          max-width: 108px

  .subscription-current-container
    text-align: center

    h2
      text-align: left

    .subscription-inner
      @media screen and (max-width: $mobile-breakpoint-i)
        padding: 24px 24px 8px 24px
        border: 1px solid $primary-gray

      @media screen and (min-width: $mobile-breakpoint-o)
        max-width: 150px

      .subscription-current
        font-family: $font-bold
        text-transform: uppercase
        font-size: $small-size
        color: $actual-color
        border-radius: 4px
        background-color: $actual-color-2
        padding: 6px 0px 4px 0px
        display: block
        margin: 6px auto 20px auto
        width: 142px

      .section-title-2
        padding-bottom: 10px
        text-transform: uppercase
        font-family: $font-bold
        font-size: $second-size

  .last-invoices-container
    .last-invoices-outer
      border-left: 1px solid $primary-gray
      margin-left: -30px
      padding-left: 30px

      .facture
        width: 100%

        td
          border-bottom: none!important
          text-align: left
          vertical-align: top
          &.price
            min-width: 5.5rem

          &:first-child
            padding-bottom: 15px

          .download-img-icon
            margin-top: -6px

        .btn.download
          padding: 0
          width: 1.5rem
          height: 1.5rem
          margin-left: 1rem
          img
            margin-bottom: 0.4rem

      .go-to-all-invoices
        display: block
        text-align: center

  .location-container
    ul
      width: 150px
      margin-top: 10px
      padding-inline-start: 0px
    ul li
      font-size: 12px
      margin: 0
      color: #7b7e80
      display: flex
      flex-direction: row
      justify-content: space-between
    #openingHoursToggler
      font-size: 12px
      color: #7b7e80
      text-decoration: underline

.bitmap-2
  @media only screen and (max-width: $mobile-breakpoint-i)
    width: 115px

@media (min-width: 1600px)
  .col-xxl
    flex-basis: 0
    flex-grow: 1
    max-width: 100%

  .col-xxl-auto
    flex: 0 0 auto
    width: auto
    max-width: none

  .col-xxl-1
    flex: 0 0 8.33333%
    max-width: 8.33333%

  .col-xxl-2
    flex: 0 0 16.66667%
    max-width: 16.66667%

  .col-xxl-3
    flex: 0 0 25%
    max-width: 25%

  .col-xxl-4
    flex: 0 0 33.33333%
    max-width: 33.33333%

  .col-xxl-5
    flex: 0 0 41.66667%
    max-width: 41.66667%

  .col-xxl-6
    flex: 0 0 50%
    max-width: 50%

  .col-xxl-7
    flex: 0 0 58.33333%
    max-width: 58.33333%

  .col-xxl-8
    flex: 0 0 66.66667%
    max-width: 66.66667%

  .col-xxl-9
    flex: 0 0 75%
    max-width: 75%

  .col-xxl-10
    flex: 0 0 83.33333%
    max-width: 83.33333%

  .col-xxl-11
    flex: 0 0 91.66667%
    max-width: 91.66667%

  .col-xxl-12
    flex: 0 0 100%
    max-width: 100%

  .order-xxl-first
    order: -1

  .order-xxl-last
    order: 13

  .order-xxl-0
    order: 0

  .order-xxl-1
    order: 1

  .order-xxl-2
    order: 2

  .order-xxl-3
    order: 3

  .order-xxl-4
    order: 4

  .order-xxl-5
    order: 5

  .order-xxl-6
    order: 6

  .order-xxl-7
    order: 7

  .order-xxl-8
    order: 8

  .order-xxl-9
    order: 9

  .order-xxl-10
    order: 10

  .order-xxl-11
    order: 11

  .order-xxl-12
    order: 12

  .offset-xxl-0
    margin-left: 0

  .offset-xxl-1
    margin-left: 8.33333%

  .offset-xxl-2
    margin-left: 16.66667%

  .offset-xxl-3
    margin-left: 25%

  .offset-xxl-4
    margin-left: 33.33333%

  .offset-xxl-5
    margin-left: 41.66667%

  .offset-xxl-6
    margin-left: 50%

  .offset-xxl-7
    margin-left: 58.33333%

  .offset-xxl-8
    margin-left: 66.66667%

  .offset-xxl-9
    margin-left: 75%

  .offset-xxl-10
    margin-left: 83.33333%

  .offset-xxl-11
    margin-left: 91.66667%


.row.second-row > div
  margin-bottom: 30px

.subscription-inner
  margin: auto
  > h2
    text-align: center

@media screen and (max-width: 991px)
  .section-map .location-container, .subscription-inner
    margin: unset !important
  .subscription-inner
    > h2
      text-align: left

a:not([href]):not([tabindex])
  color: unset
