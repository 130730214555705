@import "../var"

.react-confirm-alert-body
  font-family: $font-primary

  button
    background-color: $primary-color
    color: white
    border-radius: 4px

.large-alert-popup
  height: $topbar-height
  position: absolute
  top: 0
  right: 0

.large-alert-popup.slide
  animation: large_show_slide 1s ease forwards

@keyframes large_show_slide
  0%
    transform: translateY(-100%)
  50%
    transform: translateY(-10%)
  100%
    transform: translateY(0%)

.large-alert-popup.hide
  animation: large_hide_slide 1s ease forwards

@keyframes large_hide_slide
  0%
    transform: translateY(0%)
  100%
    transform: translateY(-100%)

.successNotification
  background-color: #E7F5DC !important

.successNotificationWthDesc
  background-color: #E7F5DC !important
  .ant-notification-notice-message
    margin-bottom: 0 !important

.errorNotification
  background-color: #FFCDCC !important

.errorNotificationWthDesc
  background-color: #FFCDCC !important
  .ant-notification-notice-message
    margin-bottom: 0 !important
