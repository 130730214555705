@import 'var'

.regularize_modal
  position: fixed
  z-index: 1015
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: #F5F7FB
  overflow: auto
  padding: 24px
  .error-input
    color: #CD100C
  .content
    width: 95%
    margin: auto
    max-width: 1000px
  .progress
    height: 8px
    .progress-bar
      border-radius: 4px
  .boxes
    margin: 40px auto
    .box
      background: white
      border-radius: 6px
      padding: 24px
    .box-left
      width: calc(60% - 12px)
      float: left
      min-height: 192px
      @media screen and (max-width: $mobile-breakpoint-i)
        width: 100%
        float: unset
    .box-right
      float: right
      width: calc(40% - 12px)
      @media screen and (max-width: $mobile-breakpoint-i)
        width: 100%
        float: unset
        margin-top: 40px
      .continue-button
        margin-top: 48px
        text-align: center
        padding: 10px
        background: #1576E1
        border-radius: 4px
        color: white
        cursor: pointer
        text-decoration: none
        display: block

    &::after
      content: ""
      display: block
      clear: both

  .stripe-element
    padding: 12px 12px 10px 12px
    border: 1px solid #D3DBE1
    background: white
    border-radius: 8px
    width: 100%
    font-size: 16px
  .stripe-element-small
    padding: 12px 12px 10px 12px
    border: 1px solid #D3DBE1
    background: white
    border-radius: 8px
    width: 120px
    font-size: 16px
  input[type=checkbox]
    display: inline
    margin-right: 16px
