@import '../var'


// label
//   width: 139px
//   height: 18px
//   font-family: $font-secondary
//   font-size: $third-size
//   color: $secondary-color
//   width: 100%

.text-primary
  font-family: $font-secondary
  font-size: $medium-size
  color: $blue-color!important

.text-primary-drop
  font-family: $font-secondary
  font-size: $medium-size
  color: $blue-color!important

.text-warning
  font-family: $font-primary
  font-size: $medium-size
  color: $table-color_2

.text-success
  font-family: $font-primary
  font-size: $medium-size
  color: $success-color

.text-default
  font-family: $font-secondary
  color: $actual-color!important
  font-size: $medium-size

.text-alert
  font-family: $font-secondary
  color: $primary-color
  font-size: $medium-size


.info
  font-family: $font-primary
  font-size: $medium-size
  color: $secondary-color
  text-align: right

.upcase
  text-transform: uppercase

.blue
  color: $primary-color

.third-size
  font-size: $third-size
  font-family: "Open Sans", sans-serif

.light-text
  color: $table-color

a
  color: $secondary-color
  cursor: pointer

  &:disabled
    cursor: not-allowed

  .rectangle-title
    text-decoration: none

a:not([href]):not([tabindex])
  color: initial
  text-decoration: initial


