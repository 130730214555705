.progress-circle-container
  text-align: center
.progress-circle
  position: relative
  display: inline-block
  margin: 1rem
  width: 95px
  height: 95px
  border-radius: 50%
  background-color: rgb(188, 228, 222)
  &:after
    content: ''
    display: inline-block
    width: 100%
    height: 100%
    border-radius: 50%
    -webkit-animation: colorload 2s
    animation: colorload 2s

  sup
    top: -2px
    left: 6px

  span
    font-size: 1.5rem
    color: #2dcfb3
    position: absolute
    left: 50%
    top: 50%
    display: block
    width: 89px
    height: 89px
    line-height: 89px
    margin-left: -44px
    margin-top: -45px
    text-align: center
    border-radius: 50%
    background: #f5f5f5
    z-index: 1
    &:after
      content: ""
      font-weight: 600
      color: #2dcfb3
  &.progress-0:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(90deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-1:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(93.6deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-2:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(97.2deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-3:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(100.8deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-4:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(104.4deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-5:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(108deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-6:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(111.6deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-7:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(115.2deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-8:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(118.8deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-9:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(122.4deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-10:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(126deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-11:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(129.6deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-12:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(133.2deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-13:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(136.8deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-14:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(140.4deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-15:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(129.6deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-16:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(133.2deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-17:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(136.8deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-18:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(140.4deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-19:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(144deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-20:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(151.2deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-21:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(158.6deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-22:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(161.2deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-23:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(165.6deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-24:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(169.2deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-25:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(180deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-26:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(183.6deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-27:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(187.2deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-28:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(190.8deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-29:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(194.4deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-30:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(198deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-31:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(201.6deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-32:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(205.2deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-33:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(208.8deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-34:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(212.4deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-35:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(216deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-40:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(234deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-36:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(219.6deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-37:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(223.2deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-38:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(226.8deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-39:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(230.4deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-41:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(237.6deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-42:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(241.2deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-43:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(244.8deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-44:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(248.4deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-45:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(252deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-46:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(255.6deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-47:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(259.2deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-48:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(262.8deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-49:after
    background-image: linear-gradient(90deg, #dbf3ef 50%, transparent 50%, transparent), linear-gradient(266.4deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-50:after
    background-image: linear-gradient(-90deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-51:after
    background-image: linear-gradient(-86.4deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-52:after
    background-image: linear-gradient(-82.6deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-53:after
    background-image: linear-gradient(-79.2deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-54:after
    background-image: linear-gradient(-75.6deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-55:after
    background-image: linear-gradient(-72deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-56:after
    background-image: linear-gradient(-68.4deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-57:after
    background-image: linear-gradient(-64.8deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-58:after
    background-image: linear-gradient(-61.2deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-59:after
    background-image: linear-gradient(-57.6deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-60:after
    background-image: linear-gradient(-54deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-61:after
    background-image: linear-gradient(-50.4deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-62:after
    background-image: linear-gradient(-46.8deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-63:after
    background-image: linear-gradient(-43.2deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-64:after
    background-image: linear-gradient(-39.6deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-65:after
    background-image: linear-gradient(-36deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-66:after
    background-image: linear-gradient(-32.4deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-67:after
    background-image: linear-gradient(-28.8deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-68:after
    background-image: linear-gradient(-25.2deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-69:after
    background-image: linear-gradient(-21.6deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-70:after
    background-image: linear-gradient(-18deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-71:after
    background-image: linear-gradient(-14.4deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-72:after
    background-image: linear-gradient(-10.8deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-73:after
    background-image: linear-gradient(-7.2deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-74:after
    background-image: linear-gradient(-3.6deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-75:after
    background-image: linear-gradient(0deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-76:after
    background-image: linear-gradient(3.6deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-77:after
    background-image: linear-gradient(7.2deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-78:after
    background-image: linear-gradient(10.8deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-79:after
    background-image: linear-gradient(14.4deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-80:after
    background-image: linear-gradient(18deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-81:after
    background-image: linear-gradient(21.6deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-82:after
    background-image: linear-gradient(25.2deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-83:after
    background-image: linear-gradient(28.8deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-84:after
    background-image: linear-gradient(32.4deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-85:after
    background-image: linear-gradient(36deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-86:after
    background-image: linear-gradient(39.2deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-87:after
    background-image: linear-gradient(43.2deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-88:after
    background-image: linear-gradient(46.8deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-89:after
    background-image: linear-gradient(50.4deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-90:after
    background-image: linear-gradient(54deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-91:after
    background-image: linear-gradient(57.6deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-92:after
    background-image: linear-gradient(61.2deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-93:after
    background-image: linear-gradient(64.8deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-94:after
    background-image: linear-gradient(68.4deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-95:after
    background-image: linear-gradient(72deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-96:after
    background-image: linear-gradient(75.6deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-97:after
    background-image: linear-gradient(79.2deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-98:after
    background-image: linear-gradient(82.8deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-99:after
    background-image: linear-gradient(86.4deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)
  &.progress-100:after
    background-image: linear-gradient(90deg, #2dcfb3 50%, transparent 50%, transparent), linear-gradient(270deg, #2dcfb3 50%, #dbf3ef 50%, #dbf3ef)

@-webkit-keyframes colorload
  0%
    opacity: 0
    transform: rotate(0)

  100%
    opacity: 1
    transform: rotate(360deg)