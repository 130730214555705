@import "var"

.custom-select
  position: relative
  margin: 0
  border: none
  background: none
  padding: 0
  color: $table-color
  font-family: $font-primary
  height: 35px

  .field
    border: 1px solid $gray-color
    border-radius: 4px
    display: flex
    padding: 6px 8px 6px 12px
    cursor: pointer
    > .display
      height: 23px

    &.placeholder-option
      .display
        color: $gray-color

  &.no-border
    .field
      border: none

  &.with-question-mark
    .options
      box-shadow: 0px 14px 22px rgba(0, 0, 0, 0.1)
      margin-left: -1px
      margin-right: -1px

  &.open
    border: 1px solid #1576E1
    .field
      border-bottom-left-radius: 0
      border-bottom-right-radius: 0


  .display
    flex: 1

  .button
    align-items: center
    cursor: pointer
    display: flex
    font-size: 20px
    color: $gray-color

  .options
    background: white
    border: 1px solid $gray-color
    border-top: none
    border-bottom-left-radius: 4px
    border-bottom-right-radius: 4px
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25)
    left: 0
    position: absolute
    padding: 0
    right: 0
    top: 100%
    z-index: 3
    margin-top: 2px

  .option
    cursor: pointer
    padding: 14px 0px 12px 10px
    min-height: 42px
    border-bottom: 1px solid $gray-color

    &:hover
      background-color: #F9F9F9
      
    &:last-child
      border-bottom: none

  .overlay
    bottom: 0
    left: 0
    position: fixed
    right: 0
    top: 0
    z-index: 2