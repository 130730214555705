@import '../var'

.bottom-space-30
  margin-bottom: 30px

.full-border
  font-family: $font-primary
  font-size: $small-size
  color: $secondary-color
  border-bottom: 1px solid $primary-gray

.no-margin
  margin-top: 0 !important
  margin-bottom: 0 !important
  margin-left: 0 !important
  margin-right: 0 !important
.gray-background
  background: whitesmoke



  @media screen and (min-width: $mobile-breakpoint-o)
    margin-top: 140px


.mobile-setting-breadcrumb
  display: none

  @media screen and (max-width: $mobile-breakpoint-i)
    color: $secondary-color
    font-size: $large-size
    display: inline-block

.header-child-setting
  display: none
  .title-head
    margin-top: 20px
    line-height: 0.8
.sub-title
  font-size: 18px
  color: $table-color
  padding-top: 20px
  padding-left: 10px

.top-margin
  margin-top: 50px
  height: 45px

.left
  float: left
.margin-left
  margin-left: 30px
.margin-left10
  margin-left: 10px !important
.logo-brand
  width: 110px
.flow-root
  display: table
  width: 100%

.info-sub
  font-family: $font-primary
  font-size: $medium-size
  color: $table-color
  display: -webkit-box

.full-width
  width: 100% !important
.text-tag
  font-family: $font-primary
  font-size: $third-size
  color: #7b7e80
  height: 29px
  border-radius: 14.5px
  background-color: rgba(123, 126, 128, 0.1)
  float: left
  padding: 5px
  padding-left: 15px
  margin-top: 10px
  margin-right: 20px


.padding50
  padding: 40px
.spacer-mobile
  display: none
.width200
  width: 200px
.notif
  position: relative

@media only screen and (max-width: $mobile-breakpoint-o)
  .header-child-setting
    display: block
    width: 100%
    background: whitesmoke
    left: 0px
    padding: 10px 20px 20px 20px

    .setting-link-back
        padding: 20px 0 0 6px
        display: block
        height: auto
        img
          margin-right: 8px
  .right-content-setting
    margin-left: 0px
    margin-top: 209px
  .top-margin
    height: 70px
  .mobile-right
    float: right
    margin-left: 5px!important
  .double-bottom
    margin-bottom: 100px!important
  .flow-root
    height: 70px
  .carte-info-mobile
    float: left
    margin-top: -25px!important
    position: relative
    width: 130px
  .carte-height
    height: 100px
  .carte-height-small
    height: 80px
  .carte-paiement
    margin-left: 0px
  .margin-left10
    margin-left: 0px!important
  .padding50
    padding: 0px
    float: left
    margin-left: 30%
    padding-top: 30%
  .bloc-notification-large
    width: 72%
    margin-top: 10px
  .carte-group
    position: absolute
    width: 90%
  .spacer-mobile
    display: block
    padding: 20px
  .text-tag
    margin-top: 50px
    margin-bottom: -35px
    width: 100%
