
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700')
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Open+Sans:wght@600&display=swap')

@font-face
  font-family: 'AvenirLTStd-Roman'
  src: url('fonts/Avenir/37C428_0_0.eot')
  src: url('fonts/Avenir/37C428_0_0.eot?#iefix') format('embedded-opentype'), url('fonts/Avenir/37C428_0_0.woff2') format('woff2'), url('fonts/Avenir/37C428_0_0.woff') format('woff'), url('fonts/Avenir/37C428_0_0.ttf') format('truetype')


@font-face
  font-family: 'AvenirLTPro-Roman'
  src: url('fonts/Avenir/37C428_1_0.eot')
  src: url('fonts/Avenir/37C428_1_0.eot?#iefix') format('embedded-opentype'), url('fonts/Avenir/37C428_1_0.woff2') format('woff2'), url('fonts/Avenir/37C428_1_0.woff') format('woff'), url('fonts/Avenir/37C428_1_0.ttf') format('truetype')


@font-face
  font-family: 'AvenirLTStd-Heavy'
  src: url('fonts/Avenir/37C428_2_0.eot')
  src: url('fonts/Avenir/37C428_2_0.eot?#iefix') format('embedded-opentype'), url('fonts/Avenir/37C428_2_0.woff2') format('woff2'), url('fonts/Avenir/37C428_2_0.woff') format('woff'), url('fonts/Avenir/37C428_2_0.ttf') format('truetype')


@font-face
  font-family: 'AvenirLTPro-Heavy'
  src: url('fonts/Avenir/37C428_3_0.eot')
  src: url('fonts/Avenir/37C428_3_0.eot?#iefix') format('embedded-opentype'), url('fonts/Avenir/37C428_3_0.woff2') format('woff2'), url('fonts/Avenir/37C428_3_0.woff') format('woff'), url('fonts/Avenir/37C428_3_0.ttf') format('truetype')


@font-face
  font-family: 'AvenirLTPro-HeavyOblique'
  src: url('fonts/Avenir/37C428_4_0.eot')
  src: url('fonts/Avenir/37C428_4_0.eot?#iefix') format('embedded-opentype'), url('fonts/Avenir/37C428_4_0.woff2') format('woff2'), url('fonts/Avenir/37C428_4_0.woff') format('woff'), url('fonts/Avenir/37C428_4_0.ttf') format('truetype')


@font-face
  font-family: 'AvenirLTStd-HeavyOblique'
  src: url('fonts/Avenir/37C428_5_0.eot')
  src: url('fonts/Avenir/37C428_5_0.eot?#iefix') format('embedded-opentype'), url('fonts/Avenir/37C428_5_0.woff2') format('woff2'), url('fonts/Avenir/37C428_5_0.woff') format('woff'), url('fonts/Avenir/37C428_5_0.ttf') format('truetype')

@font-face
  font-family: 'Avenir-semibold'
  src: url('fonts/Avenir/AvenirNext-DemiBold.woff2') format('woff2')

@font-face
  font-family: 'PublicoText-Bold'
  src: url('fonts/Avenir/PublicoText-Bold-Web.woff2') format('woff2')

@font-face
  font-family: 'OpenSans-semibold'
  src: url('fonts/Avenir/opensans-semibold.woff2') format('woff2')