@import 'var'

.placeholder-option
  .display
    color: #a9abae !important
    opacity: 1

.form-control::placeholder
  color: #a9abae !important
  opacity: 1

.contact-message-modal
  .modal-header
    button.close
      font-size: 60px
      font-weight: 100
      opacity: 1
      position: absolute
      right: 42px
      top: 24px
      width: 36px
      margin: 0
      padding: 0
      height: auto
      width: auto
      z-index: 9999999

  .modal-body
    .support-description
      .support-description-element
        p
          strong
            color: #3477da

    .support-message-and-description
      margin: auto
      max-width: 980px

      .support-contact-message
        font-size: 15px
        p
          a
            color: #3477da
            text-decoration: underline

      .support-description
        .support-description-element
          font-size: 14px
          text-align: center

          &.support-phone
            img
              padding: 7px 0px

          strong
            color: $primary-color
    img
      margin: auto
      display: block

    h1
      font-family: $font-secondary
      font-size: 34px
      display: block
      text-align: center
      padding: 30px 0px

      @media screen and (max-width: $mobile-breakpoint-i)
        font-size: $mobile-title-size

    form
      max-width: 950px
      margin: auto

      label
        font-size: $primary-size
        font-family: $font-primary
        color: black
        padding: 8px 0px
        height: auto

      .customInputFile
        .custom-file-label
          text-align: center
          color: $primary-color

          &:after
            content: '+'
            height: 44px
            background-color: $primary-color
            color: white

      button[type='submit']
        background-color: $primary-color
        text-transform: uppercase
        color: $white-color
        font-size: 18px
        font-family: $font-primary
        border-radius: 4px
        padding: 14px 70px
        display: block
        margin: 40px auto
        border: none
        &:hover
          background-color: darken($primary-color, 15%)
        &:disabled
          background: $disabled
          border: 1px solid $disabled!important
