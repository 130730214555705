@import 'var'

@keyframes shrinkUserMailsSection
  0%
    width: calc(100% - 45px)
    padding-left: 45px
  100%
    width: calc(100% - #{$nav-menu-width})
    @media only screen and (max-width: $xlarge-breakpoint-o)
      padding-left: 10px

.opened
  width: calc(100% - #{$nav-menu-width})
  padding-left: 45px
  @media only screen and (min-width: $mobile-breakpoint-o)
    animation: 0.4s ease-out 0s 1 shrinkUserMailsSection
  @media only screen and (max-width: $xlarge-breakpoint-o)
    padding-left: 10px

@media only screen and (min-width: $mobile-breakpoint-o)
  .user-mails-section.closed
    width: calc(100% - 45px)
    padding: 0 0 0 45px

@keyframes slideInFromRight
  0%
    right: -($nav-menu-width + 38px)
  100%
    right: 0px

.nav__menu,
.nav__menu_2
  position: fixed
  top: 0
  width: $nav-menu-width
  height: 100%
  background: $gray-white
  transition: all 0.5s ease
  z-index: 1002
  padding-top: 149px
  padding-left: 48px
  overflow-x: hidden
  display: block
  overflow-y: scroll
  right: 0
  animation: 0.4s ease-out 0s 1 slideInFromRight

.menu__helper
  z-index: 1001
  position: fixed
  top: 0
  right: 0
  height: 100%
  width: 100%
  display: none

.gh11-sidebar
  width: 110%
.gh12-sidebar
  font-size: $primary-size
  margin: 44px 0px 20px 0px
.menuIcon
  margin-top: -5px!important
  margin-left: -10px!important
  margin-right: 10px
.plus
  width: 29px
  height: 20px
  border-radius: 4px
  border: solid 1px #bcbdbd
  float: right
  top: 0.7rem
  cursor: pointer
  position: relative
  .button
    height: 20px
    svg
      font-size: 18px
      position: absolute
      top: 0px
      left: 4px
      color: $secondary-color

.padding4
  padding-top: 4px

.dte
  font-family: $font-primary
  font-size: $third-size
  color: $table-color
  vertical-align: middle
  float: left
.categorie
  font-family: $font-primary
  font-size: $default-size
  font-weight: $weight-primary
  color: $actual-color
  border-radius: 12px
  background-color: $actual-color-2
  margin-left: auto
  margin-right: auto
  float: left
  vertical-align: middle
  line-height: 23px
  padding-left: 10px
  cursor: pointer

  .arrow-button
    margin-top: 7px

.oval
  float: left
  margin-top: 9px
  margin-left: 12px
  margin-right: 12px
  width: 3px
.arrow-button
  width: 6px
  float: right
  margin-right: 8px
  margin-top: 7px
.arrow-button-large
  width: 12px
  float: right
  margin-right: 15px
  margin-top: 0px
.cadre-1
  width: 100%
  height: 187px
  display: contents
  margin: 10px 0

// TODO: scope this
.overlay
  transition: .5s ease
  opacity: 0
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  -ms-transform: translate(-50%, -50%)
  text-align: center

.etape-courrier
  position: relative
  font-family: $font-primary
  font-size: $third-size
  color: $table-color

  .follow-user-mail
    color: $grey-text
    text-decoration: underline
    display: block
    padding-left: 28px

  .forwarding-date-expected,
  .sender-name
    padding-left: 30px

  .sender-name
    position: absolute
    font-weight: $weight-bold

    &::first-letter
      text-transform: capitalize

  .forwarding-date
    display: inline-block
    float: right

  .destruction-date-expected
    display: block
    float: none
    margin-left: -27px
    background-color: #fff8fa
    margin-right: -32px
    padding: 27px
    margin-top: 18px
    color: $red-color

  .access-and-hours-container
    padding-left: 28px

  .sidebar-clickable-link
    display: inline-block
    padding-left: 0px
    color: $grey-text
    text-decoration: underline


.icon-spacer
  padding: 6px
.etape-icon
  width: 18px
  height: 18px
  border-radius: 3.2px
  background-color: $table-background
  color: white
  padding-top: 4px
  text-align: center
  vertical-align: middle
  font-family: $font-secondary
  font-size: $medium-size
  color: $white-color
  margin-right: 12px
  margin-bottom: 0px !important

.select
  position: relative
  display: block
  overflow: hidden
  font-family: AvenirLTPro-Roman
  font-size: 10px
  color: #1576e1!important
  border-radius: 12px
  background-color: #e4f6ff!important
  margin-left: auto
  margin-right: auto
  float: left
  width: 84px
  height: 20px
  vertical-align: middle
  line-height: 20px
  padding-left: 10px
.select
  &::after
    content: '\25BC'
    position: absolute
    top: 0
    right: 0
    bottom: 0
    padding: 0 1em
    background: #e4f6ff!important
    pointer-events: none
  &:hover::after
    color: #1576e1!important
  &::after
    -webkit-transition: .25s all ease
    -o-transition: .25s all ease
    transition: .25s all ease

.user-mails-sidebar
  .dropdown
    .plus.button
      color: $secondary-color

#submenu-item
  font-family: $font-primary
  font-size: $third-size
  color: $secondary-color
  height: 32px
  line-height: 32px
  vertical-align: middle
  text-decoration: none
  background-color: red
.dropdown-item-plus
  font-family: $font-primary
  font-size: $third-size
  line-height: 35px
  height: 42px!important
  cursor: pointer
.dropdown-item-plus:hover
  background-color: $hove-color !important

.tools-hovers
  display: none
  img
    cursor: pointer
    float: right
    margin: 8px 5px 0px 5px
  .loader-destroy
    float: right
    margin: 8px 5px 0px 5px
    position: relative
.text-left
  float: left
  line-height: 35px
.sub-hover
  height: 35px
.sub-hover:active
  background-color: $hove-color!important
.sub-hover:hover > .tools-hovers
  display: block !important

.sub-hover
  &:hover ~
    .tools-hovers
      display: block

.sub-first
  height: 41px!important
.sub-first:hover
  background-color: $white-color!important

.input-plus
  height: 31px
  border-radius: 4px
  border: solid 1px $gray-border-color
  font-family: $font-primary
  font-size: $medium-size

#input-plus-group
  button[type=submit]
    cursor: pointer

.icon-button-input
  position: absolute
  right: 10px
  top: 9px

.dropdown-cadre
  width: 189px !important
  height: 189px!important

.header_sidebar
  display: none !important

.user-mails-sidebar

  .sidebar-row
    img.default
      display: block

  @media screen and (min-width: $mobile-breakpoint-o)
    .return-back
      display: none

  .btn
    margin-top: 15px
  .right
    margin-right: 0
    line-height: 23px
  .dte
    color: $secondary-color
  .oval
    margin-top: 6px
  .dropdown-tag
    width: auto
    .tag-name
      margin-right: 25px
      font-size: $default-size
    .dropdown-item
      min-width: 200px
      padding-left: 1.6rem
      padding-right: 1rem

      &:nth-child(2)
        margin-top: 16px

      &:first-child
        padding-left: 1rem

      .form-edit
        width: 100%

        button.edit
          svg.fa-check
            margin-right: 0px
    form
      width: 100%
      position: relative
      .loader
        position: absolute
        right: 4px
        top: 9px
      button
        background: transparent
        border: none
        position: absolute
        right: 0
        line-height: 40px
        cursor: pointer
        padding-top: 0px
    .msg
      font-size: 13px
      color: $secondary-color

@media only screen and (max-width: $mobile-breakpoint-i)
  .header_sidebar
    display: block !important
    position: absolute
    background: white
    width: 112%
    margin-top: -100px
    margin-left: -13%
    padding-top: 100px
    padding-left: 30px
  .group-botton
    margin-top: 130px

#re-expedition
  .lettre
    margin: 8px 15px
    height: 44px
    display: block
    @media only screen and (max-width: $mobile-breakpoint-i)
      height: 125px
  .error
    clear: both
    background-color: rgba(255, 195, 111, 0.15)
    color: $warning-color
    font-size: 13px
    padding: 1rem

  .remove-user-mail-button
    cursor: pointer

@media only screen and (min-width: $mobile-breakpoint-o)
  .hidden-desktop
    display: none
