.uppercase
  text-transform: uppercase

.capitalize
  text-transform: capitalize !important

.chevron::before
  border-style: solid
  border-width: 0.1rem 0.1rem 0 0
  content: ''
  display: inline-block
  height: 0.45em
  left: 0.8rem
  position: relative
  top: 0.15em
  transform: rotate(-45deg)
  margin-top: 8px
  width: 0.45em
  vertical-align: top

.chevron.right:before
  left: 0
  transform: rotate(45deg)

.chevron.bottom:before
  top: 0
  transform: rotate(135deg)

.chevron.left:before
  left: 0.25em
  transform: rotate(-135deg)

.nopadding
  padding-top: 0
  padding-bottom: 0
  padding-left: 0
  padding-right: 0

// // // .hidden
// // //   display: none

.fullscreen-container-loader
  height: 60vh

.common-loader
  height: 100%
  display: flex
  justify-content: center
  align-items: center
  flex-wrap: wrap

.partial-loader
  display: flex
  justify-content: center
  align-content: center
  flex-wrap: wrap
