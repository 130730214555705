
@import 'var'
.subscription-current-white
  width: 139px
  height: 18px
  border-radius: 4px
  border: solid 1px rgba(40, 44, 46, 0.2)
  background-color: $white-color
  font-family: $font-bold
  font-size: $x-small-size
  color: $table-color
  text-align: center
  padding: 3px
  margin: auto
.special-top
  margin-top: 0px!important
.special-top-2
  margin-top: 15px!important
.delete-side-margin
  margin-left: 0px!important
  margin-right: 0px!important
.no-border
  border-right: 0px !important
.with-border
  border: solid 1px rgba(40, 44, 46, 0.2)
  border-radius: 4px
  width: 100%
  margin-top: -9px
  padding-top: 40px
.padding15
  margin-left: 25px !important
.padding-full
  padding: 30px
.inline-white
  display: inline-block
  background: white!important
  width: 104%
  margin-left: -10px
  margin-right: -9px

.abonnement-ul
  padding-left: 15px!important
  color: $secondary-color
  li
    color: $secondary-color
    font-family: $font-primary
    font-size: $third-size
    text-align: left
    padding: 10px
.icon-li
  width: 10.8px
  height: 11.2px
  margin-right: 10px
.gray-color
  color: $table-color
.sub-li
  margin-left: 23px
  font-family: $font-primary
  font-size: $medium-size
  color: $actual-color
.inside-line-abn
  margin-top: 30px
  border-bottom: 1px solid #ececec
  margin-bottom: 30px
.full-width-30
  width: 32%
.section-p
  color: $secondary-color
  font-family: $font-primary
  font-size: $third-size
.side-title
  margin-top: 156px
  font-family: $font-secondary
  font-size: $large-size
  color: $secondary-color

.side-title-2
  margin-top: 69px
  font-family: $font-secondary
  font-size: $large-size
  color: $secondary-color
.cadre-side
  width: 435px
  height: 351px
  border-radius: 2px
  padding: 30px
  color: white
  background-repeat: no-repeat
  background-position: 100% 30px
.green-side
  background-color: $success-color
  background-image: url('images/house-icon-green.svg')
.red-side
  background-color: $primary-color
  background-image: url('images/building-icon.svg')
.violet-side
  background-color: $violet-color
  background-image: url('images/violet-building.svg')
.price-side
  font-family: $font-secondary
  font-size: $title-size
  span
    font-size: 21px
.white
  color: $white-color !important
.aside-ul
  padding-left: 0px!important
  li
    padding: 3px
    color: $white-color !important
.success-btn
  color: $success-color
  box-shadow: 0 10px 19px -8px rgba(0, 0, 0, 0.32)
.alert-btn
  color: $primary-color
  box-shadow: 0 10px 19px -8px rgba(0, 0, 0, 0.32)
.violet-btn
  color: $violet-color
  box-shadow: 0 10px 19px -8px rgba(0, 0, 0, 0.32)
.house-icon-large
  position: absolute
  right: -21px
.large-pic-1
  width: 251px
  height: 190px
.large-pic-2
  width: 251px
  height: 240px
.large-pic-3
  width: 251px
  height: 257px
.pic-abonnement
  img
    margin: 5px
.abonnement2
  border-left: 1px solid #ececec
  padding-top: 5px
.margin-auto
  margin: auto
  height: auto !important
.full-width-abn
  width: 31%
  margin-left: 15px

@media only screen and (max-width: $mobile-breakpoint-i)
  .cadre-color
    width: 100% 
    height: auto
  .cadre-color-2
    width: 90% 
    height: auto
    margin-bottom: 15px
  .pic-abonnement
    display: none
  .side-title
    margin-top: -50px
    margin-bottom: 30px
  .side-title-2
    margin-top: 0px
  .with-border
    margin-left: 0px
  .padding-full
    padding: 20px
    margin-top: -40px
  .full-width-30
    width: 100%
    margin-left: -11px
  .label-radio-sub
    float: right
    margin-top: -22px
  .btn-mobile-large
    margin-left: 15px !important
    width: 90%
    margin-top: 18px
  .section-p
    color: $table-color
  .cadre-abn-2
    margin-top: 30px
    border-top: 1px solid #ececec
    padding-top: 30px

    