@import 'var'

#root:not(:empty) + #initialLoader
  display: none

#initialLoader
  background-color: white
  height: 100%
  width: 100%
  position: absolute
  display: flex
  justify-content: center

.loader-container
  width: 36px
  font-size: 0

.loader-element
  display: inline-block
  background-color: #1576E1
  width: 8px
  height: 8px
  margin: 2px
  border-radius: 100%
  -webkit-animation-fill-mode: 'both'
  animation-fill-mode: 'both'

.loader-el1
  -webkit-animation: animation-1ik4pk7 0.6483112629525832s -0.15168873704741676s infinite ease
  animation: animation-1ik4pk7 0.6483112629525832s -0.15168873704741676s infinite ease

.loader-el2
  -webkit-animation: animation-1ik4pk7 1.5362121148582846s 0.7362121148582847s infinite ease
  animation: animation-1ik4pk7 1.5362121148582846s 0.7362121148582847s infinite ease

.loader-el3
  -webkit-animation: animation-1ik4pk7 1.5545496894710409s 0.7545496894710408s infinite ease
  animation: animation-1ik4pk7 1.5545496894710409s 0.7545496894710408s infinite ease

.loader-el4
  -webkit-animation: animation-1ik4pk7 1.171954385975127s 0.3719543859751268s infinite ease
  animation: animation-1ik4pk7 1.171954385975127s 0.3719543859751268s infinite ease

.loader-el5
  -webkit-animation: animation-1ik4pk7 1.5885091312318655s 0.7885091312318657s infinite ease
  animation: animation-1ik4pk7 1.5885091312318655s 0.7885091312318657s infinite ease

.loader-el6
  -webkit-animation: animation-1ik4pk7 1.4400903607060538s 0.6400903607060537s infinite ease
  animation: animation-1ik4pk7 1.4400903607060538s 0.6400903607060537s infinite ease

.loader-el7
  -webkit-animation: animation-1ik4pk7 0.9037525899060944s 0.10375258990609443s infinite ease
  animation: animation-1ik4pk7 0.9037525899060944s 0.10375258990609443s infinite ease

.loader-el8
  -webkit-animation: animation-1ik4pk7 1.4829037513772008s 0.6829037513772007s infinite ease
  animation: animation-1ik4pk7 1.4829037513772008s 0.6829037513772007s infinite ease

.loader-el9
  -webkit-animation: animation-1ik4pk7 1.1718858528999503s 0.3718858528999504s infinite ease
  animation: animation-1ik4pk7 1.1718858528999503s 0.3718858528999504s infinite ease
