@import 'var'

.slogan
  font-family: $font-primary
  font-size: $sub-size
  color: $table-color
  margin-top: -15px
.full-height
  height: 100vh !important

  @media screen and (max-width: $mobile-breakpoint-i)
    height: auto !important

.bureaux-cadre
  min-height: 182px
  margin: auto
.info-text
  font-family: $font-bold
  font-size: $small-size
  color: $table-color
  text-transform: uppercase
  width: 100%
  &.info-text-light
    color: $scroll
.title-cadre
  font-family: $font-secondary
  font-size: $large-size
  color: $secondary-color
  width: 100%
  padding-top: 5px
  padding-bottom: 8px
.text-align-left
  text-align: left !important
.cadre-margin-top
  margin-top: 50px
.oval-cadre
  float: none !important
  margin-top: -3px !important
.fixed-right
  position: fixed
  right: 0
  padding: 60px
.top-aside
  margin-top: 30px

.label-btn
  width: 150px

.nav__menu_filtre
  position: fixed
  right: -477px
  top: 0
  width: 477px
  max-width: 30%
  height: 100%
  background: $gray-white
  transition: all 0.5s ease
  z-index: 1002
  padding-top: 70px
  padding-left: 48px
  overflow-x: hidden
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1)

  h4
    padding-left: 15px

.question-form-gray
  font-family: $font-primary
  font-size: $sub-size
  color: $secondary-color
  margin-top: -20px

.icon-select
  position: absolute
  right: 40px
  margin-top: -15px !important
  z-index: 9999

  @media only screen and (max-width: $mobile-breakpoint-i)
    margin-top: 11px !important

.icon-select2
  position: absolute
  right: 40px
  margin-top: 10px !important
  z-index: 2

  @media only screen and (max-width: $mobile-breakpoint-i)
    margin-top: 11px !important

.interval-cadre
  height: 170px
.filtre-close
  position: absolute
  right: 30px
  top: 0px
  opacity: 1
  z-index: 99999
  @media only screen and (max-width: $mobile-breakpoint-i)
    right: 0px
    top: -15px

.filtre-close-arrow
  width: 40px
  height: 40px
.under-top
  margin-top: -60px !important
.align-right
  text-align: right
.width100-special-btn
  width: 90%
  margin-left: 35px
  padding: 15px
.no-padding
  padding-right: 0px
  padding-left: 0px
.spacer-3
  padding: 15px
.inside-input
  font-family: $font-primary !important
  font-size: $sub-size !important
  color: $table-color !important
.width100-special-btn-2
  width: 96%
  margin-left: 17px
  padding: 24px
  height: auto!important
.width100-special-btn-3
  width: 100%
  padding: 24px !important
  height: auto!important
.cadre-radio
  width: 280px
  height: 228px
  border-radius: 2px
  border: solid 1px $gray-color
  text-align: center
  padding: 20px
  margin: auto
.title-radio
  font-family: $font-bold
  font-size: $second-size
  color: $table-color
  text-transform: uppercase
  margin-top: 10px
.no-height
  height: auto !important
.subtitle-cadre
  font-family: $font-primary
  font-size: $third-size
  color: $table-color!important
.input-radio-center
  width: 18px
  margin: auto
  margin-top: 5px

.width98
  width: 98%
  margin: auto
.radio-active
  border: solid 1px $primary-color
.top30-desktop
  margin-top: 30px
.style-strong
  font-size: $sub-size !important
  font-family: $font-primary !important
  color: $table-color !important
.text-area
  height: 165px
.fullscreen
  width: 100%!important
  max-width: 100%!important
  margin: 0px
  top: -1px
  left: -1px
  .modal-content
    min-height: 100vh
  .close
    z-index: 10002
    position: absolute
    right: 30px
.list-rooms
  .bureaux-cadre .row:hover
    background-color: $primary-gray
    cursor: pointer

.carousel-room
    .carousel-item
      width: 100%

@media only screen and (min-width: $mobile-breakpoint-i)
  #cliquable-filtre
    &:checked ~
      .nav__menu_filtre
        display: block
        overflow-y: scroll
        right: 0
      .full-padding
        width: 62% !important
        -webkit-transition: width 2s
        transition: width 0.4s
        margin-left: 3%
    &:not(:checked) ~
      .nav__menu_filtre
        display: block
        overflow-y: scroll
        right: 0
      .full-padding
        width: 62% !important
        -webkit-transition: width 2s
        transition: width 0.4s
        margin-left: 3%

@media only screen and (max-width: $mobile-breakpoint-i)
  #cliquable-filtre
    &:checked ~
      .nav__menu_filtre
        display: block
        overflow-y: scroll
        right: 0
        width: 100%
        max-width: 100%
    &:not(:checked) ~
      .full-padding
        width: 100%
        -webkit-transition: width 2s
        transition: width 0.5s

  .bureaux-cadre
    height: auto
  .title-cadre
    padding-bottom: 0px!important
  .cadre-margin-top
    margin-top: 0px
    margin-bottom: 36px

  .mobile-info-left
    padding: 0 30px
  .mobile-info-right
    margin-top: 1rem
    padding: 0 30px
  .room.bureaux-cadre
    .mobile-info-right .title-cadre
      padding-top: 15px !important
      border-top: 1px solid #ececec
  .service-fullscreen
    .submit.section-button
      width: 100%
      padding: 24px
  .label-btn
    width: 150px !important
  .under-bottom
    margin-bottom: -50px
  .no-float
    float: none !important
  .question-form-gray
    margin-top: 10px
  .top30
    margin-top: 30px
  .top60
    margin-top: -60px
  .width100-special-btn
    margin-left: 15px
  .width100-special-btn-2
    width: 90%
  .size-mobile
    font-size: 35px
  .relative-text
    position: relative
    width: 140%
  .spacer-3
    display: none
  .width98
    width: 89%
  .width100-special-btn-3
    padding: 12px
    margin-top: -70px
  .fullscreen
    width: 102% !important
    max-width: 102% !important
    margin: 0px
    height: 100vh !important
    top: 70px
    left: -1px
    .modal-content
      height: auto

