@import 'var'

.hotline-form
  max-width: 660px
  margin: auto

  .slogan
    margin-top: -4px

  .primary-link
    font-family: $font-bold
  textarea::placeholder
    font-size: $medium-size