@import '../var'

table
  margin-bottom: 1rem
  background-color: transparent
  font-family: $font-primary
  font-size: $table-size
  td, th
    border-top: none!important
    border-bottom: 1px solid $color-border!important

.content-table
  color: $table-color
  font-family: $font-primary
  font-size: $third-size

.hover-tr
  cursor: pointer

.first-col
  color: $secondary-color
  font-family: $font-primary
  font-size: $second-size

  @media only screen and (max-width: $mobile-breakpoint-i)
    padding-left: 7%!important