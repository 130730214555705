
/* font */
$font-primary: 'Open Sans', sans-serif
$font-secondary: AvenirLTPro-Roman
$font-semiBold: 'OpenSans-semibold'
$font-bold: AvenirLTPro-Heavy
$PublicoText-Bold: PublicoText-Bold
$weight-primary: normal
$weight-default: 400
$weight-bold: 700
$mobile-breakpoint-i: 900px
$mobile-breakpoint-o: 901px
$tablet-breakpoint: 1024px
$xlarge-breakpoint-i: 1199px
$xlarge-breakpoint-o: 1200px

@mixin font-primary-bold
  font-family: $font-primary
  font-weight: $weight-bold

@mixin red-error-cross
  background-image: url('images/times-regular.svg')
  background-repeat: no-repeat
  background-position: calc( 100% - 10px ) 8px
  background-size: 12px

/* color */
$primary-color: #1576E1
$hover-color: #0e519b
$secondary-color: #282c2e
$violet-color: #311e40
$gray-color: #dddddd
$active-color: rgba(255, 255, 255, 0.2)
$notification-color: lighten($primary-color, 5%)
$white-color: #ffffff
$side-menu-color: #000F46
$gray-menu: #282c2e
$shadow-menu: 0 5px 20px 0 rgba(0, 0, 0, 0.1)
$primary-gray: #ececec
$color-hover: darken($primary-color, 5%)
$blue-color: #2a5063
$actual-color: #1576e1
$table-color: #7b7e80
$grey-text: #7b7e80
$table-color_2: #ffc36f
$yellow-color: #ffc36f
$red-color: #eb4968
$black-color: #191D20
$color-border: #dee2e6
$actual-color-2: #e4f6ff
$primary_opacity_3: #fff8fa
$hove-color: #f5f5f5
$gray-white: #fafafa
$table-background: #311e41
$warning-color: #d2800d
$warning-a-color: #b16700
$scroll: #bdbdbd
$success-color: #2dcfb3
$disabled: #d0d1d1
$gray-border-color: #f0efef
$gray-border-semi-transparent: rgba(123, 126, 128, 0.57)
$disabled-button: rgba(123, 126, 128, 0.3)
$error-color: red
$darker-error-color: #CD100C
$light-blue: #F5F7FB
/* text size */

$error-validation: 11px
$x-small-size: 8px
$small-size: 10px
$medium-size: 11px
$default-size: 12px
$third-size: 13px
$second-size: 14px
$table-size: 15px
$sub-size: 16px
$primary-size: 18px
$large-size: 24px
$mobile-title-size: 35px
$small-title-size: 35px
$title-size: 42px
$sidemenu-size: 232px
$topbar-height: 70px

/* utils
$box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1)
$nav-menu-width: 406px




