.cancelation_procedure
  *
    font-family: 'Inter', 'Open Sans'
    max-width: 650px
  h2
    font-style: normal
    font-weight: 600
    font-size: 18px
    line-height: 24px

  .ant-form-item
    max-width: 456px
  .ant-form-item-label
    label
      color: #191D20 !important
      font-style: normal !important
      font-weight: 600 !important
      font-size: 16px !important
      line-height: 150% !important
  .ant-form-item-required
    &::before
      display: none !important
  .ant-select-selector
    height: 55px !important
    border: 1px solid #D3D7DA !important
    border-radius: 8px !important
    padding: 14px !important
    .ant-select-selection-item
      font-style: normal
      font-weight: 400
      font-size: 14px
      line-height: 150%
  .ant-input
    height: 55px !important
    border: 1px solid #D3D7DA !important
    border-radius: 8px !important
    padding: 14px !important
    font-style: normal
    font-weight: 400
    font-size: 14px
    line-height: 150%
  .ant-form-item-extra
    background: #F5F7FB
    border-radius: 8px
    padding: 10px 10px 10px 44px
    position: relative
    font-style: normal
    font-weight: 400
    font-size: 10px
    line-height: 150%
    margin: 5px 0
    &::before
      content: 'i'
      background: #1576E1
      position: absolute
      top: 12px
      left: 12px
      color: white
      border-radius: 100%
      height: 20px
      width: 20px
      text-align: center
      padding-top: 2px
      font-size: 11px
      font-weight: 400
  .ant-radio-wrapper
    margin-bottom: 8px
    font-size: 16px
  .form-item-submit
    margin-top: 50px

    &.flex-right .ant-form-item-control
      align-items: flex-end

  .ant-upload
    width: 100%
    button
      width: 100%
      height: 49px !important
      border: 1px solid #D3D7DA !important
      border-radius: 8px !important
      padding: 14px !important
      font-style: normal
      font-weight: 400
      font-size: 14px
      line-height: 150%
      color: #6C767F
      position: relative
      padding-right: 40px !important
      text-align: left
    .anticon-upload
      color: #1576E1
      position: absolute
      right: 10px
      font-size: 20px
      top: 12px
  .upsell
    padding: 26px 168px 26px 26px
    border: 1px solid #D3DBE1
    border-radius: 8px
    margin-top: 27px
    margin-bottom: 15px
    position: relative
    h3
      font-style: normal
      font-weight: 700
      font-size: 24px
      line-height: 140%
    .price
      font-style: normal
      font-weight: 600
      font-size: 20px
      line-height: 140%
      margin-bottom: 21px
    .description
      font-style: normal
      font-weight: 400
      font-size: 14px
      line-height: 150%
      color: #6C767F
    img
      position: absolute
      right: 26px
      top: 26px
  .help
    background: #F5F7FB
    border-radius: 8px
    padding: 18px 120px 18px 24px
    margin-bottom: 48px
    position: relative
    h4
      font-style: normal
      font-weight: 600
      font-size: 14px
      line-height: 150%
      color: #171A1C
    p
      font-style: normal
      font-weight: 400
      font-size: 14px
      line-height: 150%
      color: #6C767F
    &.warning
      background: #FFE4ED
      &::after
        content: '!'
        background: #FB82A9
    &::after
      content: '✔'
      color: white
      background: #1576E1
      height: 32px
      width: 32px
      border-radius: 100%
      position: absolute
      top: 15px
      right: 23px
      text-align: center
      font-size: 16px
      padding-top: 3px
  .ant-form-item-control
    display: flex
    flex-direction: column
    > div
      order: 5
    .ant-form-item-control-input
      order: 2
    .ant-form-item-extra
      order: 3
  .input-with-info
    .ant-form-item-extra
      order: 1
  .ant-btn-primary
    background: #1576E1
    border-radius: 8px
    font-weight: 600
    font-size: 15px
    line-height: 125%
    text-align: center
    color: #FFFFFF
    padding: 14px 24px
    height: auto
  .ant-btn-default
    background: white
    border-radius: 8px
    font-weight: 600
    font-size: 15px
    line-height: 125%
    text-align: center
    color: #1576E1
    padding: 14px 24px
    height: auto
    border: 1px solid #1576E1
  .btns
    display: flex
    flex-direction: row
    gap: 20px
    justify-content: center
    button
      flex-grow: 1
@media(max-width: 1000px)
  .btns
    display: block !important
    gap: 20px !important
    .ant-btn
      width: 100% !important
      margin-bottom: 16px !important
