@import 'var'

.contact-of-sharing-container
  h2.subtitle-sidebar 
    @media screen and (max-width: $mobile-breakpoint-i)
      margin-bottom: 20px

  h3
    font-size: 18px
    font-family: $font-secondary
    margin-top: 56px

  p.desc
    font-size: 13px
    padding-bottom: 14px

  .add-button
    text-align: right
    @media screen and (max-width: $mobile-breakpoint-i)
      margin-top: 30px
      text-align: center

  .contact-of-sharing-element
    margin-top: 34px
    padding-bottom: 12px
    .contact-full-name
      min-width: 30%
      text-transform: capitalize
    .contact-category
      background-color: rgba(21, 118, 225, 0.1)
      padding: 4px 10px 6px 10px
      border-radius: 10px
      display: inline-block
      color: $actual-color
      text-transform: capitalize

      @media screen and (max-width: $mobile-breakpoint-i)
        float: right

.modal-form.contact-of-sharing-modal
  .modal-content
    .modal-header
      text-align: center
      .modal-title
        .title-popup
          font-family: $font-bold
          display: block
    .modal-footer
      padding-top: 12px