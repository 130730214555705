@import 'var'

body
  font-weight: normal
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: normal
  font-family: $font-primary
  height: 100vh
  #root
    height: 100%

.affiliatedLayout
  padding: 30px 0px 30px 0px

\::-webkit-scrollbar
  width: 5px
  margin-right: 5px

\::-webkit-scrollbar-track
  -webkit-border-radius: 10px
  border-radius: 10px

\::-webkit-scrollbar-thumb
  -webkit-border-radius: 10px
  border-radius: 10px
  background: #7b7e80
  -webkit-box-shadow: inset 0 0 6px $hove-color

a.have-tooltip
  position: relative
  display: inline
  span
    position: absolute
    width: 100px
    position: absolute
    left: -35px
    top: 20px
    color: #fff
    background: $primary-color
    height: 30px
    line-height: 30px
    text-align: center
    visibility: hidden
    &:after
      content: ''
      position: absolute
      bottom: 100%
      left: 50%
      margin-left: -8px
      width: 0
      height: 0
      border-bottom: 8px solid $primary-color
      border-right: 8px solid transparent
      border-left: 8px solid transparent
a
  &:hover.have-tooltip
    span
      visibility: visible
      z-index: 999

.container
  max-width: 100%
  margin: 0

.row
  @media only screen and (min-width: $xlarge-breakpoint-o)
    padding-top: 15px
    padding-bottom: 15px


.icon-button
  margin: 4px

.logo
  &.logo-mobile
    width: 26px
    height: 26px
    margin: auto

.valide-icon
  width: 9.7px

.pic-p
  width: 177px!important
  height: 38px
  border-radius: 19px
  background-color: $success-color
  overflow: hidden
  font-family: $font-bold
  font-size: $second-size
  color: $white-color!important
  text-align: center
  line-height: 38px

.house-icon
  width: 40px
  float: right
  margin-right: 0px
  margin-top: -4px

.icon-valide
  width: 18px
  height: 18px
  border-radius: 3.2px
  background-color: #2dcfb3
  padding: 4px
  margin-right: 10px


.arrow-button-left
  width: 5px
  margin-top: -3px
  margin-left: 7px

.outside-popup
  margin-left: 5px!important

.success-color
  input[type=checkbox]:checked + label:before
    background-color: $success-color
    border-color: $success-color
    color: #fff

.valide
  background-color: $primary-color
  border: 1px $primary-color

.info-shape
  margin: auto
  .first-cercle
    width: 100%
    height: 100%
    border-radius: 50%
    position: relative
    display: flex
    justify-content: center
    align-items: center
    align-content: center
  .second-cercle
    width: 73%
    height: 73%
    border-radius: 21px
    position: absolute
    top: 14%
    left: 14%
  .third-cercle
    width: 64%
    height: 64%
    border-radius: 13.5px
    position: absolute
    top: 19%
    left: 20%
    display: flex
    justify-content: center
    align-items: center
    align-content: center

$inside-circle-size: 20px
.large-shape
  width: 57px
  height: 57px
  .inside-circle
    width: $inside-circle-size
    height: $inside-circle-size
  &.warning-shape,
  &.notification-shape
    .inside-circle
      width: auto
      color: $white-color
      font-size: 18px
.small-shape
  width: 34px
  height: 34px
  .inside-circle
    max-width: $inside-circle-size
  .third-cercle
    span
      padding: 0px!important
      font-size: 10px
      color: white

.valide-shape
  .first-cercle
    background-color: rgba(45, 207, 179, 0.1)
  .second-cercle
    background-color: rgba(45, 207, 179, 0.23)
  .third-cercle
    background-color: #2dcfb3
.gray-shape
  .first-cercle
    background-color: rgba(123, 126, 128, 0.1)
  .second-cercle
    background-color: rgba(123, 126, 128, 0.23)
  .third-cercle
    background-color: #7b7e80
.warning-shape
  .first-cercle
    background-color: rgba(255, 52, 101, 0.1)
  .second-cercle
    background-color: rgba(255, 52, 101, 0.23)
  .third-cercle
    background-color: #ff3465
.notification-shape
  .first-cercle
    background-color: rgba(45, 108, 213, 0.21)
  .second-cercle
    background-color: rgba(45, 108, 213, 0.42)
  .third-cercle
    background-color: #2d6cd5

.inside-popup
  margin-left: 0px !important

.num-carte
  float: left
  margin-right: -5px
  width: 96%

.sidebar-row
  width: 105%
  margin-left: -10%


.alert-warning
  font-family: $font-primary
  font-size: $third-size
  color: $warning-color
  a
    color: #b16700

.primary-link
  color: $primary-color
  font-size: $third-size

.default-link
  color: $primary-color
  font-size: $medium-size
  font-weight: $weight-primary
  text-transform: uppercase
  text-decoration: none

.tab-link
  font-family: $font-bold
  font-size: $small-size
  color: $table-color
  text-transform: uppercase
  width: 136px
  text-align: center
  a
    text-decoration: none

.nav-tabs
  .nav-link.active
    border: none
    border-bottom: 1px solid $primary-color
.nav-tabs
  .nav-link
    border: none
    text-decoration: none

.inside-line
  margin-top: 30px
  border-bottom: 1px solid $hove-color
  margin-bottom: 30px

.inside-text
  margin-top: 21px
  position: relative
  margin-bottom: -8px
  text-align: center
  background: white
  width: 232px
  text-align: center
  margin-left: auto
  margin-right: auto
  font-family: $font-primary
  font-size: $medium-size
  color: $table-color
.filtre-btn
  top: 6px!important
  left: -30px!important
  width: auto
  a
    padding: 18px 21px 18px 21px
    text-decoration: none

.msg-confirm-container
  display: flex
  flex-direction: column
  justify-content: center
  height: 100vh
  #msg-confirm
    display: block
    position: relative

@media only screen and (max-width: $mobile-breakpoint-i)
  .container
    width: 100%!important
    max-width: 100%!important
    margin-left: 0px !important

.explain-container
  margin: 10px 0
  text-align: justify
  font-family: $font-primary
  font-size: $third-size
  color: $grey-text

  p
    margin: 0

.page-title
  margin: 0px
  h1.title
    font-family: $PublicoText-Bold
    font-weight: $weight-bold
    font-size: 22px
  @media screen and (min-width: $mobile-breakpoint-o)
    display: none
