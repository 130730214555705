@import '../var'

input,
textarea
  font-family: $font-primary
  font-size: $third-size
  color: $table-color

input,
textarea
  &.form-control
    color: $table-color
    &:focus
      color: $table-color

input
  &.form-control
    height: 37px


input.form-control.hidden-border.num-carte:focus
  border: none!important
input.form-control.hidden-border.dte-carte:focus
  border: none!important
input.form-control.hidden-border.cvc-carte:focus
  border: none!important

input[type=text]:focus
  outline: 0 none !important
input[type=password]:focus
  border: 1px solid $primary-color
  outline: 0 none !important

input[type=checkbox] + label
  display: block
  margin: 0em
  cursor: pointer
  padding: 0em
  height: 16px

input[type=checkbox]
  display: none

select
  background-color: white
  font-family: $font-primary
  color: $table-color
  background: url('images/double-arrow-icon.svg') 95% 50% no-repeat

  &.with-question-mark
    padding: 10px
    height: 42px
    width: 100%

  &.no-border
    border: none

  @media screen and (max-width: $mobile-breakpoint-i)
    width: 100%
    appearance: none
    background: url('images/double-arrow-icon.svg') 97% 50% no-repeat

    &.in-error
      border-color: $primary-color


input[type=checkbox] + label:before
  content: "\2714"
  border: 0.1em solid $gray-color
  border-radius: 0.25em
  width: 30px
  height: 30px
  display: inline-block
  padding: 1px 6px
  margin-right: 0.1em
  vertical-align: middle
  color: transparent
  transition: .1s

.courriers-table
  tbody
    input[type=checkbox] + label:before
      display: none
    input[type=checkbox]:checked + label:before
      display: unset
  tr:hover
    input[type=checkbox] + label:before
      display: unset

input[type=checkbox] + label:active:before
  transform: scale(0)


input[type=checkbox]:checked + label:before
  background-color: $primary-color
  border-color: $primary-color
  color: #fff

input[type=checkbox]:disabled + label:before
  transform: scale(1)
  background-color: $gray-color
  border-color: $gray-color


input[type=checkbox]:checked:disabled + label:before
  transform: scale(1)
  background-color: $gray-color
  border-color: $gray-color


input[type="radio"]
  &:checked, &:not(:checked)
    position: absolute
  &:checked + label, &:not(:checked) + label
    position: relative
    padding-left: 28px
    cursor: pointer
    line-height: 20px
    display: inline-block
    color: $color-hover
  &:checked + label:before, &:not(:checked) + label:before
    content: ''
    position: absolute
    left: 0
    top: 0
    width: 18px
    height: 18px
    border: 1px solid #ddd
    border-radius: 100%
    background: #fff
  &:checked + label:after
    content: ''
    width: 12px
    height: 12px
    background: $primary-color
    position: absolute
    top: 3px
    left: 3px
    border: 1px solid $primary-color
    border-radius: 100%
    -webkit-transition: all 0.2s ease
    transition: all 0.2s ease
  &:not(:checked) + label:after
    content: ''
    width: 12px
    height: 12px
    background: $primary-color
    position: absolute
    top: 3px
    left: 3px
    border: 1px solid $primary-color
    border-radius: 100%
    -webkit-transition: all 0.2s ease
    transition: all 0.2s ease
    opacity: 0
    -webkit-transform: scale(0)
    transform: scale(0)
  &:checked + label:after
    opacity: 1
    -webkit-transform: scale(1)
    transform: scale(1)
  &:checked + .cadre-radio
    border: red 1px solid!important

.input-group-text
  border-top-left-radius: 0
  border-bottom-left-radius: 0
  margin-top: -1px
  height: 35px
  width: 35px
  background-color: $table-color
  border: 1px $table-color
  display: block
  padding-left: 10px!important
  padding-top: 3px!important
  padding-right: 16px!important

.form-control:focus
  -webkit-box-shadow: none !important
  box-shadow: none !important
  border-color: #7b7e8096


.no-style-select
  background: none
  border: none
  width: 100%
  -moz-appearance: none
  -webkit-appearance: none
  appearance: none
  background: url('images/double-arrow-icon.svg') 100% 35% no-repeat
  background-size: 12px

  @media only screen and (max-width: $mobile-breakpoint-i)
    font-family: $font-primary
    font-size: $sub-size
    color: $table-color
    margin-top: 0px
    background: url('images/double-arrow-icon.svg') 100% 48% no-repeat

.cadre-filtre-input
  margin-bottom: 24px
  padding: 8px 0px 0px 0px
  width: 100%
  border-radius: 4px

  .question-form
    font-family: $font-primary
    font-size: $medium-size
    color: $grey-text
    margin-bottom: 4px


  input,
  .custom-select > .field
    border: solid 1px $gray-color

  .custom-select.in-error > .field
    border-color: $primary-color

  p
    margin-bottom: 0px

  .field
    min-height: 35px

.cadre-filtre-input
  input, textarea, select, .select-country > .input
    border: solid 1px $gray-color
    border-radius: 4px
    color: $grey-text

    &::placeholder
      color: $gray-color

    &.in-error
      border-color: $primary-color

  .error-input
    margin-top: 6px
    color: $primary-color
    font-size: $small-size

.select-country > .input > div
  border: none

select
  border-radius: 4px
  padding: 0.375rem 0.75rem

.cadre-filtre-input.dateinput
  input
    border-radius: 4px
    font-size: 16px
    color: $table-color
    padding: 0.375rem 0.75rem
    height: 35px
    &::placeholder
      color: $table-color
  &.in-error input
    border-color: $primary-color

.SingleDatePickerInput
  border: none!important
  width: 100%

.DateInput, .SingleDatePicker
  width: 100%

.btn
  &.btn-white
    width: 100%
    text-align: center
    font-size: $third-size
    color: $secondary-color
    border: 1px solid $gray-color
    border-radius: 4px



input,
textarea
  &.in-error
    @include red-error-cross

.dateinput.in-error
  input
    @include red-error-cross
